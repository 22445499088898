import { createRef, useState, useEffect, useMemo } from 'react';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import Popup from "reactjs-popup";
import Slider from '@mui/material/Slider';
import Slide from '@mui/material/Slide';
import gql from 'graphql-tag';
import qs from 'qs';

import { songProps, TrackIcon, BtnShare, BtnFavorite } from '../components/MetaBoom';
import { Spinner, ChkLocalFlag, SetLocalFlag } from './components';
import { AddListenHistory, GetListenHistory } from './utils';
import { IMG } from './imgLib';

function ConvertSongLength(time: number) {
    if (isNaN(time)) return '--:--';
    time = Math.round(time);
    let second = time % 60;
    let minute = Math.floor(time / 60);
    return (minute > 10 ? minute + '' : '0' + minute) + ':' + (second > 10 ? second + '' : '0' + second);
}

const PlayListBlock = ({ boomInfo, index, playing, switchSong, playPause }: { boomInfo: any, index: number, playing: boolean, switchSong: any, playPause: any }) => {
    function handleSongClick(k: number) {
        if (k === index) playPause();
        if (switchSong) switchSong(k);
    }

    function GetSongLink(song: any) {
        let link = 'https://opensea.io/assets/' + (song.chainId && song.chainId === 137 ? 'matic/' : 'ethereum/')
        if (song.id && song.contract && song.tokenId) {
            if ((song.id + '').includes('F')) {
                link = song.contract + '';
            } else {
                link += song.contract + '/' + song.tokenId;
            }
            return link;
        } else {
            return '';
        }
    }

    return (
        <div className='relative w-full h-full overflow-y-auto Roboto'>
            {Object.keys(boomInfo.songList).map((k: any) => {
                let song = boomInfo.songList[k];
                let curr = index === parseInt(k);
                let link = GetSongLink(song);
                return <div className='relative w-full mb-2 pr-10' key={'boom-' + k}>
                    <div className={'cursor-pointer relative w-full h-12 pl-9'} onClick={() => handleSongClick(parseInt(k))}>                      
                        <div className='absolute left-0 w-9 h-full flex justify-center'>
                            {curr ? <IMG src={playing ? 'PauseBlue' : 'RoundPlayBlue'} className='w-5 h-5 my-auto' />
                                : <p className='my-auto font-medium'>{parseInt(k) + 1}</p>}
                        </div>
                        <div className='relative w-full h-full pl-14 '>
                            <img className='absolute left-0 top-0 w-12 h-12 ' src={song.img} alt='cover' />
                            <div className='relative w-full h-full flex'>
                                <p className={(curr ? 'text-gen2blue' : '') + ' Roboto my-auto text-sm truncate'} > {song.title}</p>
                                <div className='my-auto w-[3px] h-[3px] rounded-full bg-[#d9d9d9] mx-1' />
                                {song.length > 0 ? <p className='my-auto Roboto text-[#bdbdbd] text-xs mr-2'>{ConvertSongLength(song.length)}</p> : ''}
                                {link ?
                                    <Popup
                                        on={['hover', 'click']}
                                        arrow={false}
                                        position='top right'
                                        trigger={
                                            <div className='relative my-auto w-min-4 w-4 h-min-4 h-4 z-50'>
                                                <TrackIcon id={song.id + ''} className='absolute w-4 h-4 ' />
                                            </div>}>
                                        <div className='w-48 pb-4'>
                                            <div className='p-4 border border-blue-dark rounded-[6px] bg-black'>
                                                <Link to={link} className='focus:outline-none' target='_blank'>
                                                    <div className='flex'>
                                                        <IMG src='OpenSea' className='my-auto w-6 h-6 mr-1' />
                                                        <p>View on Opensea</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </Popup> :
                                    <TrackIcon id={song.id + ''} className='my-auto w-4 h-4 ' />}



                            </div>

                        </div>
                    </div>
                </div>
            })}
        </div >
    )
}

const GET_BOOM_INFO = gql`
query GetMetaBoomInfo($tokenId: Int!) {
    boomInfo: getMetaBoomInfo(tokenId: $tokenId) {
        ownerId
        tokenId
        series
        displayName
        avatarUrl
        boomImg
        background
        name
        story
        rarity
        trackTag
        songList {
            id
            img
            media
            video
            title
            artist
            length
            contract
            tokenId
            chainId
        }
    }
    likes: getMBLikes(tokenId: $tokenId)
}
`;

const MBBlock = ({ id, setAlbum }: { id: number, setAlbum: any }) => {
    const [showList, switchList] = useState(false);
    const [showSlide, setSlide] = useState(false);
    const [albumInfo, setAlbumInfo] = useState<any>({
        boomImg: 'https://fansi-static.s3.ap-southeast-1.amazonaws.com/MetaBoom/parts/default/001.png',
        name: '',
        story: '',
        title: '',
    });
    const { loading } = useQuery(
        GET_BOOM_INFO,
        {
            variables: { tokenId: id },
            fetchPolicy: 'network-only',
            onCompleted: (data: any) => {
                if (data.boomInfo)
                    setAlbumInfo(data.boomInfo);
            },
            onError: (err: any) => {
                console.warn(err);
            },
        }
    );

    function PlayAlbum() {
        setAlbum(id, true);
    }

    function SwitchSongList() {
        if (showList) {
            switchList(false);
            setSlide(false);
        } else {
            switchList(true);
            setTimeout(() => {
                setSlide(true);
            }, 100)
        }

    }

    return (
        <div className='w-full'>
            <div className='w-full relative border border-darkBlueHL rounded px-4'>
                <div className='relative w-full h-22'>
                    {loading ? <div className='absolute-center'>
                        <Spinner color="#5fddff" />
                    </div> :
                        <div className='relative w-full h-full pl-20 pr-16 py-2 flex'>
                            <img src={albumInfo.boomImg} className='vertical-center left-0 w-18 h-18' alt='album' />
                            <div className='relative w-full my-auto'>
                                <div className='w-full flex'>
                                    <p className='Roboto w-[124px] font-extrabold text-sm truncate'>{albumInfo.name}</p>
                                    <div className='my-auto w-[3px] h-[3px] rounded-full bg-[#d9d9d9] mx-1' />
                                    <p className='my-auto text-sm text-gray-6d min-w-[62px]'>{albumInfo.songList ? (albumInfo.songList.length + ' Tracks') : ''}</p>
                                </div>
                                {albumInfo.ownerId > 0 ? <p className='Roboto text-xs text-gray-6d'>{'By '}
                                    <Link to={'/collection/' + albumInfo.ownerId}><span className='text-gen2blue underline'>{albumInfo.displayName}</span></Link></p> : <div className='w-full h-4' />}
                            </div>
                            <div className='absolute right-0 top-0 w-16 h-full flex'>
                                <IMG onClick={PlayAlbum} src='RoundPlayGray' className='ml-4 my-auto w-8 h-8 cursor-pointer' />
                            </div>
                        </div>
                    }
                </div>
                {albumInfo.songList && showList ?
                    <div className='relative w-full overflow-hidden'>
                        <Slide in={showSlide} direction='down'  {...(showSlide ? { timeout: 1000 } : {})} >
                            <div className='relative w-full'>
                                {Object.keys(albumInfo.songList).map((k: any) => {
                                    let song = albumInfo.songList[k];
                                    return <div className='relative w-full mb-2 h-11 pl-9' key={'songList-' + k}>
                                        <div className='absolute left-0 w-9 h-full flex justify-center'>
                                            <p className='my-auto font-medium'>{parseInt(k) + 1}</p>
                                        </div>
                                        <div className='relative w-full h-full pl-10 '>
                                            <img className='vertical-center left-0 w-8 h-8 ' src={song.img} alt='cover' />
                                            <div className='relative w-full h-full flex'>
                                                <p className={' Roboto my-auto text-sm truncate'} > {song.title}</p>
                                                <div className='my-auto w-[3px] h-[3px] rounded-full bg-[#d9d9d9] mx-1' />
                                                {song.length > 0 ? <p className='my-auto Roboto text-[#bdbdbd] text-xs mr-2'>{ConvertSongLength(song.length)}</p> : ''}
                                                <TrackIcon id={song.id + ''} className='my-auto w-4 h-4 ' />
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </Slide>
                    </div> : ''}
            </div>
            {loading ? '' : <div className='relative w-full my-2'>
                <p onClick={SwitchSongList} className='w-full text-center Roboto text-xs cursor-pointer'>{showList ? 'Hide playlist' : 'View playlist'}</p>
            </div>}
        </div>
    )
}

const MBQueueBlock = ({ albumInfo, playing, playPause, mbList, setAlbum }: { albumInfo: any, playing: boolean, playPause: any, mbList: number[], setAlbum: any }) => {
    const [queueList, setQueue] = useState<number[]>([]);
    const [mbHistory, setMbHistory] = useState<any[]>([]);

    let list = useMemo(() => {
        if (queueList.length < 2 || mbList.length < 2 || queueList[0] !== mbList[0] || queueList[1] !== mbList[1]) {
            return mbList;
        }
        return queueList;
    }, [queueList, mbList]);

    useEffect(() => {
        if (list.length > 0) {
            setQueue(list);
        }
        const history = GetListenHistory();
        let newHistory = [];
        if (history[0]) newHistory.push(history[0]);
        if (history[1]) newHistory.push(history[1]);
        setMbHistory(newHistory);
    }, [list, setQueue])



    return (
        <div className='relative h-full w-full overflow-y-auto px-3'>
            <p className='Roboto font-extra-bold text-sm mt-2'>Playing Now</p>
            <div className='mt-3 relative w-full h-21 bg-darkestBlue border border-darkBlueHL rounded py-1 px-2'>
                <div className='relative w-full h-full pl-20 pr-16 text-gen2blue flex'>
                    <img src={albumInfo.boomImg} className='absolute left-0 top-0 w-18 h-18' alt='MetaBoom' />
                    <div className='w-full my-auto'>
                        <div className='w-full flex'>
                            <p className='Roboto w-[124px] font-extrabold text-sm truncate'>{albumInfo.name}</p>
                            <div className='my-auto w-[3px] h-[3px] rounded-full bg-[#d9d9d9] mx-1' />
                            <p className='my-auto text-sm text-gray-6d min-w-[62px]'>{albumInfo.songList ? (albumInfo.songList.length + ' Tracks') : ''}</p>
                        </div>
                        {albumInfo.ownerId > 0 ? <p className='Roboto text-xs text-gray-6d'>{'By '}
                            <Link to={'/collection/' + albumInfo.ownerId}><span className='text-gen2blue underline'>{albumInfo.displayName}</span></Link></p> : <div className='w-full h-4' />}
                    </div>
                    <div className='absolute right-0 top-0 w-16 h-full flex'>
                        <IMG onClick={playPause} src={playing ? 'PauseBlue' : 'RoundPlayBlue'} className='ml-4 my-auto w-10 h-10 cursor-pointer' />
                    </div>
                </div>
            </div>
            <p className='Roboto font-extra-bold text-sm my-3'>Up Next</p>
            {queueList.map((id: any) => {
                return (
                    <MBBlock id={id} key={'MB-List-' + id} setAlbum={setAlbum} />
                )
            })}
            <p className='Roboto font-extra-bold text-sm my-3'>Listen Again</p>
            {mbHistory.map((albumInfo: any) => {
                return (
                    <div className='w-full relative border border-darkBlueHL rounded px-4 mb-2'>
                        <div className='relative w-full h-22'>
                            <div className='absolute-center'>
                                <div className='relative w-full h-full pl-20 pr-16 py-2 flex'>
                                    <img src={albumInfo.boomImg} className='vertical-center left-0 w-18 h-18' alt='album' />
                                    <div className='relative w-full my-auto'>
                                        <div className='w-full flex'>
                                            <p className='Roboto w-[124px] font-extrabold text-sm truncate'>{albumInfo.name}</p>
                                            <div className='my-auto w-[3px] h-[3px] rounded-full bg-[#d9d9d9] mx-1' />
                                            <p className='my-auto text-sm text-gray-6d min-w-[62px]'>{albumInfo.songList ? (albumInfo.songList.length + ' Tracks') : ''}</p>
                                        </div>
                                        {albumInfo.ownerId > 0 ? <p className='Roboto text-xs text-gray-6d'>{'By '}
                                            <Link to={'/collection/' + albumInfo.ownerId}><span className='text-gen2blue underline'>{albumInfo.displayName}</span></Link></p> : <div className='w-full h-4' />}
                                    </div>
                                    <div className='absolute right-0 top-0 w-16 h-full flex'>
                                        <IMG onClick={() => setAlbum(albumInfo.tokenId, true)} src='RoundPlayGray' className='ml-4 my-auto w-8 h-8 cursor-pointer' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

const SongOpenSeaLink = ({ song }: any) => {
    let link = 'https://opensea.io/assets/' + (song.chainId && song.chainId === 137 ? 'matic/' : 'ethereum/')
    if ((song.id + '').includes('F')) {
        link = song.contract + '';
    } else {
        link += song.contract + '/' + song.tokenId;
    }
    return (
        <Link to={link} className='my-auto min-w-[100px]' target='_blank'>
            <p className='text-gen2blue text-xs'>View on Opensea</p>
        </Link>
    )
}

export const MiniPlayer = () => {
    const FLASH_DOT = [0, 0.01, 0.02, 0.01];        
    const L2E_Flag = 'Player_L2E_Tips';
    const GET_RECOMMEND_MB = gql`
        query GetRecommendMBList {
            me {
                id
                displayName
            }
            mbList: getRecommendMBList
        }
        `;
    const [initial, setInitial] = useState(false);
    // const [mbList, setMBList] = useState<number[]>([]);
    const [songIndex, setSongIndex] = useState(0);
    const [mediaPlaying, setMediaPlay] = useState(false);
    const [mediaRdy, setMediaRdy] = useState(false);
    const [boomInfo, setBoomInfo] = useState<any>({
        boomImg: 'https://fansi-static.s3.ap-southeast-1.amazonaws.com/MetaBoom/parts/default/001.png',
        name: '',
        story: '',
        title: '',
    });
    const [currentSong, setCurrSong] = useState<songProps>({
        id: '0',
        img: '',
        media: '',
        title: '',
        artist: '',
        type: 1,
        length: 0,
    });
    const [tokenId, setTokenId] = useState(-1);
    let mediaRef: React.RefObject<HTMLVideoElement>;
    mediaRef = createRef();
    const [duration, setDuration] = useState(0);
    const [progress, setProgress] = useState(0);
    const [flash, setFlash] = useState(0);
    const [volume, setVolume] = useState(1);
    const [showVolume, setVolumeDisplay] = useState(false);
    const [showMenu, setMenu] = useState(false);
    const [currMenu, setMenuIndex] = useState(1);
    const [loopType, setLoop] = useState(1);//1 auto 2 album 3 song
    const [userId, setUserId] = useState(0);
    const [mbOwnerId, setMbOwner] = useState(0);
    const [l2eDuration, setL2eDuration] = useState(0);
    const [likes, setLikes] = useState(false);
    const [MetaBoom_IMG_Share, setMBSImg] = useState('');    
    const [showl2eTips, setl2eTips] = useState(false);
    const [addL2eLog] = useMutation(
        gql`
      mutation UpdL2eLogs($logType: Int!, $logInfo: String!) {
        result: updL2eLogs(logType: $logType, logInfo: $logInfo)
      }
    `,
        {
            onError: (err: any) => {
                console.warn(err);
            },
        }
    );

    const { loading } = useQuery(GET_RECOMMEND_MB, {
        onCompleted: (data: any) => {
            if (initial) return; // only need to run once        
            if (data.me) setUserId(parseInt(data.me.id));
            let currList = JSON.parse(data.mbList);
            function sort(array: number[]) {
                array.sort(() => Math.random() - 0.5);
            }
            if (currList.length < 1) currList.push(1);
            sort(currList);
            let newToken = currList[0];            
            window.MBList = currList;
            LoadAlbumInfo(newToken);
        },
        onError: (err: any) => {
            console.warn(err);
            return (
                <div>
                    <p className='p-6 text-lg text-white'>Network Error</p>
                </div>
            );
        },
    });

    const [getBoomInfo, { loading: loadingBoomInfo }] = useLazyQuery(
        GET_BOOM_INFO,
        {
            variables: { tokenId },
            fetchPolicy: 'network-only',
            onCompleted: (data: any) => {
                setBoomInfo(data.boomInfo);   
                let boomInfo = { ...data.boomInfo };
                boomInfo.id = boomInfo.tokenId;
                setMbOwner(boomInfo.ownerId > 0 ? boomInfo.ownerId : (100000000 + boomInfo.id));
                let mbImg = boomInfo.boomImg.substring(0, boomInfo.boomImg.length - 5) + 'S.png';
                setMBSImg(mbImg);
                AddListenHistory(boomInfo);
                if (data.boomInfo.songList && data.boomInfo.songList.length > 0) {
                    setCurrSong(data.boomInfo.songList[0]);
                }
                setLikes(data.likes);
                if (!initial) setInitial(true);
            },
            onError: (err: any) => {
                console.warn(err);
            },
        }
    );    

    let l2eBlock = false;

    //progress bar
    useEffect(() => {             
        const LISTEN_TICK = 10 * 1000;

        function UpdL2eListen() {
            if (userId < 1) return;
            if (l2eDuration < LISTEN_TICK) return;
            let newDuration = l2eDuration - LISTEN_TICK;
            setL2eDuration(newDuration);
            const variables = {
                logType: 1,
                logInfo: JSON.stringify({ tokenId }),
            };
            addL2eLog({ variables });
        }

        const timer = setTimeout(() => {
            if (mediaPlaying && mediaRef.current) {                              
                let newProgress = mediaRef.current.currentTime;
                setProgress(newProgress)
                let newDuration = Math.round(mediaRef.current.duration);
                setDuration(newDuration);
                let nextFlash = flash + 1;
                if (nextFlash >= FLASH_DOT.length) nextFlash = 0;
                setFlash(nextFlash);

                if (userId > 0) {
                    let newDuration = l2eDuration + 250;
                    setL2eDuration(newDuration);
                    if (newDuration >= LISTEN_TICK) UpdL2eListen();
                }
            }
        }, 250);
        return () => {
            clearTimeout(timer);
        };
    }, [mediaRef, mediaRdy, mediaPlaying, setProgress, setFlash, flash, FLASH_DOT.length
        , l2eDuration, setL2eDuration, userId, addL2eLog, tokenId])

    //add global MB play function
    useEffect(() => {        
        if (!window.MBSwitch) {
            window.MBSwitch = SwitchMB;
        }
    })

    //chk l2e flag
    useEffect(() => {
        const gap = 86400;
        if (ChkLocalFlag(L2E_Flag, gap))
            setl2eTips(true);
    }, [setl2eTips, L2E_Flag])

    if (!window.MBList) window.MBList = [];
    if (loading) return null;

    function LoadAlbumInfo(newToken: number) {
        setTokenId(newToken);
        getBoomInfo({ variables: { tokenId: newToken } });
    }

    function SwitchPlayPause() {
        if (loading || loadingBoomInfo) return;
        if (!mediaRef.current) return;
        let player = mediaRef.current;
        if (player.paused) {
            setMediaPlay(true);
            player.play();
        } else {
            setMediaPlay(false);
            player.pause();
        }
    }

    function SwitchSong(index: number) {        
        if (loading || loadingBoomInfo) return;
        if (index < 0) {
            if (songIndex === 0) {
                if (mediaRef.current) {
                    SeekProgress(0);
                    mediaRef.current.play();
                    return;
                }
            } else {
                index = 0;
            }
        }
        if (index >= boomInfo.songList.length) {
            SwitchMB(-1);
            return;
        }
        if (boomInfo.songList[index]) {
            setSongIndex(index);
            let song = boomInfo.songList[index];
            setCurrSong(song);
            if (mediaRef.current) {
                if (mediaPlaying) {
                    mediaRef.current.autoplay = true;
                } else {
                    mediaRef.current.autoplay = false;
                }
            } else {
                setMediaPlay(false);
            }
        }
        setProgress(0);
        setMediaRdy(false);
    }

    function NextSong() {
        if (loading || loadingBoomInfo) return;
        if (!mediaRef.current) return
        switch (loopType) {
            case 3: //single loop set time to 0
                SeekProgress(0);
                mediaRef.current.play();
                break;
            default:
                SwitchSong(songIndex + 1);
                break;
        }
    }

    function MediaLoaded() {        
        if (mediaRef.current) {
            setMediaRdy(true);
            setDuration(Math.round(mediaRef.current.duration));
            mediaPlaying ? mediaRef.current.play() : mediaRef.current.pause();
        }
    }

    //-1 next
    function SwitchMB(tokenId: number, play?: boolean) {
        if (loadingBoomInfo) return;
        let mbId = 0;
        let currList = window.MBList;
        if (tokenId < 0) {
            if (loopType === 2) {
                setSongIndex(0);
                let song = boomInfo.songList[0];
                setCurrSong(song);
                return
            } else {
                if (window.MBList.length > 1) {
                    mbId = currList[1];
                    let current = currList.shift();
                    if (current) currList.push(current);
                    window.MBList = currList;
                }
            }
        }

        if (tokenId > 0) {
            let index = currList.indexOf(tokenId);
            if (index > -1) {
                let first = currList.slice(0, index);
                let second = currList.slice(index, currList.length);
                currList = second.concat(first);
            } else {
                let current = currList.shift();
                if (current) currList.push(current);
                currList.unshift(tokenId);
            }
            window.MBList = currList;
            mbId = tokenId;
        }

        if (mbId > 0) {
            setSongIndex(0);
            if (play) setMediaPlay(true);
            LoadAlbumInfo(mbId);
        }
    }

    function SeekProgress(target: number) {
        if (!mediaRef.current) return;
        setProgress(target);
        mediaRef.current.currentTime = target;
    }

    function changeVolume(target: number) {
        if (!mediaRef.current) return;
        setVolume(target);
        mediaRef.current.volume = target;
    }

    function switchLoop() {
        let newLoop = loopType + 1;
        if (newLoop > 3 || newLoop < 1) newLoop = 1;
        setLoop(newLoop);
    }

    function HideL2eFlag() {
        SetLocalFlag(L2E_Flag);
        setl2eTips(false);
    }

    return (
        <div className='w-full my-auto flex flex-wrap h-16 bg-black'>
            <video ref={mediaRef}
                onEnded={NextSong}
                autoPlay
                playsInline
                className='hidden'
                src={currentSong['media']}
                onLoadedData={MediaLoaded} />
            <div className='absolute w-full z-50 h-8 px-2 -top-[16px] lg:-top-[12px]'>
                <Slider
                    sx={{
                        color: '#5fddff',
                        height: 3,
                        bottom: 4,
                        '& .MuiSlider-thumb': {
                            height: 16,
                            width: 16,
                            backgroundColor: '#fff',
                            border: '2px solid currentColor'
                        },
                        '& .MuiSlider-rail': {
                            opacity: 1,
                            backgroundColor: '#fff',
                        },
                        '& .MuiSlider-valueLabel': {
                            height: 16,
                            lineHeight: 1.2,
                            fontSize: 12,
                            background: 'unset',
                            padding: 2,
                            backgroundColor: '#5fddff'
                        },
                    }}
                    defaultValue={0}
                    onChange={(_, value) => SeekProgress(value as number)}
                    max={duration}
                    value={progress}
                    step={0.5}
                    valueLabelFormat={ConvertSongLength(progress)}
                    valueLabelDisplay='auto' />
            </div>
            <div className='h-full w-1/3 hidden lg:flex px-5 '>
                <img src={currentSong.img} className='w-10 h-10 my-auto mr-2' alt='cover' />
                <p className='w-42 text-sm my-auto truncate mr-2'>{currentSong.title}</p>
                <div className='w-1 h-1 my-auto mr-1 bg-gray-cd rounded-full' />
                <p className='text-gray-cd text-xs my-auto mr-1 '>{ConvertSongLength(duration)}</p>
                <div className='w-1 h-1 my-auto bg-gray-cd rounded-full mr-1' />
                <SongOpenSeaLink song={currentSong} />
            </div>
            <div className='h-full w-1/4 flex lg:hidden pt-4 lg:pt-2 p-2 '>
                <img src={currentSong.img} className='w-8 h-8 my-auto' alt='cover' />
            </div>
            <div className='relative h-full w-1/2 lg:w-1/3 pt-0 lg:pt-2 px-6 lg:px-0 flex flex-wrap-reverse'>
                <div className={'w-full h-l07 relative flex' + (loading || loadingBoomInfo ? ' opacity-20' : '')}>
                    <div className='flex mx-auto pt-3 lg:pt-0'>
                        <IMG src='Previous' className='w-6 h-6 cursor-pointer mr-6 my-auto block'                        
                            onClick={() => SwitchSong(songIndex - 1)} />
                        <IMG src={mediaPlaying ? 'Pause' : 'Play'}
                            className='w-10 h-10 cursor-pointer mr-6 my-auto'
                            onClick={SwitchPlayPause} />
                        <IMG src='Next'
                            className='w-6 h-6 cursor-pointer my-auto'
                            onClick={() => SwitchSong(songIndex + 1)} />                        
                    </div>
                </div>
                {userId > 0 ?
                    <Popup on={['hover', 'focus']}
                        disabled={!showl2eTips}                        
                        open={showl2eTips}
                        arrowStyle={{ color: '#5fddff', visibility: (showl2eTips ? 'visible' : 'hidden') }}
                        trigger={
                            <div className='z-40 block lg:hidden vertical-center right-[-8px] w-4 h-6 pt-2' >
                                <div className='relative w-4 h-4 rounded-full border border-quest-red' >
                                    <div className='absolute-center w-2 h-2 rounded-full bg-quest-red' />
                                </div>
                            </div>
                        }
                        position='top center'>
                        <div className={'w-48 px-3 py-2 rounded bg-gen2blue ' + (showl2eTips ? '' : ' hidden')}>
                            <p className='Roboto text-black font-extrabold text-sm'>Don't stop the music; watch your Score soar !</p>
                            <div className='w-full flex justify-center'>
                                <div onClick={HideL2eFlag} className='Roboto text-xs my-auto mr-2 cursor-pointer text-black'>Got it</div>
                                <Link to='/collection/0/myFarming' className=' focus:outline-none bg-djPink Roboto text-xs p-1 mt-1 rounded'>
                                    Get Started
                                </Link>
                            </div>
                        </div>
                    </Popup>
                    :
                    <Popup
                        disabled={!showl2eTips}                        
                        open={showl2eTips}
                        arrowStyle={{ color: '#5fddff', visibility: (showl2eTips ? 'visible' : 'hidden') }}
                        trigger={
                            <div className='z-40 block lg:hidden vertical-center right-[-8px] w-4 h-6 pt-2' >
                                <div className='relative w-4 h-4 rounded-full border border-gray-6d' >
                                    <div className='absolute-center w-2 h-2 rounded-full bg-gray-6d' />
                                </div>
                            </div>}
                        position='top center'>
                        <div className={'w-44 px-3 py-2 rounded bg-gen2blue' + (showl2eTips ? '' : ' hidden')}>
                            <p className='Roboto text-black font-extrabold text-sm'>Login to earn Music Score !</p>
                            <div className='w-full flex justify-center'>
                                <div onClick={HideL2eFlag} className='Roboto text-xs my-auto mr-2 cursor-pointer text-black'>Got it</div>
                                <Link to={'/login?' + qs.stringify({ n: window.location.pathname })} className='focus:outline-none bg-djPink Roboto text-xs p-1 mt-1 rounded'>
                                    Get Started
                                </Link>
                            </div>
                        </div>
                    </Popup>
                }                
            </div>
            {
                boomInfo ?
                    <div className='relative h-full w-1/4 lg:w-1/3 flex pr-0 lg:pr-18 '>
                {showMenu ? <div className='w-screen lg:w-[420px] h-[75vh] min-h-80 absolute right-0' aria-label='menu_panel'
                    style={{ top: -8, msTransform: 'translateY(-100%)', transform: 'translateY(-100%)' }}>
                    <Slide in={showMenu} direction='left'  {...(showMenu ? { timeout: 1000 } : {})} >
                        <div className='relative w-full h-full pt-42 bg-black border border-darkBlueHL rounded-2xl'>
                            <div className='absolute top-0 w-full h-42 bg-darkestBlue px-4'>
                                <div className='relative w-full h-full py-5' style={{ borderBottom: '1px solid #044556' }}>
                                    <div className='relative w-full pl-28 pr-14'>
                                            <img src={boomInfo.boomImg} className='absolute left-0 top-0 w-24 h-24' alt='metaboom' />
                                        <div className='w-full'>
                                            <p className='text-sm text-gray-6d'>{(boomInfo.songList ? ((songIndex + 1) + ' / ' + boomInfo.songList.length) : '--') + ' Tracks'}</p>
                                            <p className='text-lg font-bold truncate'>{currentSong.title}</p>
                                                    {boomInfo.displayName ? <p className='text-sm truncate'>By
                                                        <Link to={'/collection/' + mbOwnerId}>
                                                            <span className='font-semibold text-gen2blue ml-1'>{boomInfo.displayName}</span>
                                                        </Link></p> : ''}
                                        </div>
                                                <div className='absolute right-0 top-0 w-16 flex'>
                                                    <div className="mt-2">
                                                    <BtnFavorite userId={userId} boomboxId={tokenId} likes={likes} setLikes={setLikes} l2eBlock={l2eBlock} btnStyle={2}/>
                                                </div>
                                                    <BtnShare name={boomInfo.name} l2eBlock={l2eBlock} userId={userId} shareImg={MetaBoom_IMG_Share} story={boomInfo.story + ''} boomboxId={tokenId} btnStyle={2} />
                                        </div>
                                    </div>
                                    <div className='absolute bottom-0 left-0 px-4 w-full'>
                                        <div className='w-full flex'>
                                            <div className='relative w-1/2 h-7 pr-1' onClick={() => setMenuIndex(1)}>
                                                <p className={'text-center Roboto font-extrabold ' + (currMenu < 2 ? 'text-gen2blue' : 'cursor-pointer')}>Playlist</p>
                                                {currMenu < 2 ? <div className='absolute bottom-0  w-full pr-1 '><div className='w-full h-[3px] bg-gen2blue' /></div> : ''}
                                            </div>
                                            <div className='relative w-1/2 h-7 pl-1' onClick={() => setMenuIndex(2)}>
                                                <p className={'text-center Roboto font-extrabold ' + (currMenu > 1 ? 'text-gen2blue' : 'cursor-pointer')}>Queue</p>
                                                {currMenu > 1 ? <div className='absolute bottom-0  w-full pr-1 '><div className='w-full h-[3px] bg-gen2blue' /></div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='relative w-full h-full py-5 px-4'>
                                {currMenu < 2 ? <div className='relative w-full h-full'>
                                    {boomInfo.songList ? <PlayListBlock playing={mediaPlaying} playPause={SwitchPlayPause} switchSong={SwitchSong} boomInfo={boomInfo} index={songIndex} /> : ''}
                                </div> :
                                    <div className='relative w-full h-full'>
                                            <MBQueueBlock mbList={window.MBList.length > 1 ? [window.MBList[1], window.MBList[2]] : [tokenId, tokenId]} albumInfo={boomInfo} playing={mediaPlaying} playPause={SwitchPlayPause} setAlbum={SwitchMB} />
                                    </div>}
                            </div>
                        </div>
                    </Slide>
                </div> : ''}
                <div className='absolute right-0 w-18 h-16 cursor-pointer' onClick={() => { setMenu(showMenu ? false : true) }}>
                            <div className='w-px h-full pt-2'>
                                <div className='w-full h-full bg-[#2e5c6a]' />
                            </div>
                    <IMG src={showMenu ? 'PlaylistClose' : 'Playlist'} className='w-6 h-auto absolute-center' />
                </div>
                {mediaPlaying ? <div className='absolute left-0 my-auto w-1 h-1 bg-blue-300 rounded-full' style={{ opacity: FLASH_DOT[flash] }} /> : ''}
                        <div className='w-full hidden lg:flex justify-end'>
                            {userId > 0 ?
                                <Popup on={['hover', 'focus']}
                                    disabled={!showl2eTips}
                                    open={showl2eTips}
                                    arrowStyle={{ color: '#5fddff', visibility: (showl2eTips ? 'visible' : 'hidden') }}
                                    trigger={
                                        <div className='relative flex my-auto h-6 px-2 rounded-2xl border border-quest-red mr-10'>
                                            <div className='my-auto w-2 h-2 rounded-full bg-quest-red mr-2' />
                                            <p className='my-auto text-quest-red text-xs'>Earning Music Score</p>
                                        </div>
                                    }
                                    position='top center'>
                                    <div className={'w-48 px-3 py-2 rounded bg-gen2blue ' + (showl2eTips ? '' : ' hidden')}>
                                        <p className='Roboto text-black font-extrabold text-sm'>Don't stop the music; watch your Score soar !</p>
                                        <div className='w-full flex justify-center'>
                                            <div onClick={HideL2eFlag} className='Roboto text-xs my-auto mr-2 cursor-pointer text-black'>Got it</div>
                                            <Link to='/collection/0/myFarming' className=' focus:outline-none bg-djPink Roboto text-xs p-1 mt-1 rounded'>
                                                Get Started
                                            </Link>
                                        </div>
                                    </div>
                                </Popup>
                                :
                                <Popup on={['hover', 'focus']}
                                    open={showl2eTips}
                                    disabled={!showl2eTips}
                                    arrowStyle={{ color: '#5fddff', visibility: (showl2eTips ? 'visible' : 'hidden') }}
                                    trigger={                       
                                        <Link to={'/login?' + qs.stringify({ n: window.location.pathname })} className='flex my-auto h-6 px-2 rounded-2xl border border-gray-6d mr-10'>
                                            <div className='my-auto w-2 h-2 rounded-full bg-gray-6d mr-2' />
                                            <p className='my-auto text-gray-6d text-xs'>Login to earn</p>
                                        </Link>}
                                    position='top center'>
                                    <div className={'w-44 px-3 py-2 rounded bg-gen2blue' + (showl2eTips ? '' : ' hidden')}>
                                        <p className='Roboto text-black font-extrabold text-sm'>Login to earn Music Score !</p>
                                        <div className='w-full flex justify-center'>
                                            <div onClick={HideL2eFlag} className='Roboto text-xs my-auto mr-2 cursor-pointer text-black'>Got it</div>
                                            <Link to={'/login?' + qs.stringify({ n: window.location.pathname })} className='focus:outline-none bg-djPink Roboto text-xs p-1 mt-1 rounded'>
                                                Get Started
                                            </Link>
                                        </div>
                                    </div>
                                </Popup>
                            }
                        <div className='cursor-pointer my-auto mr-4' onClick={switchLoop}>
                            <IMG src={loopType < 2 ? 'LoopRandom' : (loopType < 3 ? 'Loop' : 'LoopSingle')} className='w-6 h-auto' />
                        </div>
                    <div className='relative w-6 h-6 my-auto mr-8'>
                        <div className='absolute-center w-14 h-14' onMouseLeave={() => setVolumeDisplay(false)} />
                        {showVolume ?
                            <div onMouseEnter={() => setVolumeDisplay(true)}
                                onMouseLeave={() => setVolumeDisplay(false)}
                                className='absolute py-3 top-[-172px] left-[-2px] bg-darkestBlue border-darkBlueHL border w-7 h-40 rounded'>
                                <Slider
                                    size='small'
                                    aria-label='Volume'
                                    defaultValue={1}
                                    max={1}
                                    step={0.1}
                                    value={volume}
                                    valueLabelFormat={volume * 100 + ''}
                                    orientation='vertical'
                                    valueLabelDisplay='auto'
                                    sx={{
                                        color: '#5fddff',
                                        width: 2,
                                        '& .MuiSlider-track': {
                                            border: 'none',
                                        },
                                        '& .MuiSlider-rail': {
                                            opacity: 1,
                                            backgroundColor: '#fff',
                                        },
                                        '& .MuiSlider-thumb': {
                                            width: 12,
                                            height: 12,
                                            backgroundColor: '#fff',
                                        },
                                    }}
                                    onChange={(_, value) => changeVolume(value as number)}
                                />
                            </div> : ''}
                        <div onMouseEnter={() => setVolumeDisplay(true)}>
                            <IMG onClick={() => { if (volume > 0) { changeVolume(0) } else { changeVolume(1) } }} src={volume > 0 ? (showVolume ? 'VolumeBlue' : 'Volume') : 'Muted'} className='relative w-6 h-auto my-auto mr-8 cursor-pointer' />
                    </div>
                </div>
                </div>
            </div> : ''}
        </div>
    )
};