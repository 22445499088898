import Add from '../components/img/btn-bgc-add.svg';
import Alert from '../components/img/icon-alert.svg';
import AlertRed from '../components/img/icon-alert-red.svg';
import AppleStore from '../components/img/img-apple-store.svg';
import AppleStoreC from '../components/img/img-apple-store-color.svg';
import AppleStoreDownload from '../components/img/img-apple-store-c.svg';
import ArrowDown from '../components/img/icon-arrow-down.svg';
import ArrowLeft from '../components/img/icon-arrow-left.svg';
import ArrowLeftBlue from '../components/img/icon-arrow-left-blue.svg';
import Arrow2LeftBlue from '../components/img/icon-arrow-2-left-blue.svg';
import ArrowRight from '../components/img/icon-arrow-right.svg';
import ArrowRightBlack from '../components/img/icon-arrow-right-black.svg';
import ArrowRightBlue from '../components/img/icon-arrow-right-blue.svg';
import ArrowRightRed from '../components/img/icon-arrow-right-red.svg';
import ArrowUp from '../components/img/icon-arrow-up.svg';
import ArrowUpB from '../components/img/icon-arrow-up-blue.svg';
import Audio from '../components/img/icon-audio.svg';
import AudioOff from '../components/img/icon-audio-off.svg';
import Award from '../components/img/icon-award.svg';
import Beta from '../components/img/icon-beta.svg';
import Camera from '../components/img/icon-camera.svg';
import Card from '../components/img/icon-credit-card.svg';
import CardBlack from '../components/img/icon-credit-card-black.svg';
import Check from '../components/img/form-checkbox-actived.svg';
import CheckBlue from '../components/img/icon-check-round-blue.svg';
import CheckRed from '../components/img/icon-check-round-red.svg';
import CheckYellow from '../components/img/icon-check-round-yellow.svg';
import CheckAble from '../components/img/icon-chkable.svg';
import CheckDisableRound from '../components/img/icon-disable.svg';
import CheckDisableSquare from "../components/img/form-checkbox-disable-02.svg";
import Clock from '../components/img/icon-clock-round-white.svg';
import Close from '../components/img/btn-close.svg';
import CloseB from "../components/img/icon-close-blue.svg";
import CloseR from "../components/img/icon-close-red.svg";
import CloseY from "../components/img/icon-close-yellow.svg";
import Collapse from '../components/img/icons-ic-collapse.svg';
import Copy from '../components/img/btn-copy.svg';
import Delete from '../components/img/icon-delete-white.svg';
import Demo from '../components/img/demo-song.gif';
import Diamond from '../components/img/icon-diamond-pink.svg';
import Discord from '../components/img/icon-discord.svg'
import Drag from '../components/img/icon-drag.svg';
import DownloadBlack from '../components/img/icon-download-black.svg';
import DStar from '../components/img/icon-doodle-star.svg';
import Edit from '../components/img/icon-edit-white.svg';
import EditBlack from '../components/img/icon-edit-black.svg';
import EditBlue from '../components/img/icon-edit-blue.svg';
import EditPhoto from '../components/img/icon-editphoto.svg';
import Ellipsis from '../components/img/icon-ellipsis.svg';
import EthBadge from '../components/img/icon-badge-track-eth.png';
import Ethereum from "../components/img/icon-ETH-round.png";
import ETH from "../components/img/icon-eth-white.svg";
import ETHBlack from "../components/img/icon-eth-black.svg";
import Extend from '../components/img/icons-ic-extend.svg';
import FANSI from '../components/img/icon-fansi.svg'
import FansiBadge from '../components/img/icon-badge-track-fansi.svg';
import Favorite from '../components/img/icon-favorite.svg'
import FavoriteBlue from '../components/img/icon-favorite-blue.svg';
import FavoriteBlueEmpty from '../components/img/icon-favorite-blue-empty.svg'
import FavoriteDark from '../components/img/icon-favorite-dark.svg';
import FavoriteDarkWhite from '../components/img/icon-favorite-dark-w.svg';
import FB from '../components/img/icon-facebook.svg';
import FBBlue from '../components/img/icon-fb-blue.svg';
import GreenCheck from '../components/img/icon-circle-check-green.svg';
import Gear from '../components/img/icon-gear.svg';
import Google from '../components/img/icon-google.svg';
import GooglePlay from '../components/img/img-google-play.svg';
import GooglePlayC from '../components/img/img-google-play-color.svg';
import GooglePlayDownload from '../components/img/img-google-play-c.svg';
import IG from '../components/img/icon-instagram.svg';
import Info from '../components/img/icon-info.svg';
import InfoBlue from '../components/img/icon-info-blue.svg';
import InfoGreen from '../components/img/icon-info-green.svg';
import InfoRed from '../components/img/icon-info-red.svg';
import InfoYellow from '../components/img/icon-info-yellow.svg';
import LinkBlue from '../components/img/icon-link-blue.svg';
import Linked3 from '../components/img/icon-Linked3.png';
import Linkedin from '../components/img/icon-linkedin.svg';
import LinkTree from '../components/img/icon-link-tree.svg';
import LinkWhite from "../components/img/icon-link-white.svg";
import ListWhite from '../components/img/icon-list-white.svg';
import Logo from '../components/img/FANSI_MB_logo.svg';
import LogoFull from '../components/img/icon-fansi-logo-full.svg';
import LogoMetaBoom from '../components/img/metaboom-logo.svg';
import LogoMetaBoomL from '../components/img/MB-logo-lg.svg';
import Logout from '../components/img/icon-logout.svg';
import Loop from '../components/img/icon-loop.svg';
import LoopSingle from '../components/img/icon-loop-single.svg';
import LoopRandom from '../components/img/icon-loop-random.svg';
import Mail from '../components/img/icon-mail.svg';
import MailBlack from '../components/img/icons-mail-black.svg';
import Mb2Mockup from '../components/img/mb2-mockup1.png';
import MBAvatar from '../components/img/MB-avatar.png';
import Menu from '../components/img/icon-menu.svg';
import Member from '../components/img/icon-member-white.svg';
import MetaBoom from '../components/img/icon-MetaBoom.svg';
import MetaBoomBW from '../components/img/icon-metaboom.png';
import MetaBoomH from '../components/img/icon-MetaBoom-header.svg';
import MM from '../components/img/icon-metamask.png';
import MMBlue from '../components/img/icon-wallet-blue.svg';
import MMWarning from "../components/img/metamask.gif";
import Muted from '../components/img/icon-muted.svg';
import Next from '../components/img/btn-next.svg';
import OpenSea from '../components/img/icon-opensea.svg';
import OpenSeaBlack from '../components/img/icon-opensea-black.svg';
import OpenSeaBadge from '../components/img/icon-badge-track-opensea.svg';
import Pause from '../components/img/btn-pause.svg';
import PauseBlue from '../components/img/btn-pause-blue.svg';
import Play from '../components/img/btn-play.svg';
import Playlist from '../components/img/icon-playlist-open.svg';
import PlaylistClose from '../components/img/icon-playlist-close.svg';
import Polygon from '../components/img/icon-polygon.svg';
import PolygonBlack from '../components/img/icon-polygon-black.svg';
import Prev from '../components/img/btn-bgc-left.svg';
import Previous from '../components/img/btn-previous.svg';
import Question from '../components/img/icon-question.svg';
import Quest from '../components/img/icon-quest.svg';
import QuestBlack from '../components/img/icon-quest-black.svg';
import QuestBlue from '../components/img/icon-quest-blue.svg';
import QuestRed from '../components/img/icon-quest-red.svg';
import QuestYellow from '../components/img/icon-quest-yellow.svg';
import QuestRainbow from '../components/img/icon-quest-rainbow.svg';
import RarityC from '../components/img/Rarity_C.png';
import RarityE from '../components/img/Rarity_E.png';
import RarityM from '../components/img/Rarity_M.png';
import RarityR from '../components/img/Rarity_R.png';
import Reduce from '../components/img/btn-bgc-reduce.svg';
import Refresh from '../components/img/icon-refresh-white.svg';
import RefreshBlue from '../components/img/icon-refresh-blue.svg';
import RisingStars from '../components/img/icon-rising-stars.svg';
import RisingStarsH from '../components/img/icon-rising-stars-header.svg';
import RoundPlay from '../components/img/round-play.svg';
import RoundPlayBlue from '../components/img/round-play-blue.svg';
import RoundPlayGray from '../components/img/round-play-gray.svg';
import RoundCArrowLeft from '../components/img/round-carousel-arrow-left.svg';
import RoundCArrowRight from '../components/img/round-carousel-arrow-right.svg';
import Search from '../components/img/icon-search.svg';
import Score from '../components/img/icon-score-white.svg';
import SkipNext from '../components/img/icon-skip-next.svg';
import SkipPrevious from '../components/img/icon-skip-previous.svg';
import Share from '../components/img/icon-check-w.svg';
import ShareBlue from '../components/img/icon-check-blue.svg';
import Star from '../components/img/icon-star-white.svg';
import Stream from '../components/img/icon-stream-white.svg';
import Time from '../components/img/icon-time-white.svg';
import TimeB from '../components/img/icon-time-blue.svg';
import Twitter from '../components/img/icon-twitter.svg'
import TwitterBlack from '../components/img/icon-twitter-black.svg'
import TwitterBlue from '../components/img/icon-twitter-blue.svg';
import Triangle from '../components/img/icon-triangle.svg';
import Trophy from '../components/img/icon-trophy.svg';
import TrophyPink from '../components/img/icon-trophy-pink.svg';
import Uncheck from '../components/img/uncheck-white.png';
import Volume from '../components/img/icon-volume.svg';
import VolumeBlue from '../components/img/icon-volume-blue.svg';
import Wallet from '../components/img/icon-wallet-white.svg';
import YT from '../components/img/icon-youtube.svg';
import ZoomIn from '../components/img/icon-zoom-in.svg';

export const IMG = ({ src, className, style, onClick }: { src: string, className?: string, style?: React.CSSProperties, onClick?: any }) => {
    let img = Info;
    const List: { [k: string]: string } = {
        Add,
        Alert,
        AlertRed,
        AppleStore,
        AppleStoreC,
        AppleStoreDownload,
        ArrowDown,
        ArrowLeft,
        ArrowLeftBlue,
        ArrowRight,
        ArrowRightBlack,
        ArrowRightBlue,
        ArrowRightRed,
        Arrow2LeftBlue,
        FavoriteBlueEmpty,
        FavoriteDark,
        FavoriteDarkWhite,
        ArrowUp,
        ArrowUpB,
        Audio,
        AudioOff,
        Award,
        Beta,
        Camera,
        Card,
        CardBlack,
        Check,
        CheckBlue,
        CheckRed,
        CheckYellow,
        CheckAble,
        CheckDisableRound,
        CheckDisableSquare,
        Clock,
        Close,
        CloseB,
        CloseR,
        CloseY,
        Collapse,
        Copy,
        Delete,
        Demo,
        Diamond,
        Discord,
        Drag,
        DownloadBlack,
        DStar,
        Edit,
        EditBlack,
        EditBlue,
        EditPhoto,
        Ellipsis,
        EthBadge,
        Ethereum,
        ETH,
        ETHBlack,
        Extend,
        FANSI,
        FansiBadge,
        Favorite,
        FavoriteBlue,
        FB,
        FBBlue,
        GreenCheck,
        Gear,
        Google,
        GooglePlay,
        GooglePlayC,
        GooglePlayDownload,
        IG,
        Info,
        InfoBlue,
        InfoGreen,
        InfoRed,
        InfoYellow,
        LinkBlue,
        Linked3,
        Linkedin,
        LinkTree,
        LinkWhite,
        ListWhite,
        Logo,
        LogoFull,
        LogoMetaBoom,
        LogoMetaBoomL,
        Logout,
        Loop,
        LoopSingle,
        LoopRandom,
        Mail,
        MailBlack,
        Mb2Mockup,
        MBAvatar,
        Member,
        Menu,
        MetaBoom,
        MetaBoomBW,
        MetaBoomH,
        Next,
        MM,
        MMBlue,
        MMWarning,
        Muted,
        OpenSea,
        OpenSeaBlack,
        OpenSeaBadge,
        Pause,
        PauseBlue,
        Play,
        Playlist,
        PlaylistClose,
        Polygon,
        PolygonBlack,
        Prev,
        Previous,
        Question,
        Quest,
        QuestBlack,
        QuestBlue,
        QuestRainbow,
        QuestRed,
        QuestYellow,
        RarityC,
        RarityE,
        RarityM,
        RarityR,
        Reduce,
        Refresh,
        RefreshBlue,
        RisingStars,
        RisingStarsH,
        RoundPlay,
        RoundPlayBlue,
        RoundPlayGray,
        RoundCArrowLeft,
        RoundCArrowRight,
        Score,
        SkipNext,
        SkipPrevious,
        Search,
        Share,
        ShareBlue,
        Star,
        Stream,
        Time,
        TimeB,
        Twitter,
        TwitterBlack,
        TwitterBlue,
        Triangle,
        Trophy,
        TrophyPink,
        Uncheck,
        Volume,
        VolumeBlue,
        Wallet,
        YT,
        ZoomIn,
    }
    if (List[src]) {
        img = List[src];
    } else {
        console.log('image ' + src + ' not found')
    }
    return <img className={className ? className : ''} src={img} onClick={onClick ? onClick : null} style={style ? style : {}} alt={src} />
}