import { ChangeEvent, forwardRef, useEffect, useState, createRef, RefObject, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Link, NavLink } from 'react-router-dom';
import { toast, ToastContent } from 'react-toastify';
import ReactSpinkit, { SpinnerProps } from 'react-spinkit';
import { Dialog } from '@mui/material';
import { NumericFormat } from "react-number-format";
import TextAreaAutosize from 'react-textarea-autosize';
import Popup from 'reactjs-popup';

import cx from 'classnames';
import pipe from 'ramda/es/pipe';

import { IMG } from './imgLib';
import { fbShareUrl, tweetUrl } from './utils';
import __ from './gettext';

import '../components/css/FaithNomad.css';


const current = new Date().getTime() % 100000;

export const Spinner = ({ name, color, className }: { name?: SpinnerProps['name'], color?: string, className?: string }) => {
    return <ReactSpinkit name={name ? name : 'ball-beat'} color={color ? color : 'black'}
        className={className ? className : ''} fadeIn='none' />;
};

export const Loading = () => {
    return (
        <h1 className='relative container section h-screen flex-grow text-center mt-4'>
            <p className='text-xl m-auto'>Loading...</p>
            <Spinner className='mt-2 mx-auto' color='white' />
        </h1>
    )
};

export const FallBack = () => {
    return <div className='relative min-h-2/3-screen w-full'>
        <div className='absolute-center'>
            <Spinner name="folding-cube" color="fuchsia" />
        </div>
    </div>
}

export const error = pipe(
    (err: Error | ToastContent) => {
        return err instanceof Error ? err.message : err;
    },
    (msg: ToastContent) => {
        // Strip "graphql error" string if present
        if (typeof msg === 'string') msg = msg.replace(/^GraphQL error:\s+?/i, '');
        return msg;
    },
    (msg: ToastContent) =>
        toast(msg, {
            className: 'alert bg-red-error',
            bodyClassName: 'alert-body',
            progressClassName: 'invisible',
        }),
);

export const success = pipe(__, (msg: ToastContent) =>
    toast(msg, {
        className: 'alert bg-green-success',
        bodyClassName: 'alert-body',
        progressClassName: 'invisible'
    }),
);

export const ScrollToTopOnce = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return null;
};

export const TabLink = forwardRef<any, any>((props, ref) => (
    <NavLink
        ref={ref}
        to={props.to}
        className={({ isActive }) => {
            const tabClass = ['tab-nav mr-6 md:mr-8'];
            if (isActive) tabClass.push('tab-nav-active');
            return tabClass.join(" ");
        }} end>
        {props.children}
    </NavLink>
));

export const VideoPopUp = ({ popSwitch, handleClose, mediaUrl, bgUrl, mediaSize, blur }:
    { popSwitch: boolean, handleClose?: any, mediaUrl: string, bgUrl?: string, mediaSize?: number, blur?: boolean }) => {
    const [mediaWidth, setMediaWIdth] = useState(window.innerWidth > window.innerHeight ? window.innerHeight : window.innerWidth);
    useEffect(() => {
        function handleResize() {
            setMediaWIdth(window.innerWidth > window.innerHeight ? window.innerHeight : window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    let mvpVideo: RefObject<HTMLVideoElement>;
    mvpVideo = createRef();

    if (!mediaSize || mediaSize > 1 || mediaSize < 0.1)
        mediaSize = 0.9;

    function StopNClose() {
        if (mvpVideo.current)
            mvpVideo.current.pause();
        handleClose();
    }
    return (
        <div >
            <Popup
                className='relative w-full h-full'
                open={popSwitch}
                onClose={handleClose}>
                <div onClick={StopNClose} className='fixed top-0 left-0 z-10 bg-black bg-opacity-90 h-screen w-screen' >
                    <div onClick={StopNClose} className='absolute z-50  cursor-pointer right-0 top-0 m-8' >
                        <IMG className='m-auto h-10 w-10' src='Close' />
                    </div>
                </div>
                {bgUrl ? <img onClick={StopNClose} className='absolute w-full h-full z-20 object-cover' src={bgUrl} style={blur ? { filter: 'blur(20px)', WebkitFilter: 'blur(20px)' } : {}} alt='' /> : ''}
                <div className='absolute-center z-40'
                    style={{
                        width: Math.round(mediaWidth * mediaSize), height: Math.round(mediaWidth * mediaSize),
                        boxShadow: '0 12px 19px 2px rgba(0, 0, 0, 0.15), 0 4px 23px 4px rgba(0, 0, 0, 0.1)'
                    }}>
                    <div className='w-full h-0 relative' style={{ paddingBottom: '100%' }}>
                        <video autoPlay loop
                            playsInline
                            id="MVP"
                            ref={mvpVideo}
                            controlsList={'nodownload'}
                            controls={true}
                            onContextMenu={e => { e.preventDefault() }}
                            className='w-full h-full absolute'
                            src={mediaUrl} itemType="video/mp4" />
                    </div>
                </div>

            </Popup>
        </div >
    )
}

interface SEOProps {
    title: string;
    url?: string;
    description?: string;
    children: any;
}

interface BtnProps {
    appearance?: 'default' | 'blue' | 'black' | 'rainbow';
    disabled?: boolean;
    className?: string;
    shadow?: boolean;
    [key: string]: any;
}

interface Props {
    appearance?: 'default' | 'blue' | 'black' | 'rainbow';
    disabled?: boolean;
    className?: string;
    shadow?: boolean;
    [key: string]: any;
}

interface FieldProps extends Props {
    type?: string;
    name: string;
    error?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => any;
    value?: string | number;
    backgroundColor?: string;
    mustHave?: boolean;
}

export const Field = ({
    disabled = false,
    type = 'text',
    autosize = false,
    name,
    className,
    error,
    placeholder,
    backgroundColor = '#404040',
    mustHave,
    ...props
}: FieldProps) => {
    if (!placeholder) placeholder = 'name';
    const inputProps = {
        type,
        name,
        id: name,
        placeholder: __(placeholder),
        className: cx(className),
    };

    let child;
    if (type === 'textarea' && autosize) {
        child = <TextAreaAutosize {...inputProps} {...props} style={{ backgroundColor }} />;
    } else if (type === 'textarea') {
        child = <textarea {...inputProps} {...props} style={{ backgroundColor }} />;
    } else if (type === 'currency') {
        let { type: displayType, ...rest } = inputProps;
        child = <NumberFormat displayType={displayType} {...rest} {...props} style={{ backgroundColor }} />;
    } else {
        child = <input {...inputProps} {...props} style={{ backgroundColor }} />;
    }

    return (
        <div
            className={cx('field-wrap', {
                'field-wrap-error': error,
            })}>
            {child}
            {error && (
                <small className='field-error block whitespace-pre-wrap'>
                    {__(error)}
                </small>
            )}
        </div>
    );
};

export const NumberFormat = (props: any) => {
    const { onChange, ...rest } = props;

    return (
        <NumericFormat
            {...rest}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix='TWD $'
        />
    );
};


export const Button = ({
    appearance = 'default',
    disabled = false,
    shadow = false,
    className,
    ...props
}: BtnProps) => {
    const cs = 'focus:outline-none btn ' +
        (shadow ? 'btn-shadow ' : '') +
        (disabled ? 'btn-disabled ' : '') +
        (appearance === 'blue' ? 'btn-blue ' : '') +
        (appearance === 'black' ? 'btn-black ' : '') +
        (appearance === 'rainbow' ? 'btn-rainbow ' : '');
    return <button className={cs + className} {...props} />;
};

export const SEO = (props: SEOProps) => {
    const { title, description, url, children } = props;
    return (
        <Helmet title={title} >
            <meta property='og:title' content={title} />
            {url ? <meta property='og:url' content={url} /> : ''}
            {description && [
                <meta key='name' name='description' content={description} />,
                <meta key='prop' property='og:description' content={description} />,
            ]}
            {children}
        </Helmet>
    );
};

export function ParseOpenSeaJson(asset: any, ownerAddr?: string) {
    let uriSplit = asset.animation_url ? asset.animation_url.split("/") : "";
    let re = /(?:\.([^.]+))?$/;
    // @ts-ignore
    let file = re.exec(uriSplit[uriSplit.length - 1])[1]
        ? // @ts-ignore
        re.exec(uriSplit[uriSplit.length - 1])[1].replace(/\s/g, "")
        : "";
    let owner = "";
    if (asset.owner) owner = asset.owner;
    if (
        (!owner || owner.substring(0, 5) === "0x000") &&
        asset.top_ownerships &&
        asset.top_ownerships.length > 0
    ) {
        //switch to top owner
        let topOwner = asset.top_ownerships[0];
        if (topOwner["owner"] && topOwner["owner"]["address"])
            owner = topOwner["owner"]["address"];
    }
    if (!owner && ownerAddr) owner = ownerAddr;
    if (!owner) owner = "0x0000000000000000000000000000000000000000";
    owner = owner.toLowerCase();
    let nft = {
        title: asset.name ? asset.name : asset.asset_contract.name,
        file: file === "" ? "" : `.${file}`,
        type: file === "mp3" || file === "wav" ? 0 : 1,
        length: 0,
        blockchain: "Ethereum",
        tokenId: asset.token_id,
        standard: asset.asset_contract.schema_name,
        media: asset.animation_url,
        contract: asset.asset_contract.address,
        checked: false,
        img: asset.image_url,
        owner,
    };
    return { nft, file };
}

export function GetDefaultAvatar(id?: number) {
    if (!id) id = 0;
    return 'https://fansi-static.s3.ap-southeast-1.amazonaws.com/MetaBoom/img/avatar/1.png'
}

export function GetDefaultCover(id?: number | string) {
    if (!id) id = 0;
    id = parseInt((id + '').replace('F', '').replace('S', ''));
    return 'https://fansi-static.s3.ap-southeast-1.amazonaws.com/MetaBoom/parts/default/00' + (id % 10) + '.png'
}

export const MusicManiacPopup = ({ popSwitch, handleClose }: { popSwitch: boolean, handleClose?: any }) => {

    let styleIndex = {
        marginLeft: 32,
        marginTop: 12,
        listStyleType: 'square',
        fontStyle: 'italic'
    };

    let styleContent = {
        marginLeft: 64,
        marginTop: 8,
        listStyleType: 'disc'
    };

    return (
        <div>
            <Dialog open={popSwitch} onClose={handleClose} fullScreen={false} maxWidth={'xl'}>
                <div className='flex w-full h-full text-white' style={{ backgroundColor: '#282828' }}>
                    <div className='m-auto p-4 lg:p-6 ' style={{ minWidth: 312, maxWidth: 800, minHeight: 580, maxHeight: 880 }}>
                        <IMG onClick={handleClose} className='w-8 h-8 absolute top-0 right-0 m-6 z-40 cursor-pointer' src='Close' />
                        <p className='text-left text-2xl lg:text-4xl font-bold '>Junkie score</p>
                        <li className='text-base lg:text-xl italic font-bold mt-6' style={{ listStyleType: 'disc' }}>Junkie score= (Stream+Favorite+Share)+Earned</li>
                        <div className='mt-2 text-base font-bold'>
                            <li className='ml-4 mt-3 lg:text-lg' style={{ listStyleType: 'circle' }}>Stream:Favorite:Share=1:2:3</li>
                            <li style={styleIndex}>Stream</li>
                            <ul style={styleContent}>
                                <li>Music streaming time per 30 seconds= 1 valid stream</li>
                                <li>A Stream is worth 150 points</li>
                                <li>The maximum score for a single day is 24,000 points</li>
                            </ul>
                        </div>
                        <div className='mt-2 text-base font-bold'>
                            <li style={styleIndex}>Favorite</li>
                            <ul style={styleContent}>
                                <li>You’ll earn points by subscribing to your favorite playlist (click on favorite, yes, the heart emoji)=200</li>
                                <li>Cancellation of Favorite will -200 points</li>
                            </ul>
                        </div>
                        <div className='mt-2 text-base font-bold'>
                            <li style={styleIndex}>Share</li>
                            <ul style={styleContent}>
                                <li>Click and visit a MB playlist link is worth 300 points </li>
                                <li>Each MB share link is only valid for 10 non-member visits and 1 member visit, so remember to generate new shared link each day to get maximum reward</li>
                                <li>A shared link is valid for 30 days</li>
                                <li>The maximum score for a single day is 72,000 points</li>
                            </ul>
                        </div>
                        <div className='mt-2 text-base font-bold'>
                            <li style={styleIndex}>Earned</li>
                            <ul style={styleContent}>
                                <li>{'Last Round Points*0.1 <= 240,000'}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export const MetaDJPopup = ({ popSwitch, handleClose }: { popSwitch: boolean, handleClose?: any }) => {

    let styleIndex = {
        marginLeft: 16,
        marginTop: 8,
        listStyleType: 'circle',
        fontStyle: 'italic'
    };

    let styleContent = {
        marginLeft: 48,
        marginTop: 2,
        listStyleType: 'square'
    };

    let styleInfo = {
        marginLeft: 16,
        marginTop: 2,
        listStyleType: 'disc'
    };

    return (
        <div>
            <Dialog open={popSwitch} onClose={handleClose} fullScreen={false} maxWidth={'xl'}>
                <div className='flex w-full h-full text-white' style={{ backgroundColor: '#282828' }}>
                    <div className='m-auto p-6 ' style={{ minWidth: 312, maxWidth: 880 }}>
                        <IMG onClick={handleClose} className='w-8 h-8 absolute top-0 right-0 m-6 z-40 cursor-pointer' src='Close' />
                        <p className='text-left text-2xl lg:text-4xl font-bold '>DJ score</p>
                        <li className='text-base lg:text-xl italic font-bold mt-6' style={{ listStyleType: 'disc' }}>DJ score= (Stream*Music Sources Bonus+Favorite+Share) MB Rarity Bonus+Earned</li>
                        <li className='text-base lg:text-xl italic font-bold mt-4' style={{ listStyleType: 'disc' }}>Stream:Favorite:Share=1:2:3</li>
                        <div className='mt-2 text-base font-bold'>
                            <li style={styleIndex}>Stream</li>
                            <ul style={styleContent}>
                                <li>Music streaming time per 30 seconds= 1 valid stream</li>
                                <li>A non-member valid Stream is worth 100 points</li>
                                <li>A member valid Stream is worth 150 points</li>
                                <li>The maximum score for a single day is 24,000 points</li>
                            </ul>
                        </div>
                        <div className='mt-2 text-base font-bold'>
                            <li style={styleIndex}>Music Sources Bonus</li>
                            <ul style={styleContent}>
                                <li>import free music from OpenSea=*1 (fixed number) </li>
                                <li>import music from your MM wallet=*1.2 (fixed number) </li>
                                <li>import FANSI drops </li>
                                <ul style={styleInfo}>
                                    <li>1-5 tracks=*1.5</li>
                                    <li>6-10 tracks=*1.8</li>
                                    <li>11-15 tracks=*2.3</li>
                                    <li>16-20 tracks=*3.1</li>
                                    <li>21-25 tracks=*4.4</li>
                                    <li>26-30 tracks=*6.5</li>
                                    <li>31-35 tracks=*9.9</li>
                                </ul>
                            </ul>
                        </div>
                        <div className='mt-2 text-base font-bold'>
                            <li style={styleIndex}>Favorite</li>
                            <ul style={styleContent}>
                                <li>A subscription for Favorite is worth 200 points</li>
                                <li>Cancellation of Favorite will -200 points</li>
                            </ul>
                        </div>
                        <div className='mt-2 text-base font-bold'>
                            <li style={styleIndex}>Share</li>
                            <ul style={styleContent}>
                                <li>An effective share is worth 300 points (p.s sharing is only effective when someone click on the link)</li>
                                <li>A shared link is valid for 10 non-member visits and 1 member visit (which means if the number of click exceeds these numbers, you’ll have to generate new link) </li>
                                <li>Refresh your shared link every day to get scores, a link is valid for 30 days. </li>
                                <li>The maximum score for a single day is 72,000 points</li>
                            </ul>
                        </div>
                        <div className='mt-2 text-base font-bold'>
                            <li style={styleIndex}>MB Rarity Bonus</li>
                            <ul style={styleContent}>
                                <li>Common=*1.2</li>
                                <li>Rare=*1.3</li>
                                <li>Epic=*1.5</li>
                                <li>Mythical=*1.8</li>
                                <li>Mythical Theme=*2.3</li>
                            </ul>
                        </div>
                        <div className='mt-2 text-base font-bold'>
                            <li style={styleIndex}>Earned</li>
                            <ul style={styleContent}>
                                <li>{'Last Round Points*0.1 <= 240,000'}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export const MusicScoreFarmingPopup = ({ popSwitch, handleClose }: { popSwitch: boolean, handleClose?: any }) => {
    return (
        <div>
            <Dialog open={popSwitch} onClose={handleClose} fullScreen={false} maxWidth={'xl'}>
                <div className='flex w-full h-full text-white rounded' style={{ backgroundColor: '#282828', border: 'solid 1px #089dc6' }}>
                    <div className='Roboto m-auto p-6 ' style={{ minWidth: 312, maxWidth: 599 }}>
                        <IMG onClick={handleClose} className='w-8 h-8 absolute top-0 right-0 m-6 z-40 cursor-pointer' src='Close' />
                        <p className='text-left text-2xl lg:text-4xl font-bold '>Music Score Farming</p>
                        <p className='text-left text-xl lg:text-2xl font-bold mt-8 gen2blue'>Daily Farming</p>
                        <p className='text-lg'>{`daily routine quests that can be repeated, and will refresh and reopen at 00:00 (UTC+8 ) each day.`}</p>
                        <p className='text-left text-xl lg:text-2xl font-bold mt-8 gen2blue'>Unique Challenges</p>
                        <p className='text-lg'>one-time quests that cannot be repeated once completed.</p>
                        <p className='text-left text-xl lg:text-2xl font-bold mt-8 gen2blue'>The Quests Value</p>
                        <p className='text-xl font-bold mt-2'>
                            <span className='mbYellow'>Advanced</span>{` 100 ：`}
                            <span className='mbRed'>Special</span>{` 10 ：`}
                            <span className='gen2blue'>Common</span>{` 1 `}</p>
                        <hr className='my-9' style={{ borderColor: '#6d6d6d' }} />
                        <p className='text-lg'>We will keep track of your quest performance as an important credential for $MMU airdrop rewards, which will determine how much $MMU you can win. Please try to complete all quests as much as possible to prove your passion and loyalty.</p>
                    </div>
                </div>
            </Dialog>
        </div>)
}

export const PopupMsgBlock = (
    { title, content, hash, shareTitle, popSwitch, handleClose, extBtn, shareImg, story, id, extraLink }:
        { title: string, content: string, hash?: string, shareTitle?: string, popSwitch: boolean, handleClose: any, extBtn?: any, shareImg?: string, story?: string, id?: number, extraLink?: string }) => {

    let permalink = window.location.origin + '/MetaBoom/' + id + '/';
    if (permalink.includes('?')) {
        permalink = permalink + '&t=' + current + extraLink;
    } else {
        permalink = permalink + '?t=' + current + extraLink;
    }

    const copyAddress = ((e: ClipboardEvent) => {
        e.clipboardData && e.clipboardData.setData('text/plain', permalink);
        e.preventDefault();
        document.removeEventListener('copy', copyAddress);
        alert('URL copied to clipboard');
    })

    let txtShareContent = 'Listen to this one and only music NFT playlist now. MetaBoom is everyone’s personal DJ booth, reimagined for the Web3 world!';

    return (
        <div>
            <Dialog open={popSwitch} onClose={handleClose} fullScreen={false}>
                <div className='flex w-full h-full bg-black ' style={{ border: 'solid 1px #089dc6' }}>
                    <div className='m-auto p-6 overflow-hidden' style={{ minWidth: 312, maxWidth: 528, minHeight: 300, maxHeight: 560 }}>
                        <IMG onClick={handleClose} className='w-6 h-6 absolute top-0 right-0 m-6 z-40 cursor-pointer' src='Close' />
                        <div className='w-full text-white text-xl font-bold pb-6'>
                            <p className='text-left text-2xl lg:text-3xl gen2blue'>{title}</p>
                            {content ? <p className='text-center text-base mt-6'>{content}</p> : ''}
                            {hash ? <div className='w-full flex justify-center text-xl mt-6'>
                                <a className='salmon' target='_blank' rel='noreferrer noopener' href={hash}>
                                    <div className='flex'>
                                        Etherscan
                                        <IMG className='w-6 h-6 mb-1' src='Share' />
                                    </div></a>
                            </div> : ''}
                            {//share player list
                                shareTitle && shareTitle !== "" ?
                                    <div>
                                        <img src={shareImg} className='w-full my-4 ' alt='share' />
                                        <p className='text-base lg:text-lg italic font-bold mb-2'>{shareTitle}</p>
                                        <p className='font-normal sharePlayList' style={{ fontSize: 14 }}>{story}</p>
                                        <div className='w-full flex flex-wrap items-center mt-10'>
                                            <div className='w-full lg:w-1/4 flex justify-center lg:justify-start'>
                                                <a
                                                    target='_blank'
                                                    className='mr-0 lg:mr-3 w-full '
                                                    rel='noopener noreferrer'
                                                    href={tweetUrl({
                                                        url: permalink,
                                                        // text: txtShareContent,
                                                        text: '🎧 Listen to this one and only music NFT playlist now. @metaboom_nft  is everyone’s personal DJ booth, reimagined for the Web3 world!  #Listen2Earn',
                                                    })}>
                                                    <Button className='w-full flex justify-center'><IMG className='w-4 h-4 my-auto' src='TwitterBlue' /><p className='ml-2 my-auto gen2blue text-sm'>Twitter</p></Button>
                                                </a>

                                            </div>
                                            <div className='mt-4 lg:mt-0 w-full lg:w-1/4 flex justify-center lg:justify-start'>
                                                <a
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    className='mr-0 lg:mr-3 w-full '
                                                    href={fbShareUrl({
                                                        url: permalink,
                                                        text: txtShareContent,
                                                    })}>
                                                    <Button className='w-full flex justify-center'><IMG className='w-4 h-4 my-auto' src='FBBlue' /><p className='ml-2 my-auto gen2blue text-sm'>Facebook</p></Button>
                                                </a>
                                            </div>
                                            <div className='hidden lg:flex mt-4 lg:mt-0 w-full lg:w-1/2  justify-center lg:justify-start'>
                                                <Button className='w-full flex justify-center' onClick={
                                                    //@ts-ignore
                                                    e => {
                                                        document.addEventListener('copy', copyAddress);
                                                        document.execCommand('copy');
                                                    }}><IMG className='w-4 h-4 my-auto' src='LinkBlue' /><p className='ml-2 my-auto gen2blue text-sm'>Copy Link</p></Button>
                                            </div>
                                            {/* <div className='w-full lg:w-1/4 flex justify-center lg:justify-start'>
                                                <div className='py-4 pr-2'>
                                                    <SocialIcon community='fb' title={shareTitle} className='w-12 h-12 ' id={id} />
                                                </div>
                                                <div className='py-4 pl-2'>
                                                    <SocialIcon community='twitter' title={shareTitle} className='w-12 h-12 ' id={id} />
                                                </div>
                                            </div>
                                            <div className='w-full lg:w-3/4 lg:pl-4'>
                                                <div className='p-4 w-full flex border border-white py-2 px-4 text-base rounded-3xl h-12' style={{ backgroundColor: '#5b5b5b' }}>
                                                    <div className='w-4/5'>
                                                        <p className='truncate font-normal'>{permalink}</p>
                                                    </div>
                                                    <div className='w-1/5  cursor-pointer' onClick={e => {
                                                        document.addEventListener('copy', copyAddress);
                                                        document.execCommand('copy');
                                                    }}>
                                                        <span className='font-bold salmon text-right'>copy</span>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div> : ''
                            }
                        </div>
                    </div>
                </div>
                {
                    shareTitle && shareTitle !== "" ? '' :
                        <div className='w-full absolute bottom-0 pt-12 p-4'>
                            {extBtn ? extBtn : ''}
                            <div className='w-full flex justify-center'>
                                <Button className='mt-6 h-8 w-full text-white' onClick={handleClose} >
                                    Close
                                </Button>
                            </div>
                        </div>
                }
            </Dialog >
        </div >
    );
}

export const ChatRoom = () => {
    const [showLobby, setLobby] = useState(false);
    useEffect(() => {
        const frame = document.getElementById("proticoFrame") as HTMLIFrameElement;
        const bObj = document.getElementById("frameBoxHandler") as HTMLDivElement;
        bObj.onclick = () => {
            if (frame.style.display === 'block') {
                frame.style.display = 'none';
                setLobby(false);
            } else {
                frame.src = "https://main.protico.io/protico?roomUrl=" + window.location.origin;
                frame.style.display = 'block';
                setLobby(true);
            }
        };
    }, [setLobby]);

    return (
        <div className='hidden lg:block z-40'>
            <span style={{ position: 'fixed', bottom: '116px', left: '20px', background: 'transparent' }}>
                <iframe
                    title='Protico Chat'
                    id='proticoFrame'
                    className='bg-blue-darkest'
                    style={{
                        display: 'none',
                        border: '1px solid #089dc6',
                        borderRadius: '8px',
                        boxShadow: '2px 2px rgba(0,0,0,0.2)',
                        width: '310px',
                        height: '500px',
                        marginBottom: '0.5em',
                        minWidth: '325px',
                        minHeight: '400px',
                    }}
                />
                <div className='w-full justify-end flex'>
                    <button id='frameBoxHandler' className={(showLobby ? 'bg-darkBlueHL' : 'bg-gen2blue') + ' w-12 h-12'}
                        style={{ border: 'solid 1px', borderRadius: 68, borderColor: (showLobby ? '#089dc6' : '#000') }}>
                        <p className={'my-auto w-full text-center text-xl NotoSans font-medium ' + (showLobby ? 'text-white' : 'text-black')}>
                            {showLobby ? '👋' : '💬'}</p>
                    </button>
                </div>
            </span >
        </div >
    )
}

export const PageNation = ({ count, index, callBack }: { count: number, index: number, callBack?: any }) => {
    const DisplayIndex = 4; // will display + 1;
    const [currIndex, setCurrIndex] = useState(index);
    function HandleClick(newIndex: number) {
        if (!callBack || currIndex === newIndex || newIndex < 1 || newIndex > count) return;
        callBack(newIndex);
        setCurrIndex(newIndex);
    }

    let list: number[] = [];
    for (let i = 1; i <= count; i++) list.push(i);

    return (
        <div className='flex'>
            <div className={'w-10 h-10 flex ' + (currIndex === 1 ? 'opacity-50' : 'cursor-pointer')} onClick={() => HandleClick(1)}>
                <IMG src='SkipPrevious' className='w-6 h-6 m-auto' />
            </div>
            <div className={'w-10 h-10 flex ' + (currIndex === 1 ? 'opacity-50' : 'cursor-pointer')} onClick={() => HandleClick(currIndex - 1)}>
                <IMG src='ArrowLeft' className='w-6 h-6 m-auto' />
            </div>
            {Object.keys(list).map((k: any) => {
                let newIndex = list[k];
                let startIndex = currIndex - 2;
                if (startIndex < 1) startIndex = 1;
                let endIndex = startIndex + DisplayIndex;
                if (endIndex > count) {
                    endIndex = count;
                    startIndex = endIndex - DisplayIndex;
                }
                if (newIndex < startIndex || newIndex > endIndex) return "";
                return (
                    <div className={'w-10 h-10 flex ' + (currIndex === newIndex ? ' rounded-full bg-gen2blue' : ' cursor-pointer')} onClick={() => HandleClick(newIndex)} key={'index-' + k}>
                        <p className='m-auto text-sm Roboto'>{newIndex}</p>
                    </div>
                )
            })}
            <div className={'w-10 h-10 flex ' + (currIndex < count ? 'cursor-pointer' : 'opacity-50')} onClick={() => HandleClick(currIndex + 1)}>
                <IMG src='ArrowRight' className='w-6 h-6 m-auto' />
            </div>
            <div className={'w-10 h-10 flex ' + (currIndex < count ? 'cursor-pointer' : 'opacity-50')} onClick={() => HandleClick(count)}>
                <IMG src='SkipNext' className='w-6 h-6 m-auto' />
            </div>
        </div>
    )

}

export function ChkLocalFlag(name: string, gap: number) {
    const curr = Math.round(new Date().getTime() / 1000);
    let item = parseInt(localStorage.getItem(name) + '');
    if (!item) item = 0;
    if (curr - item > gap) return true;
    return false;
}

export function SetLocalFlag(name: string) {
    const curr = Math.round(new Date().getTime() / 1000);
    localStorage.setItem(name, curr + '');
}

export const DailyPopup = () => {
    interface adsProp {
        id: number,
        title: string,
        url: string,
        img: string,
        imgM: string,
    }
    const [adsList, setAdsList] = useState<adsProp[]>([]);
    const [showPopup, setPopup] = useState(false);
    const [timer, setTimer] = useState(0);
    const [currAd, setAd] = useState(0);
    const GET_ADS = gql`
    query GetDailyAds {
        ads: getDailyAds(type:1)
    }
    `;
    const { loading } = useQuery(GET_ADS, {
        onCompleted: (data: any) => {
            if (data.ads) {
                const ads = data.ads;
                let newList: adsProp[] = []
                for (let i = 0; i < ads.length; i++) {
                    let ad = JSON.parse(ads[i]);
                    newList.push({ ...ad })
                }
                setAdsList(newList);
            }
        }
    });

    useEffect(() => {
        if (showPopup && adsList.length > 1) {
            const interval = setInterval(() => {
                let currTimer = timer + 1000;
                if (currTimer >= 7000) {
                    let newAd = currAd + 1;
                    if (newAd >= adsList.length) newAd = 0;
                    setTimer(0);
                    setAd(newAd);
                } else {
                    setTimer(currTimer);
                }
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [currAd, showPopup, adsList, timer, setTimer])

    useEffect(() => {
        const popupKey = 'MB-DailyPopup';
        const curr = Math.round(new Date().getTime() / 1000);
        const popupGap = 86400; // 1day
        let lastPopup = localStorage.getItem(popupKey);
        if (!lastPopup) lastPopup = '0';
        if (curr - parseInt(lastPopup) > popupGap) {
            setPopup(true);
            localStorage.setItem(popupKey, curr + '');
        }
    }, [setPopup])

    if (loading || adsList.length < 1) return null;

    if (!showPopup) return null;

    function SwitchAd(index: number) {
        if (index === currAd || index < 0 || index >= adsList.length) return;
        setAd(index);
        setTimer(0);
    }

    return (
        <div className='fixed top-0 left-0 w-screen h-screen overflow-hidden z-999'>
            <div className='w-full h-full bg-black bg-opacity-70 px-6 py-20 flex overflow-y-auto'>
                <div className='relative py-10 w-full mx-auto min-h-[565px] max-w-[735px] bg-black border border-blue-dark rounded-md'>
                    <IMG onClick={() => setPopup(false)} src='Close' className='z-50 absolute right-0 top-0 w-6 h-6 m-5 lg:m-7 cursor-pointer' />
                    <div className='relative w-full h-full overflow-hidden'>
                    {Object.keys(adsList).map((k: any) => {
                        let ad = adsList[k];
                        let current = parseInt(k) === currAd;                        
                        return (
                            <div className={(current ? 'fade-in-image ' : 'fade-out-image opacity-0 ') + ' absolute left-0 top-0 w-full h-full'}>
                                <Link onClick={() => setPopup(false)} to={ad.url}
                                    className={'absolute w-full max-h-full px-5 lg:px-9 z-40'}>
                                <div className='relative w-full h-full'>
                                    <p className='Roboto pr-4 lg:pr-0 w-full lg:w-4/5 font-extrabold text-[22px] lg:text-[30px] leading-tight'>{ad.title}</p>
                                        <img className='hidden lg:block w-full mt-8' src={ad.img} alt='popup-banner' />
                                        <img className='block lg:hidden w-full mt-4' src={ad.imgM} alt='popup-banner' />
                                </div>
                            </Link>
                            </div>
                        )
                    })}
                    </div>
                    {adsList && adsList.length > 1 ?
                        <div className='absolute bottom-0 h-10 w-full flex z-40'>
                            <div className='mx-auto pl-3 my-auto flex'>
                                {Object.keys(adsList).map((k: any) => {
                                    let current = parseInt(k) === currAd;
                                    return <div className={(current ? 'bg-gen2blue' : ' cursor-pointer bg-[#d9d9d9]') + ' w-[9px] h-[9px] rounded-full mr-3'}
                                        onClick={() => { SwitchAd(parseInt(k)) }} />
                                })}
                            </div>
                        </div> : ''}
                </div>
            </div>
        </div>
    )
}

export const LabelStatus = ({ status, className }: { status: number, className?: string }) => {
    let txtStatus = __('PH_ComingSoon');
    let size = 'w-24';
    let backgroundColor = '#FF52B9';
    switch (status) {
        case 2:
            txtStatus = __('ForSale');
            size = 'w-16';
            backgroundColor = 'var(--mbYellow)';
            break;
        case 3:
            txtStatus = __('Released');
            size = 'w-18';
            backgroundColor = '#fff';
            break;
    }
    return (
        <div className={(status === 1 ? 'text-white ' : '') + 'flex rounded-3xl h-6 ' + size + ' ' + (className ? className : '')} style={{ backgroundColor }}>
            <p className={'w-full my-auto text-center text-xs text-black Roboto font-medium'}>{txtStatus}</p>            
        </div>
    )
}

export const ArtistBlock = ({avatar, name, state, url}:{avatar:string, name:string, state:number, url:string})=>{
    return (
        <Link to={url}>
            <div className='relative w-full pt-10 lg:pt-16'>
                <div className='horizon-center top-0 w-[82px] lg:w-[115px] aspect-square border border-white rounded-full overflow-hidden' style={{ boxShadow: '0 6px 7px 0 rgba(0, 0, 0, 0.25)' }}>
                    <img src={avatar} className='w-full h-full object-cover' alt='avatar' />
                </div>
                <div className='w-full rounded-md pt-16 lg:pt-[74px] pb-4 lg:pb-6' style={{ border: 'solid 1px #256973', backgroundImage: 'linear-gradient(133deg, #bc6786 2%, #205f7a 17%, #1f4a59 65%, #072e36 92%)' }}>
                    <div className='flex justify-center'>
                        <p className='text-xs lg:text-base Roboto font-medium text-center '>{name}</p>
                        <IMG className='my-auto ml-1 h-4 w-4' src='GreenCheck' />
                    </div>
                    <LabelStatus status={state} className='Roboto mt-2 mx-auto text-xs lg:text-sm font-medium' />
                </div>
            </div>
        </Link >
    )

}


