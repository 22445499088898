import { Cookies } from 'react-cookie';

export const SITE_COOKIE = 'FansiAuthInfo';

class FansiUserSession {
  private accessToken: any;
  constructor() {
    this.accessToken = '';
  }

  public getAccessToken(): any {
    return this.accessToken;
  }

  public setAccessToken(token: any) {
    this.accessToken = token;
  }
}

class FansiUser {
  private _signInUserSession: FansiUserSession = new FansiUserSession();
  get signInUserSession(): FansiUserSession {
    return this._signInUserSession;
  }
  set signInUserSession(userSession: FansiUserSession) {
    this._signInUserSession = userSession;
  }
}

class UserAuthCls {
  private static _instance: UserAuthCls;
  private user: FansiUser | null;
  private authState: boolean;
  private constructor() {
    this.user = null;
    this.authState = false;
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public signOut(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.user = null;
      this.authState = false;
      const cookies = new Cookies();
      cookies.remove(SITE_COOKIE, { path: '/' });
      resolve(true);
    });
  }

  public isAuthenticate() {
    return this.authState;
  }

  public currentAuthenticatedUser(): Promise<FansiUser | any> {
    const cookies = new Cookies();
    let token = cookies.get(SITE_COOKIE);
    if (typeof token !== 'undefined') {
      let session = new FansiUserSession();
      let user = new FansiUser();
      session.setAccessToken(token.accessToken);
      user.signInUserSession = session;
      this.user = user;
      this.authState = true;
    } else {
      this.user = null;
      this.authState = false;
    }
    return new Promise((resolve, reject) => {
      if (this.user !== null) {
        resolve(this.user);
      } else {
        reject(new Error('something wrong'));
      }
    });
  }
}

const UserAuth = UserAuthCls.Instance;

export default UserAuth;
