import path from 'ramda/es/path';
import { zh, en } from './dict-translation';

const DEFAULT_LANG = 'en';
let _lang: string = DEFAULT_LANG;
let defaultLang: { [k: string]: string } = zh;

function AvailableLanguage(lang: string) {
  const langList = ['en', 'zh']; // available language other then Chinese
  if (langList.indexOf(lang) !== -1) {
    return lang;
  } else {
    return DEFAULT_LANG;
  }
}

export const setLanguage = (lang: string): boolean => {
  try {
    lang = AvailableLanguage(lang);
    _lang = lang;
    switch (_lang) {
      case 'zh':
        defaultLang = zh;
        break;
      default:
        defaultLang = en;
        break;
    }
    localStorage.setItem('fansi:lang', lang);
    return true;
  } catch (err) {
    return false;
  }
};

export const getLanguage = (): string => {
  return AvailableLanguage(_lang || path(['navigator', 'language'], window) || '');
};

// Hydrate user-set language if present
try {
  const lang = localStorage.getItem('fansi:lang');
  if (lang) {
    setLanguage(lang);
  } else {
    const newLang = DEFAULT_LANG;
    localStorage.setItem('fansi:lang', newLang);
    setLanguage(newLang);
  }
} catch (err) {
  console.warn('[error]: Could not set language ');
}

const get = (x: string): string => {
  if (x === null) return '';
  x = x.replace(/^GraphQL error:\s+?/i, ''); //remove graphql error
  if (defaultLang[x]) return defaultLang[x];
  return x;
};

export default get;
