export const zh = {
  DC_INVITE_LINK: 'https://discord.gg/vtkV88bN2E',
  FANSI_IG: 'https://www.instagram.com/fansi_nft',
  FANSI_Twitter: 'https://twitter.com/metaboom_nft',
  FANSI_LinkTree: 'https://linktr.ee/fansi_nft',
  FANSI_OpenSea: 'https://opensea.io/collection/metaboom-nft',
  MetaBoom_URL: 'https://metaboom.fansi.me',
  RisingStars_URL: 'https://www.fansi.me/hatching',
  APP_Google: 'https://play.google.com/store/apps/details?id=me.fansi.app',
  APP_Apple: 'https://apps.apple.com/tw/app/metaboom/id6444411102',
  Lang_Zh: '繁中',
  Lang_En: 'EN',
  About: '關於',
  Home_Desc: 'MetaBoom - DJ in Metaverse  by Fansi',
  Web_Default_Desc:
    "MetaBooms are chatty and feisty metaverse beings who are said to be the spirits of 80s' boomboxes wandering the world after walkman took over.Each crew contains 5, 000 brave yet loud MetaBooms searching for their DJ masters, and the first crew will start from the Ape communities.",
  HP_Title: 'FANSI Rising Star',
  Start_at: '鑄造時間',
  End_Date: '結束時間',
  PH_ComingSoon: '即將發售',
  ForSale: '發售中',
  Released: '已結束',
  Whitelist_Info: '白名單身份享有額外加密支付折扣',
  Edition: '已鑄造',
  How2Mint: '如何鑄造？',
  DiscountInfo: '折扣問題請聯絡我們',
  HP_Detail: '創作介紹',
  Contract_Detail: '合約資訊',
  PH_UserRight: '購買權益',
  PH_UserRight1: '您將通過區塊鏈鑄造全球限量的NFT蒐藏品，它具被稀有性與可追溯性。',
  PH_UserRight2: '您可以更便利的在Opensea或任何二級交易平台，通過您的錢包地址展示與交易蒐藏品。',
  PH_UserRight3: '您可以通過Web3音樂播放器-MetaBoom，更順暢的播放與分享你的音樂NFT蒐藏。',
  PH_UserRight4: '授權代碼CC BY-NC-SA：音樂人仍然保有著作與人格權，您可以展示或傳輸您的蒐藏品或其他非商業行為。',
  PH_Transactions: '鑄造紀錄',
  Minted: '已鑄造',
  PH_pay_for: '支付',
  ChkOnPolyScan: '在Polygonscan查看更多紀錄',
  show_more: '查看更多',
  Contract_Address: '合約地址',
  Blockchain: '公鏈',
  Token_Standard: '標準協議',
  Purchase_Quantity: '選擇數量',
  Total_Price: '總計',
  Select_PayMethod: '選擇付款方式',
  Hatching_Plan_Detail:
    'FANSI 將於每個星期二協助有才華的新銳音樂藝術家，推出他們的音樂 NFT！\n\n透過我們的計畫，你將有機會免費將你的作品發行成 NFT。同時，我們會利用 FANSI 平台的社群資源，提供系列的行銷方案協助銷售。如果你擁有音樂創作的完整版權，快點擊下方連結跟我們聯繫！',
  Share_Earn_Title: '分享獲得最高 12% 收益！',
  Share_Earn_Info: '加入我們的音樂推廣計畫，每一個透過你專屬邀請連結而產生的購買行為，將為你帶來最高 12% 的銷售分潤！',
  AboutUs: '關於我們',
  FANSI_Story:
    'FANSI 作為 Web3 音樂產業的先驅，我們的任務是為音樂創作者提供優質的發行策略，並為收藏家提供安全可信賴的收藏及投資環境。\n\n以此為出發點，我們希望可以跟我們的社群攜手，創造出一個更友善的音樂產業。',
  MetaBoom_Story:
    'MetaBoom 是一個人的 DJ 台，讓你可以輕鬆地整合你的音樂 NFT 收藏，並透過分享音樂獲得獎勵！\n\n我們希望可以和社群一起建造一個更有效率的收藏家經濟，讓 NFT 音樂收藏能變得更有價值，支持更多的音樂人持續創作。',
  UpcomingDrops: '即將發售',
  FeaturedDrops: '最新發售',
  ViewDrop: '更多資訊',
  ShareMint_Title: '分享獲得最高 12% 收益！',
  ShareMint_Info_Content:
    '加入我們的音樂推廣計畫，每一個透過你專屬邀請連結而產生的購買行為，將為你帶來最高 12% 的銷售分潤！\n\n立刻進入 Sharemint 網站連結你的加密錢包，取得你的專屬邀請連結！!',
  ShareMint_Button: '使用ShareMint分享',
  MirrorWorld_Info: '您正在使用 Mirror World 付款。\n請明確完成所有步驟。\n\n您在二手市場上的收藏品可能預設為隱藏狀態，請手動取消隱藏。',
  NFT_Release_Date: '發行日',
  NFT_Sale_End: '銷售一空',
  Token_Stander: '合約標准',
  Contact_Us: '聯絡我們',
  MM_Not_Login: '請登入/解鎖MetaMask，然後重新讀取此頁',
  MM_App_Scan: '請使用MetaMask app掃描開啟連結',
  MM_App_Open: '點擊使用MetaMask開啟',
  MM_Switch_Netework: '切換區塊鏈網路',
  MM_Invalid_Network: '錯誤的區塊鏈網路，請切換至{0}',
  MM_Sold_Out: '販售一空!',
  Got_It: '我知道了',
  ERC6551_INFO_LINK: 'https://sweet-angle-e85.notion.site/What-is-ERC-6551-0f4eb57d693b445897c6becd7dfae7a1?pvs=4',
};

// Placeholder english dictionary.
export const en = {
  ...zh,
  About: 'About',
  Home_Desc: 'MetaBoom - DJ in Metaverse  by Fansi',
  Web_Default_Desc:
    "MetaBooms are chatty and feisty metaverse beings who are said to be the spirits of 80s' boomboxes wandering the world after walkman took over.Each crew contains 5, 000 brave yet loud MetaBooms searching for their DJ masters, and the first crew will start from the Ape communities.",
  Start_at: 'Start at',
  End_Date: 'Ends at',
  ForSale: 'For Sale',
  Released: 'Released',
  PH_ComingSoon: 'Coming Soon',
  Whitelist_Info: ' Whitelist owners receive discounts on MATIC',
  Edition: 'Edition',
  How2Mint: 'How to mint?',
  DiscountInfo: 'Contact us to get discounts',
  HP_Detail: 'Detail',
  Contract_Detail: 'Contract Details',
  PH_UserRight: 'About your right',
  PH_UserRight1: 'You will mint a limited edition NFT which has the attribute of scarcity and traceability on the blockchain.',
  PH_UserRight2: 'You will be able to trade and display your collections on OpenSea or any secondary marketplace via your wallet address.',
  PH_UserRight3: 'You can play and share your music NFT collections smoothly via the Web3 music player - MetaBoom.',
  PH_UserRight4: 'NFT authorization CC BY-NC-SA: Artists hold the copyright of the music, but you can transmit or display your collections in any non-commercial activity.',
  PH_Transactions: 'Transactions',
  Minted: 'Minted',
  PH_pay_for: 'for',
  ChkOnPolyScan: 'Show more on Polygonscan',
  show_more: 'Show more',
  Contract_Address: 'Contract Address',
  Blockchain: 'Blockchain',
  Token_Standard: 'Token Standard',
  Purchase_Quantity: 'Select Quantity',
  Total_Price: 'Total Price',
  Select_PayMethod: 'Choose payment method',
  Hatching_Plan_Detail:
    'FANSI provides the platform to support talented artists to publish their music on web3. With this plan, FANSI will launch a weekly music drop to celebrate the rising stars in web3!\n\nIf you are an artist or musician, this plan is for you. We provide an opportunity to publish your music for FREE, along with a complete solution to help you tap into web3 market and web3 music tokenomics. Contact us now for more detail information.',
  Share_Earn_Title: 'Share&Earn up to 12% !',
  Share_Earn_Info:
    'Become an affiliate of the drop, share and invite people to buy the drop with your tailored referral link, and get up to 12% revenue shares for every successful sale generated from you!',
  AboutUs: 'About Us',
  FANSI_Story:
    'FANSI is leading the music industry into web3. Our mission is to create a supportive environment for musicians and music lovers. On our platform, you are able to collect and partner with your favorite musicians. With that in mind, we created our genesis collection known as MetaBoom.',
  MetaBoom_Story:
    'MetaBoom is your personal DJ booth and music player, reimagined for the web3 era! With our Listen-2-Earn design, you are rewarded when curating your music NFTs playlist and showcase that to the world. Together we are building a stronger community for the ownership economy. Join us as we explore and reshape the music indusrty in web3.',
  UpcomingDrops: 'Upcoming Drops',
  FeaturedDrops: 'Featured Drops',
  ViewDrop: 'View Drop',
  ShareMint_Title: 'Share & Earn up to 12%!',
  ShareMint_Info_Content:
    'Become an affiliate of the drop, share and invite people to buy the drop with your tailored referral link, and get up to 12% revenue shares for every successful sale generated from you!\n\nClick here to connect wallet on Sharemint and get your referral link now!',
  ShareMint_Button: 'Using ShareMint to share',
  MirrorWorld_Info:
    'You are making a payment using Mirror World.\nRemember to complete all steps.\n\nYour collections on the secondary market may be hidden by default. Please manually unhide them.',
  NFT_Release_Date: 'Release Date',
  NFT_Sale_End: 'End of Sale',
  Token_Stander: 'Token Stander',
  Contact_Us: 'Contact Us',
  MM_Not_Login: 'Please unlock/login MetaMask and refresh page.',
  MM_Invalid_Network: 'Invalid ethereum network, \nplease switch your metamask network to {0}',
  MM_Switch_Netework: 'Switch NetWork',
  MM_App_Scan: 'Please scan with your MetaMask app',
  MM_Sold_Out: 'Sold Out!',
  MM_App_Open: 'Click to open with MetaMask app',
  Got_It: 'I Got it',
  ERC6551_INFO_LINK: 'https://sweet-angle-e85.notion.site/What-is-ERC-6551-0f4eb57d693b445897c6becd7dfae7a1?pvs=4',
};
