import { useState } from 'react';
import { Link } from "react-router-dom";
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Personal, Web3 } from 'web3';
import { Contract } from 'web3-eth-contract';
import { ContractAbi } from 'web3-types';
import qs from 'qs';

import { Button, GetDefaultAvatar, ChatRoom, DailyPopup } from '../lib/components';
import { withAuthState } from '../lib/auth';
import { MiniPlayer } from '../lib/MiniPlayer';
import { IMG } from '../lib/imgLib';
import __, { setLanguage, getLanguage } from '../lib/gettext';
import UserAuth from '../lib/userAuth';
import './css/home.css';

declare global {
    interface Window {
        fixedNav: boolean;
        ethereum: any;
        personal: Personal | undefined;
        web3api: Web3 | undefined;
        contract: Contract<ContractAbi> | undefined;
        MBSwitch: any;
        MBList: number[];
    }
}

const MetaBoom: MenuItemProps[] = [
    {
        title: 'MetaBoom',
        forward: 1,
        url: '/',
        info: 'Best web3 music player of the people, by the people, for the people.',
        status: 0
    },
    {
        title: 'Exlpore',
        forward: 1,
        url: '/explore',
        info: 'Explore and discover fresh web3 music',
        status: 0
    },
    {
        title: 'Score Farming',
        forward: 1,
        url: '/collection/0/myFarming',
        info: 'Collect music scores and earn your eligibility for rewards.',
        status: 0
    },
    {
        title: 'Leaderboard',
        forward: 1,
        url: '/leaderboard',
        info: 'Join the crusade and get recongnized !',
        status: 0
    },
    {
        title: 'Faith Nomad',
        forward: 1,
        url: '/FaithNomad',
        info: 'The MetaBoom verse Event to get ERC-6551 Airdrop.',
        status: 0
    },
    {
        title: 'Never Forget',
        forward: 1,
        url: '/NeverForget',
        info: 'Discover the Future of Music Through Innovation',
        status: 0
    },
]

const RisingStars: MenuItemProps[] = [
    {
        title: 'Rising Stars',
        forward: 1,
        url: '/hatching/',
        info: 'Look out for the next superstar!',
        status: 0
    },
    {
        title: 'Artists',
        forward: 1,
        url: '/hatching/artist',
        info: 'FANSI Free mint event drop',
        status: 1
    },
]

const LanguageSelector = () => {
    const [displaySelector, setDisplaySelector] = useState(false);
    const currentLang = getLanguage();

    const handleSetLanguage = (language: string): void => {
        setDisplaySelector(!displaySelector)
        if (language === currentLang)
            return;
        setLanguage(language);
        window.location.reload();
    };

    return (
        <div className='relative lg:vertical-center my-auto w-12 lg:w-8 flex mr-2' >
            <div className='text-lg lg:text-base  cursor-pointer' onClick={() => handleSetLanguage(currentLang === 'en' ? 'zh' : 'en')}>
                {currentLang === 'en' ? __('Lang_Zh') : __('Lang_En')}</div>
        </div >
    );
};

interface MenuItemProps {
    title: string,
    url: string,
    forward: number, //1 link 2 a href
    info: string,
    status: number,//0 default 1 new 2 coming soon    
    clickCall?: any, //extra call when click
}

const WhitePaperUrl = 'https://metaboom.gitbook.io/metaboom-whitepaper/'
const SupportUrl = 'https://sweet-angle-e85.notion.site/FANSI-MetaBoom-Support-Center-9279528833bd488dac5fb2d6eb3153f7';

const ProfileDropdown = ({ hidden, setHidden }: any) => {
    const toggleHidden = () => {
        if (hidden) {
            setHidden(false, 'profile');
        } else {
            setHidden(true, 'profile');
        }
    }

    let index = 1;
    return (
        <div className='relative flex lg:hidden'>
            <div className='w-full flex ' style={{ zIndex: 1001 }}>
                <div className={'hamburger my-auto ' + (hidden ? '' : 'is-active')} onClick={toggleHidden} >
                    <span className='line' />
                    <span className='line' />
                    <span className='line' />
                </div>
                <a href='https://www.fansi.me' className='my-auto'>
                    <IMG src='LogoFull' className='ml-4 h-7' />
                </a>
            </div>

            <div className={'z-999 fixed w-screen h-screen bg-black left-0 top-0 pt-12 lg:py-12' + (hidden ? ' hidden' : ' flex')} >
                <div className='w-full h-full overflow-y-scroll bg-black p-6'>
                    <IMG src='MetaBoomH' className='w-22' />
                    {MetaBoom.map((item: MenuItemProps) => {
                        return <MenuItem {...item} clickCall={toggleHidden} key={'Menu-' + index++} />
                    })}
                    <div className='flex flex-wrap p-4'>
                        <p className='w-full Roboto font-bold text-xl mb-3'>Download</p>
                        <a href="https://play.google.com/store/apps/details?id=me.fansi.app" target="_blank" rel="noreferrer noopener" >
                            <IMG className='h-8 mr-2' src='GooglePlay' />
                        </a>
                        <a href="https://apps.apple.com/tw/app/metaboom/id6444411102" target="_blank" rel="noreferrer noopener" >
                            <IMG className='h-8' src='AppleStore' />
                        </a>
                    </div>
                    <div className='h-px my-5 w-full bg-gray-4a' />
                    <IMG src='RisingStarsH' className='h-6' />
                    {RisingStars.map((item: MenuItemProps) => {
                        return <MenuItem {...item} clickCall={toggleHidden} key={'Rising-' + index++} />
                    })}
                    <div className='h-px w-full bg-gray-4a my-3' />
                    <Link className='text-white pl-2 h-10 flex' to={WhitePaperUrl} target='_blank'>
                        <p className='my-auto Roboto font-bold '>White Paper</p>
                    </Link>
                    <Link className='text-white pl-2 h-10 flex' to={SupportUrl} target='_blank'>
                        <p className='my-auto Roboto font-bold '>Support</p>
                    </Link>
                    <div className='h-px w-full bg-gray-4a my-3' />
                    <div className='w-full flex flex-wrap relative'>
                        <div className='w-full flex h-10 pl-2'>
                            <a className='my-auto mr-4' href={__('FANSI_Twitter')} target="_blank" rel="noreferrer noopener">
                                <IMG className='w-7 h-7' src='Twitter' />
                            </a>
                            <a className='my-auto mr-4' href={__('DC_INVITE_LINK')} target="_blank" rel="noreferrer noopener">
                                <IMG className='w-7 h-7' src='Discord' />
                            </a>
                            <a className='my-auto mr-4' href={__('FANSI_IG')} target="_blank" rel="noreferrer noopener">
                                <IMG className='w-7 h-7' src='IG' />
                            </a>
                            <a className='my-auto mr-4' href={__('FANSI_LinkTree')} target="_blank" rel="noreferrer noopener">
                                <IMG className='w-7 h-7' src='LinkTree' />
                            </a>
                            <div className='w-2/5 flex justify-end'>
                                <LanguageSelector />
                            </div>
                        </div>
                        <p className='w-full  Roboto text-xs text-center whitespace-pre' style={{ color: '#6d6d6d' }}>{'©' + new Date().getFullYear() + ' FANSI. All rights reserved.'}</p>
                    </div>
                </div>
            </div>
        </div >
    );
};

const Header = () => {
    const [walletHidden, setWalletHidden] = useState(true);
    const [profileHidden, setProfileHidden] = useState(true);
    const [smartLookChk, setSmartLookChk] = useState(false);
    const GET_HEADER_PROFLE = gql`
        query GetHeaderProfile {
            me {
            id
            avatarUrl
            email
            displayName
            walletAddress
            }
            accountStatus: getUserAccountStatus
        }
        `;
    const { loading, data } = useQuery(GET_HEADER_PROFLE, {
        fetchPolicy: 'network-only',
    });
    const url = window.location.href.toLowerCase();

    // const MINI_PLAYER_BLACKLIST = ['/login', '/miniboom']
    let showPlayer = true;
    // MINI_PLAYER_BLACKLIST.forEach((blackList) => {
    //     blackList = blackList.toLowerCase();
    //     if (url.includes(blackList)) {
    //         showPlayer = false;
    //     }
    // });
    if (loading) return <div className='fixed w-full top-0 z-90 h-12 lg:h-16 flex bg-black bg-opacity-60' />;
    const user = data && data.me && data.me.id > 0 ? data.me : undefined;
    const userId = data && data.me ? data.me.id : 0;
    const accountStatus = data ? data.accountStatus : 0;

    //smartlook DAU trace
    if (user && !smartLookChk) {
        //chk timer
        const traceKey = 'SMTrace-' + user.id;
        let lastTrace = localStorage.getItem(traceKey);
        if (!lastTrace) lastTrace = '0';
        const curr = new Date();
        const today = curr.getFullYear() * 10000 + (curr.getMonth() + 1) * 100 + curr.getDate();

        if (today > parseInt(lastTrace + '')) {
            //@ts-ignore
            const smartlook = window.smartlook;
            if (typeof smartlook === 'function') {
                const eventName = 'MBDAU';
                const properties = {
                    "wallet": user.walletAddress
                };
                try {
                    //save trace day
                    localStorage.setItem(traceKey, today + '');
                    smartlook('track', eventName, properties);
                    setSmartLookChk(true);
                } catch (err) {
                    console.warn(err)
                }
            }
        }
    }

    if ((accountStatus === 1) && !url.includes('/login')) {
        window.location.href =
            window.location.origin +
            '/login?' + qs.stringify({ n: window.location.pathname }) + '&UpdAccount=YES&accountStatus=' +
            accountStatus;
        return null;
    }

    function SetMenuHidden(hidden: boolean, menu: string) {
        switch (menu) {
            case 'wallet':
                if (hidden) {
                    setWalletHidden(true);
                } else {
                    setWalletHidden(false);
                    if (!profileHidden) setProfileHidden(true);
                }
                break;
            default:
                if (hidden) {
                    setProfileHidden(true);
                } else {
                    setProfileHidden(false);
                    if (!walletHidden) setWalletHidden(true);
                }
                break;
        }
    }

    return (
        <div className='fixed w-full top-0 z-90 h-12 lg:h-16 flex bg-black bg-opacity-60'>
            <ChatRoom />
            <DailyPopup />
            {showPlayer ? <div className='fixed w-full bottom-0 z-50'>
                <MiniPlayer />
            </div> : ''}
            <div className='relative section-lg px-6 lg:px-12 flex' >
                <ProfileDropdown hidden={profileHidden} setHidden={SetMenuHidden} />
                <div className='hidden lg:flex'>
                    <a href='https://www.fansi.me' className='my-auto mr-12'>
                        <IMG src='LogoFull' className='ml-7 h-8' />
                    </a>
                    <MenuBlock logo='MetaBoomH' title='MetaBoom' items={MetaBoom} className='my-auto mr-11' />
                    <MenuBlock logo='RisingStarsH' title='Rising Stars' items={RisingStars} className='my-auto mr-11' />
                    <Link to={WhitePaperUrl} target='_blank' className='mr-11 my-auto h-6'>
                        <p className='Roboto  text-white'>White Paper</p>
                    </Link>
                    <Link to={SupportUrl} target='_blank' className='mr-11 my-auto h-6'>
                        <p className='Roboto  text-white'>Support</p>
                    </Link>
                </div>
                <div className='hidden lg:flex w-80 vertical-center right-0 pr-32'>
                    <a className='my-auto mr-6' href={__('FANSI_Twitter')} target="_blank" rel="noreferrer noopener">
                        <IMG className='w-7 h-7' src='Twitter' />
                    </a>
                    <a className='my-auto mr-6' href={__('DC_INVITE_LINK')} target="_blank" rel="noreferrer noopener">
                        <IMG className='w-7 h-7' src='Discord' />
                    </a>
                    <a className='my-auto mr-6' href={__('FANSI_IG')} target="_blank" rel="noreferrer noopener">
                        <IMG className='w-7 h-7' src='IG' />
                    </a>
                    <a className='my-auto' href={__('FANSI_LinkTree')} target="_blank" rel="noreferrer noopener">
                        <IMG className='w-7 h-7' src='LinkTree' />
                    </a>
                </div>
                <div className='absolute right-0 h-full w-12 lg:w-24 flex justify-end'>
                    {user ? <WalletDropdown user={user} userId={userId} hidden={walletHidden} setHidden={SetMenuHidden} /> :
                        <Link to={'/login?' + qs.stringify({ n: window.location.pathname })}
                            className="h-full w-18 lg:w-24 mr-6 rounded-3xl flex" >
                            <Button className="NotoSans font-bold my-auto text-center w-18 lg:w-32 mr-0 lg:mr-5" appearance="rainbow" >
                                <p className='text-sm lg:text-base font-medium'>Login</p>
                            </Button>
                        </Link>}
                    <div className='hidden lg:block h-16 w-12 flex'><LanguageSelector /></div>
                </div>
            </div>
        </div>
    );
};

const MenuItem = ({ title, url, forward, info, status, clickCall }: MenuItemProps) => {
    const [hover, setHover] = useState(false);

    if (status === 2) return <div className='w-full pl-4 pt-4 lg:p-2 rounded-md mb-1 bg-black cursor-default'>
        <div className='w-full flex mb-1'>
            <p className='Roboto text-xl lg:text-base font-bold text-gray-6d'>{title}</p>
            <div className='ml-3 border-gray-cd rounded-2xl border px-2 h-5 flex'>
                <p className='text-xs text-gray-cd leading-none my-auto'>Coming Soon</p>
            </div>
        </div>
        <p className={'Roboto text-xs  text-gray-cd'}>{info}</p>
    </div>

    function extraCall(e: any) {
        if (clickCall) clickCall(e);
    }

    return (<Link target={forward === 2 ? '_blank' : ''} to={url} >
        <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={(e) => { extraCall(e) }}
            className={'w-full pl-4 pt-4 lg:p-2 rounded-md mb-1 ' + (hover ? 'bg-blue-darkest' : 'bg-black')}>
            <div className='w-full flex mb-1'>
                <p className={'Roboto font-bold text-xl lg:text-base  ' + (hover ? 'text-gen2blue' : 'text-white')}>{title}</p>
                {status === 1 ? <div className='ml-3 border-djPink rounded-2xl border px-2 h-5 flex'>
                    <p className='text-djPink text-xs leading-none my-auto'>New !</p></div> : ''}
            </div>
            <p className={'Roboto text-xs ' + (hover ? 'text-gen2blue' : 'text-gray-cd')}>{info}</p>
        </div>
    </Link >)
}

const MenuBlock = ({ logo, title, items, className }: { logo?: string, title: string, items: MenuItemProps[], className?: string }) => {
    const [showMenu, setMenu] = useState(false);
    let logoCls = 'my-auto object-cover ';
    switch (logo) {
        case 'MetaBoomH':
            logoCls += 'w-20 mr-1';
            break;
        case 'RisingStarsH':
            logoCls += 'w-32 mr-1';
            break;
    }

    let index = 1;

    return (
        <div className={(className ? className : '') + ' flex relative h-8 cursor-pointer ' + (showMenu ? 'text-gen2blue' : 'text-white')}
            onMouseEnter={() => setMenu(true)} onMouseLeave={() => setMenu(false)}>
            {logo ? <IMG src={logo} className={logoCls} /> : <p className='Roboto mr-1 my-auto'>{title}</p>}
            <IMG src={showMenu ? 'ArrowUpB' : 'ArrowDown'} className='w-4 h-4 my-auto' />
            {showMenu ? <div className='absolute left-0 pt-14 w-72 '>
                <div className='w-full rounded-md border border-gen2blue bg-black p-4'>
                    {items.map((item: MenuItemProps) => {
                        return <MenuItem {...item} key={'MenuItem-' + index++} />
                    })}
                </div>
            </div> : ''}
        </div>
    )
}

interface ProfileDropdownProps {
    props?: any;
    user?: any;
    userId?: number;
    hidden: boolean;
    setHidden: any;
}

const WalletDropdown = (props: ProfileDropdownProps) => {
    const { hidden, setHidden } = props;
    const avatar =
        props.user && props.user.avatarUrl
            ? props.user.avatarUrl
            : GetDefaultAvatar(props.userId ? props.userId : 1);
    const walletAddress =
        props.user && props.user.walletAddress
            ? props.user.walletAddress
            : '0x0000000000';
    const toggleWalletEventListner = () => {
        setHidden(true, 'wallet');
        document.body.removeEventListener('click', toggleWalletEventListner);
    };

    const logOut = () => {
        UserAuth.signOut();
        window.location.reload();
    };

    const toggleHidden = (e: MouseEvent) => {
        e.stopPropagation();
        if (hidden) {
            setHidden(false, 'wallet');
        } else {
            setHidden(true, 'wallet');
        }
        if (!hidden) {
            document.body.removeEventListener('click', toggleWalletEventListner); // 正常已經移除了，多移除一次以防萬一
        } else {
            document.body.addEventListener('click', toggleWalletEventListner);
        }
    };

    return (
        <div className='relative flex w-12 my-auto'>
            <div className='flex my-auto items-center cursor-pointer flex mr-2' onClick={(e: any) => { toggleHidden(e) }} >
                <img src={avatar} className='w-8 lg:w-10 h-8 lg:h-10 border-2 rounded-full border-gen2blue' alt='avatar' />
            </div>
            <div className={'mt-12 mr-4 py-6 px-4 rounded-2xl absolute right-0 flex flex-col ' + (hidden ? 'hidden' : '')}
                style={{
                    transform: 'translate(1rem, 1rem)',
                    zIndex: 99,
                    border: 'solid 1px #089dc6',
                    backgroundColor: '#1d1d1d',
                    width: 235,
                }} >
                <div className='w-full flex'>
                    <IMG src='Wallet' className='w-8 h-8 mr-2 my-auto' />
                    <p className='my-auto gen2blue'>
                        {walletAddress
                            ? walletAddress.substring(0, 6) +
                            '...' +
                            walletAddress.substring(walletAddress.length - 6)
                            : ''}{' '}
                    </p>
                </div>
                <div className='w-full hr my-4' style={{ backgroundColor: '#4a4a4a' }} />
                <Link to={`/collection/0`} className='text-white w-full flex'>
                    <IMG src='Member' className='w-8 h-8 mr-2 my-auto' />
                    <p className='text-left w-full NotoSans font-bold'>My Collection</p>
                </Link>
                <Link to={`/collection/0/myFavorites`} className='text-white w-full mt-4 flex'>
                    <IMG src='Favorite' className='w-8 h-8 mr-2 my-auto' />
                    <p className='text-left w-full NotoSans font-bold'>My Favorites</p>
                </Link>
                <Link to={`/collection/0/myFarming`} className='text-white w-full mt-4 flex'>
                    <IMG src='Member' className='w-8 h-8 mr-2 my-auto' />
                    <p className='text-left w-full NotoSans font-bold'>My Farming</p>
                </Link>
                <Link to={`/collection/0/myProfile`} className='text-white w-full mt-4 flex'>
                    <IMG src='Gear' className='w-8 h-8 mr-2 my-auto' />
                    <p className='text-left w-full NotoSans font-bold'>My Profile</p>
                </Link>
                <div className='w-full hr my-4' style={{ backgroundColor: '#4a4a4a' }} />
                <div className='text-white w-full flex cursor-pointer' onClick={logOut}>
                    <IMG src='Logout' className='w-8 h-8 mr-2 my-auto' />
                    <p className='NotoSans font-bold text-center'> Logout </p>
                </div>
            </div>
        </div>
    );
};

export default withAuthState(Header);