import { Operation } from "@apollo/client";
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import path from 'ramda/es/path';
import gql from 'graphql-tag';
import { Cookies } from 'react-cookie';
import qs from 'qs';
import { Personal, Web3 } from 'web3';

import { Button, error, Spinner, } from './components';
import { IMG } from './imgLib';
import UserAuth, { SITE_COOKIE } from './userAuth';
import { convertPassword, IsInAppBrowser } from './utils';
import { useGoogleLogin } from '@react-oauth/google';

export const withAuthState = (Comp: React.ElementType) => {
    class Wrapper extends React.Component {
        state = {
            authenticated: false,
            user: null,
            loading: true,
        };

        constructor(props: Readonly<{}>) {
            super(props);
            UserAuth.currentAuthenticatedUser()
                .then((user) => {
                    this.setState({ authenticated: true, user, loading: false });
                })
                .catch((err) => {
                    this.setState({ loading: false });
                });
        }

        handleAuthEvent = ({ event, data }: { event: string; data?: any; }) => {
            switch (event) {
                case 'signOut':
                    this.setState({
                        authenticated: false,
                        user: null,
                    });
                    break;
                case 'signIn':
                    this.setState({
                        authenticated: true,
                        user: data,
                    });
                    break;
                default:
                    break;
            }
        };

        render() {
            return <Comp {...this.props} {...this.state} />;
        }
    }

    return Wrapper;
};

function withRouter(Component: any) {
    function ComponentWithRouterProp(props: any) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}

const EnsureLoggedIn = withRouter(
    (props: UserAuthenticatorProps) => {
        const LOADING = 'loading';
        const AUTHENTICATED = 'authenticated';
        const AUTH_FAILURE = 'auth_failure';
        const [authStatus, setAuthStatus] = useState(LOADING);

        useEffect(() => {
            let isSignedIn = props.authState === 'signedIn';
            if (authStatus !== AUTHENTICATED && authStatus !== AUTH_FAILURE)
                setAuthStatus(isSignedIn ? AUTHENTICATED : AUTH_FAILURE);
        }, [props.authState, authStatus, setAuthStatus]);

        if (authStatus === AUTH_FAILURE && props.authState === 'signedIn') {
            return <div />
        } else if (authStatus === AUTHENTICATED && props.authState === 'signedIn') {
            return props.children; // We could provide a loading state here
        }
        return null;
    },
);

type AUTH_STATE =
    | 'signIn'
    | 'signUp'
    | 'signedIn'
    | 'signedUp'
    | 'chkThirdParty'
    | 'confirmSignUp'
    | 'confirmSignIn';

interface UserAuthenticatorProps {
    authData?: any;
    children?: any;
    authState?: AUTH_STATE;
    forward?: string;
    url?: string;
}

const THIRD_PARTY_LOGIN = gql`
    query ThirdPartyLogin(
        $email: String!
        $id: String!
        $source: String!
        $displayName: String!
        $avatar: String!
        ) {
        user: thirdPartyLogin(
            email: $email
            id: $id
            source: $source
            displayName: $displayName
            avatar: $avatar
        ) {
            accessToken
            tokenLife
        }
    }
`;

interface ThirdPartyLoginProps {
    email: string;
    id: string;
    source: string;
    displayName: string;
    avatar: string;
    ctlFail: any;
    ctlSuccess: any;
}

const ThirdPartyLoginCheck = ({ ...props }: ThirdPartyLoginProps) => {
    let email = props.email;
    let id = props.id;
    let source = props.source;
    let displayName = props.displayName;
    let avatar = props.avatar;
    const getLoginInfo = useQuery(THIRD_PARTY_LOGIN,
        {
            variables: {
                email: email,
                id: id,
                source: source,
                displayName: displayName,
                avatar: avatar,
            },
        }
    );

    if (getLoginInfo.loading) return <Spinner />;
    if (getLoginInfo.error) {
        console.log(getLoginInfo.error);
        error(getLoginInfo.error)
        props.ctlFail();
        return null;
    }
    if (getLoginInfo.data) { console.log(getLoginInfo.data) }
    if (
        getLoginInfo.data.user !== null &&
        getLoginInfo.data.user.accessToken !== null
    ) {
        const cookies = new Cookies();
        cookies.set(SITE_COOKIE, getLoginInfo.data.user, {
            path: '/',
            maxAge: getLoginInfo.data.user.tokenLife,
        });
        props.ctlSuccess();
        return null;
    } else {
        props.ctlFail();
        return null;
    }
};

export const UserAuthenticator = ({
    children,
    url,
    forward,
    ...props
}: UserAuthenticatorProps) => {
    const GET_USER_PROFILE = gql`
        query GetUserProfile {
            me {
                id
                email
                walletAddress
            }
            accountStatus: getUserAccountStatus
        }
        `;

    const [thirdPartyLogin] = useLazyQuery(THIRD_PARTY_LOGIN, {
        onCompleted: data => {
            if (data) {                
                if (
                    data.user !== null &&
                    data.user.accessToken !== null
                ) {
                    const cookies = new Cookies();
                    cookies.set(SITE_COOKIE, data.user, {
                        path: '/',
                        maxAge: data.user.tokenLife,
                    });
                    window.location.reload();
                } else {
                    window.location.reload();
                }
            }
        },
        onError: (err: any) => {
            error(err.message);
            // window.location.reload();
        },
    })

    const onGoogleSuccess = useGoogleLogin({
        onSuccess: tokenResponse => 
            // console.log(tokenResponse),
            thirdPartyLogin({ variables: { email: 'GHK_GOOGLE_AUTH_TOKEN', id: tokenResponse.access_token, source: 'Google', displayName: '', avatar: 'NA' } }),
        
    });
    const [authState, setAuthState] = useState(props.authState);
    const [username, setUsername] = useState('');
    const [thirdPartyId, setThirdPartyId] = useState('');
    const [avatar, setAvatar] = useState('');
    const [thirdParty, setThirdParty] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [loading, setLoading] = useState(false);
    //const [loginType, setLoginType] = useState(loginConfirm ? loginConfirm : 'thirdParty');
    const [loginFail, setLoginFail] = useState(false);
    const [email, setEmail] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [accountStatus, setAccountStatus] = useState(1);


    let getUserProfile: any;
    getUserProfile = useQuery(GET_USER_PROFILE, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            if (data && data.me) {
                if (data.me.email) setEmail(data.me.email);
                if (data.me.walletAddress) setWalletAddress(data.me.walletAddress);
                setAccountStatus(parseInt(data.accountStatus + ''));
            }
        }
    })
   
    if (getUserProfile.loading) return <div className='absolute-center'><Spinner /></div>;
    const inAppBrowser = IsInAppBrowser();    
    const href = window.location.href.replace('#access_token', 'access_token');
    const currentUrl = new URL(href);
    let UpdAccount = currentUrl.searchParams.get("UpdAccount");
    let access_token = currentUrl.searchParams.get("access_token");
    let info;
    let state: any;
    if (access_token) {
        info = qs.parse(href);
        if (info && info['state']) {
            state = JSON.parse(info['state'] + '');
            if (state && state['UpdAccount']) UpdAccount = state['UpdAccount'];
        }
    }

    
    //檢查是否登入，已經登入就直接render
    if (UserAuth.isAuthenticate()) {
        if (!UpdAccount || UpdAccount !== 'YES' || accountStatus === 3)
            return <EnsureLoggedIn authState='signedIn'>{children}</EnsureLoggedIn>;
    } else if (url && url.indexOf('fund') !== -1) {
        let nextUrl = window.origin + '/login/?n=' + encodeURIComponent(url);
        window.location.href = nextUrl;
    }

    if (access_token && !loading && !loginFail) {
        if (state) {
            let forward = currentUrl.searchParams.get("n");
            if (!forward && state['forwardUrl'] && UpdAccount !== 'YES') {
                window.location.href = currentUrl + '&n=' + state['forwardUrl'];
            }
            if (state['status'] && state['status'] === 'login') {
                let current = Math.round(new Date().getTime() / 1000);
                if (state['chkTime'] && (current - parseInt(state['chkTime']) < 300)) { //only works for 5 minutes
                    if (state['chkCode'] && state['chkCode'] === convertPassword(state['chkTime'] + '')) { //check confirm code
                        setAvatar('NA');
                        setUsername('CHK_ACCESS_TOKEN');
                        setDisplayName('Facebook');
                        setThirdParty('Facebook');
                        setThirdPartyId(access_token);
                        setAuthState('chkThirdParty');
                        setLoading(true);
                    }
                }
            }
        }
    }

    const loginSuccess = () => {
        setAuthState('signedIn');
        props.authState = authState;
        if (state && state['forwardUrl']) {
            window.location.href = window.location.origin + state['forwardUrl'];
        } else {
            window.location.reload();
        }
    };

    const invalidLoginInfo = () => {
        setAuthState('signIn');
        setLoading(false);
        setLoginFail(true);
    };

    // const fbAppId = process.env.NODE_ENV === 'production' ? '1065544601114844' : '1065544601114844';
    // const current = Math.round(new Date().getTime() / 1000);
    // const currFwUrl = currentUrl.searchParams.get("n");
    // const currPath = window.location.pathname;
    // let forwardUrl = (currFwUrl && currFwUrl !== '/') ? currFwUrl : (currPath && currPath !== '/login' ? currPath : '/');
    // const loginState = JSON.stringify({ url, status: 'login', chkTime: current, chkCode: convertPassword(current + ''), forwardUrl, UpdAccount });
    // let fbLoginUrl = 'https://www.facebook.com/v10.0/dialog/oauth?client_id=' + fbAppId + '&scope=email&redirect_uri=' + window.origin + '/login&response_type=token&state=' + loginState;
    // function RetreiveFBToken() {
    //     window.location.href = fbLoginUrl;
    // }

    const logOut = () => {
        UserAuth.signOut();
        window.location.href = window.location.origin;
    };
    return (
        <div className='section-lg bg-black flex ' style={{ minHeight: '100vh' }}>
            <div className={'w-full ' + (UpdAccount && accountStatus < 3 ? '' : 'lg:w-1/2')}>
                {authState !== 'signedIn' ? (
                    <div className='w-full h-full flex' >
                        <div className='w-6/7 lg:w-3/5 m-auto' >
                            <img className='w-64 mx-auto' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/MetaBoom/img/login_side_logo.svg' alt='login_logo' />
                            {UpdAccount ? (accountStatus === 1 && email ? //add wallet
                                <div className='w-full mt-16'>
                                    <IMG className='w-14 h-14 mx-auto' src='Alert' />
                                    <p className='mt-4 text-2xl Roboto italic font-bold w-full text-center'>Email connected !</p>
                                    <p className='text-2xl Roboto italic font-bold w-full text-center'>Final step : link your wallet with your email account</p>
                                </div >
                                : (accountStatus === 2 && walletAddress ? //add email
                                    <div className='w-full mt-16'>
                                        <IMG className='w-14 h-14 mx-auto' src='Alert' />
                                        <p className='mt-4 text-2xl Roboto italic font-bold w-full text-center'>Wallet connected !</p>
                                        <p className='text-2xl Roboto italic font-bold w-full text-center'>Final step : link your email with you wallet</p>
                                    </div > :
                                    <p className='mt-11 text-3xl Roboto italic font-bold w-full text-center'>Welcome to MetaBoom</p>)
                            ) : <p className='mt-11 text-3xl Roboto italic font-bold w-full text-center'>Welcome to MetaBoom</p>}

                            {walletAddress && accountStatus === 2 ?
                                <div className='mt-6 h-10 px-8 w-full flex'>
                                    <div className='mx-auto flex'>
                                        <IMG className='w-6 h-6 mr-2 my-auto' src='MM' />
                                        <p className='my-auto font-medium text-lg NotoSans gen2blue'>
                                            {walletAddress.substring(0, 6) + '...' + walletAddress.substring(walletAddress.length - 6)}
                                        </p>
                                    </div>
                                </div> :
                                (email ? <div className='mt-8 h-10 px-8 w-full flex'>
                                    <div className='mx-auto flex'>
                                        <IMG className='pt-1 w-7 h-7 mr-2 my-auto' src='Mail' />
                                        <p className='my-auto font-medium text-lg NotoSans gen2blue'>
                                            {email}
                                        </p>
                                    </div>
                                </div> : <div className='w-full flex'>
                                    <Button className='mt-6 h-10 w-80 px-8 mx-auto'>
                                        <div className='w-full h-full flex'>
                                            <MMLoginBtn label='Connect MetaMask' className={'my-auto w-full'} />
                                        </div>
                                    </Button>
                                </div>)
                            }
                            {UpdAccount && accountStatus < 3 ?
                                <div className='w-full flex'>
                                    <IMG className='mt-2 w-14 h-14 mx-auto' src='ArrowDown' />
                                </div> :
                                inAppBrowser ? '' : <div className='w-full my-10 flex relative' style={{ height: 1, backgroundColor: '#727272' }}>
                                    <p className='px-1 absolute-center font-medium NotoSans text-sm mx-auto fansi_bgColor'>OR</p>
                                </div>}
                            {email && accountStatus === 1 ?
                                <div className='w-full flex flex-wrap'>
                                    <div className='w-full flex'>
                                        <Button className='mt-4 h-10 w-80 px-8 mx-auto'>
                                            <div className='w-full h-full flex'>
                                                <MMLoginBtn label='Connect MetaMask' className={'my-auto w-full'} />
                                            </div>
                                        </Button>
                                    </div>
                                    <div className='mt-10 flex mx-auto cursor-pointer' onClick={logOut}>
                                        <IMG src='ArrowLeftBlue' className='w-8 h-8 mr-1 my-auto' />
                                        <p className='leading-none NotoSans text-2xl font-medium gen2blue my-auto'>Back</p>
                                    </div>
                                </div>
                                :
                                <>                                    
                                    {!inAppBrowser ?
                                        <>
                                            <div className='w-80 mx-auto flex mt-6'>
                                            <Button onClick={onGoogleSuccess}
                                                        className='px-8 w-full h-10 border border-white flex' style={{ maxWidth: 400 }} >
                                                        <IMG src='Google' className='my-auto mr-4 h-6 w-6 h-6' />
                                                        <p className='my-auto NotoSans text-white '>Login with Google</p>
                                            </Button>
                                                 {/* <GoogleLogin
                                                clientId={clientId}
                                                render={renderProps => (
                                                    <Button onClick={renderProps.onClick} disabled={renderProps.disabled}
                                                        className='px-8 w-full h-10 border border-white flex' style={{ maxWidth: 400 }} >
                                                        <IMG src='Google' className='my-auto mr-4 h-6 w-6 h-6' />
                                                        <p className='my-auto NotoSans text-white '>Login with Google</p>
                                                    </Button>
                                                )}
                                                onSuccess={onGoogleSuccess}
                                                onFailure={onGoogleFailure}
                                                cookiePolicy={'single_host_origin'}
                                                style={{ borderRadius: 6, border: 'solid 1px #5fddff', backgroundColor: 'rgba(95, 221, 255, 0.14)' }}
                                                isSignedIn={false}
                                                /> */}
                                            </div>
                                        </> : ''}
                                    {accountStatus === 2 ?
                                        <div className='w-full flex flex-wrap'>
                                            <Link to='/collection/0/myProfile' className='mt-10 flex mx-auto '>
                                                <IMG src='ArrowLeftBlue' className='w-8 h-8 mr-1 my-auto' />
                                                <p className='leading-none NotoSans text-2xl font-medium gen2blue my-auto'>Back</p>
                                            </Link>
                                        </div>
                                        :
                                        <div className='flex w-full mt-12 mb-6 relative'>
                                            <IMG className='w-4 h-4 vertical-center ' style={{ left: -18 }} src='Alert' />
                                            <p className='pl-2 text-xs text-left NotoSans'>By clicking on login, you agree to the terms Fansi Platform's
                                                <a target='_blank' rel="noreferrer noopener" className='gen2blue no-underline font-bold'
                                                    href={`https://www.fansi.me/tos`}> UserTerms of Service </a> and
                                                <a target='_blank' rel="noreferrer noopener" className='gen2blue no-underline font-bold'
                                                    href={`https://www.fansi.me/privacy`}> Privacy Policy </a>
                                            </p>
                                        </div>

                                    }

                                </>}
                        </div>
                    </div>
                ) : null
                }
                {authState === 'chkThirdParty' ? (
                    <div className='container text-center'>
                        <ThirdPartyLoginCheck
                            ctlFail={invalidLoginInfo}
                            ctlSuccess={loginSuccess}
                            email={username}
                            id={thirdPartyId}
                            source={thirdParty}
                            displayName={displayName}
                            avatar={avatar}
                        />
                    </div>
                ) : (
                    ''
                )}
            </div>
            <div className={'hidden ' + (UpdAccount && accountStatus < 3 ? '' : 'lg:block w-1/2')}
                style={{ backgroundImage: `url(https://fansi-static.s3.ap-southeast-1.amazonaws.com/MetaBoom/img/login_side_bg.png)` }}>
                <div className='w-full h-full flex'>
                    <div className='w-full my-auto '>
                        <img className='mx-auto' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/MetaBoom/img/login_side_logo.svg' alt='MB_logo' />
                        <p className='w-1/2 mt-8 mx-auto Roboto text-3xl text-center whitespace-pre'>
                            {'Listen to the\n future of Music NFTs\n curated by the community.'}</p>
                    </div>
                </div>
            </div>
            <EnsureLoggedIn authState={authState}>{children}</EnsureLoggedIn>
        </div >
    );
};

export const withAuthentication = (Comp: React.ComponentType<{}>) => {
    return (props: object) => {
        const url: string = path(['match', 'url'], props) || '';
        return (
            <UserAuthenticator url={url}>
                <Comp {...props} />
            </UserAuthenticator>
        );
    };
};

export const makeAuthenticatedRequest = (operation: Operation) => {
    return (
        UserAuth.currentAuthenticatedUser()
            .then((user) => {
                const session = user.signInUserSession;
                const token = session.getAccessToken();
                operation.setContext({
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                });
            })
            .catch((err) => {
                console.warn('[INFO]:01 User is not signed in. Will fetch without authentication.' + err.message);
            })
    );
};

const parseQuerystring = (x: string) => {
    x = x.replace(/^\?/, ''); // strip leading question mark
    return qs.parse(x);
};

const RedirectAfterLogin = () => {
    console.log('FORWARD!!');
    useEffect(() => {
        let nextUrl = '/';
        if (window.location.search) {
            const { n } = parseQuerystring(window.location.search);
            if (typeof n === 'string') nextUrl = n;
        }
        window.location.href = nextUrl;
    });

    return (
        <div className='container section'>
            <Spinner />
        </div>
    );
};

const GET_LOGIN_NONCE = gql`
query getLoginNonce($walletAddress: String!) {
    nonce: getLoginNonce(walletAddress: $walletAddress)
}
`;

export async function InitialWeb3(setStatus?: any) {
    if (!window.web3api || !window.web3api.eth || !window.ethereum) {
        try {
            if (window.ethereum) {
                window.web3api = new Web3(window.ethereum);
                window.personal = new Personal(window.ethereum);
                if (setStatus) setStatus(1); //start calling MM
                const accounts = await window.ethereum.send('eth_requestAccounts');
                if (accounts['result'].length > 0) {
                    if (setStatus) setStatus(2); //MM enable
                    return true;
                } else {
                    return false;
                }
            } else {
                window.alert('Please install MetaMask and use desktop browser to proceed');
                return false;
            }
        } catch (error) {
            if (setStatus) setStatus(1); //start calling MM
            console.warn(error);
            // window.alert('Please confirm your Metamask plugin is login and connected.');
            return false;
        }
    }
    return true;
}

export const MMLoginBtn = ({ className, labelClass, size, label, hideIcon }: { className?: string, size?: string, label?: string, hideIcon?: boolean, labelClass?: string }) => {
    const prefix = 'Login MetaBoom with Metamask. Nonce:';
    const [MMLogin, setMMLogin] = useState(false);
    const [wallet, setWallet] = useState('');
    const [thirdPartyLogin] = useLazyQuery(THIRD_PARTY_LOGIN, {
        onCompleted: data => {
            if (data) {
                if (
                    data.user !== null &&
                    data.user.accessToken !== null
                ) {
                    const cookies = new Cookies();
                    cookies.set(SITE_COOKIE, data.user, {
                        path: '/',
                        maxAge: data.user.tokenLife,
                    });
                    window.location.reload();
                } else {
                    window.location.reload();
                }
            }
        },
        onError: (err: any) => {
            error(err.message);
            // window.location.reload();
        },
    })

    const [getNonce, { loading: loadingNonce }] = useLazyQuery(GET_LOGIN_NONCE, {
        onCompleted: data => {
            if (data && data.nonce) {
                MMLoginConfirm(data.nonce);
            } else {
                alert('LOGIN ERROR!!')
                window.location.reload();
            }
        },
        onError: (err: any) => {
            console.warn(err.message);
            setMMLogin(false);
        },
    })

    const MMLoginClick = async () => {
        if (loadingNonce)
            return;

        setMMLogin(true);
        // Check if MetaMask is installed
        if (!(window as any).ethereum) {
            window.alert('Please install MetaMask first.');
            setMMLogin(false);
            return;
        }

        if (!window.web3api || !window.web3api.eth)
            if (!await InitialWeb3())
                window.alert('Please confirm your Metamask plugin is login and ready.');

        if (!window.web3api || !window.web3api.eth) {//fail retrieve
            setMMLogin(false);
            return;
        }

        const account = await window.web3api.eth.getAccounts();
        if (account && account[0]) {
            let walletAddress = account[0].toLowerCase();
            setWallet(walletAddress);
            getNonce({ variables: { walletAddress } });
        } else {
            window.alert('Please activate MetaMask first.');
            setMMLogin(false);
            return;
        }
    };

    async function MMLoginConfirm(nonce: string) {
        if (!window.web3api || !window.web3api.eth) //retrieve web3 if undefined
            if (!await InitialWeb3())
                window.alert('Please confirm your Metamask plugin is login and ready.');

        if (!window.web3api || !window.web3api.eth) {//fail retrieve
            setMMLogin(false);
            return;
        }

        const signIn = await CallMMSign({ wallet, nonce });
        if (signIn?.wallet && signIn?.signature) {
            thirdPartyLogin({ variables: { email: signIn?.wallet, id: signIn?.wallet, source: 'Metamask', displayName: signIn?.signature, avatar: 'NA' } });

        } else {
            alert('Invalid info, you need to sign the message to be able to log in.');
            window.location.reload();
        }
    }

    const CallMMSign = async ({ wallet, nonce, }: { wallet: string; nonce: string; }) => {
        try {
            const signature = await window.personal!.sign(
                prefix + nonce,
                wallet,
                '' // MetaMask will ignore the password argument here
            );
            return { wallet, signature };
        } catch (err) {
            console.log(err);
            setMMLogin(false);
            alert('You need to sign the message to be able to log in.');
        }
    };

    return (
        <div className={'flex cursor-pointer ' + (className ? className : '')} onClick={MMLoginClick}>
            {MMLogin ? <div className='w-full flex'> <div className='m-auto'><Spinner /></div></div> :
                <div className='w-full flex '>
                    {hideIcon ? '' : <IMG className='w-6 h-6 mr-2 my-auto' src='MM' />}
                    <p className={labelClass ? labelClass : ' my-auto NotoSans font-medium w-48'} >
                        {label ? label : 'Login with Metamask'}</p>
                </div>}
        </div>
    )
}

export const LogInPage = () => {
    let params = window.location.href.split('n=');
    let forwardUrl = params.length > 1 ? params[1] : '';
    return (
        <UserAuthenticator authState='signIn' forward={forwardUrl ? forwardUrl : undefined}>
            <RedirectAfterLogin />
        </UserAuthenticator>
    );
};

