import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, from, fromPromise, toPromise } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { makeAuthenticatedRequest } from './auth';

const authLink = new ApolloLink((operation, forward) => {
    return fromPromise(
        makeAuthenticatedRequest(operation)
            .then(() => {
                return toPromise(forward(operation));
            })
    );
});

const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
        );

    if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const client = new ApolloClient({
    link: from([errorLink, authLink.concat(httpLink)]),
    cache: new InMemoryCache()
});


