import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { MBCard, MBCardLg } from '../collections/Collection';
import { ScrollToTopOnce, Loading, GetDefaultAvatar, GetDefaultCover } from '../../lib/components';
import { SongProps, MBBlockProps, GetListenHistory } from '../../lib/utils';
import { IMG } from '../../lib/imgLib';
import "../css/explore_page.css";

function convertScore(score: number) {
    if (score > 10000000)
        return Math.floor(score / 1000000) + 'M';
    if (score > 10000)
        return Math.floor(score / 1000) + 'K';
    return score + '';
}


export const TabMenu = ({ currType, menuType, text, callBack, listType }: { currType: number, menuType: number, text: string, callBack: any, listType?: number }) => {
    return (
        <div onClick={() => { if (currType !== menuType) callBack(menuType) }}
            className={(menuType !== currType ? 'cursor-pointer' : '') + ' pr-2 lg:pr-5 relative'} style={{ minWidth: 110 }}>
            <p className={(menuType === currType ? 'gen2blue' : '') + ' w-full text-center font-bold Roboto my-auto whitespace-nowrap' + (listType && listType === 1 ? ' text-base':' text-xs')} >{text}</p>
            <div className={(menuType === currType ? '' : 'opacity-0') + ' hr w-full'} style={{borderWidth:2, borderColor:'var(--gen2blue)'}}/>
        </div>
    )
}

const TopMBBlock = ({ tokenId, name, displayName, boomImg, songList }: MBBlockProps) => {
    const [hover, setHover] = useState(false);
    let tracks = songList ? songList.length : 0;
    if (songList && tracks > 3) songList = songList.slice(0, 3)
    return (
        <Link to={'/metaboom/' + tokenId} className={'relative pt-4 lg:pt-7'}>
            <div className='relative text-white w-64 lg:w-84 ' style={{ minWidth: 255 }} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <img className='absolute top-0 left-0 w-22 lg:w-28 h-22 lg:h-28 ml-2 lg:ml-4 rounded' src={boomImg} alt='MetaBoom' />
                <div className='bgNFT w-full rounded pb-4 boderNFT border border-black' style={{ minHeight: 200 }}>
                    <div className='w-full flex'>
                        <div className='w-3/7' />
                        <div className='w-4/7 pr-8 lg:pr-4'>
                            <p className='mt-4 Roboto font-bold truncate'>{name}</p>
                            <p className='Roboto text-sm text-gray-660 truncate' >{displayName}</p>
                        </div>
                    </div>
                    <div className='hr mt-7' style={{ backgroundColor: 'rgba(95, 221, 255, 0.14)' }} />
                    <div className='w-full h-56 px-4'>
                        {songList ? songList.map((song: SongProps) => {
                            return (
                                <div className='w-full flex mt-6 pr-3' key={'song-' + song.title}>
                                    <img src={song.img ? song.img : GetDefaultCover(song.id)} className='w-12 h-12 rounded mr-3' alt='cover' />
                                    <p className='Roboto my-auto font-xs lg:font-base truncate'>{song.title}</p>
                                </div>
                            )
                        }) : ''}
                    </div>
                    <p className='Roboto text-sm text-gray-660 ml-4' >{tracks + ' tracks'}</p>
                    <div className={'absolute right-0 bottom-0 h-10 w-10 rounded-full m-4 lg:m-5 '}>
                        <IMG className='absolute-center w-8 h-8' src={hover ? 'RoundPlayBlue' : 'RoundPlayGray'} />
                    </div>
                </div>
            </div>
        </Link>
    )
}

const DJBlock = ({ id, avatarUrl, displayName, monthlyScore }: { id: number, avatarUrl: string, displayName: string, monthlyScore: number }) => {
    if (!displayName) displayName = 'DJ';
    return (
        <>
            <Link to={'/collection/' + id} className='w-20 lg:w-full p-0 lg:py-2 lg:px-4 bg-black hover:bg-blue-darkest rounded block lg:flex relative mr-9 lg:mr-0' style={{ minWidth: 80 }}>
                <img className='w-18 lg:w-11 h-18 lg:h-11 border border-white rounded-full mr-1 lg:mr-6' src={avatarUrl ? avatarUrl : GetDefaultAvatar(id)} alt='avatar' />
                <IMG className='hidden lg:blockvertical-center right-0 ' src='ArrowRight' />
                <p className='text-white mt-1 lg:my-auto Roboto font-bold text-base lg:text-xl text-center lg:text-left w-full lg:w-1/2 truncate'>{displayName}</p>
                <div className='relative lg:vertical-center h-auto lg:h-full right-0 w-full lg:w-1/4 flex '>
                    <IMG className='w-6 h-6 my-auto mr-2' src='TrophyPink' />
                    <p className='djPink Roboto text-lg truncate w-16 my-auto'>{convertScore(monthlyScore)}</p>
                </div>
            </Link>
            <div className='hidden lg:block my-2 w-full mx-2 bg-white hr' style={{ backgroundColor: 'rgba(95, 221, 255, 0.14)' }} />
        </>
    )
}

const RankInfoBlock = ({ rank, name, displayName, media, score, tokenId, type }:
    { rank: number, name?: string, displayName?: string, media?: string, score: number, tokenId: number, type: number }) => {
    let avatar = media ? media : (type > 2 ? GetDefaultAvatar(tokenId) : GetDefaultCover(tokenId));

    let units = '';
    let counts = '';
    switch (type) {
        case 1: //listen time
            counts = convertScore(score);
            units = 'Min';
            break;
        case 2: //share score
            counts = convertScore(score);
            units = 'Times';
            break;
        case 3:
            counts = score + '';
            units = 'MetaBooms';
            break;
        case 4:
            counts = score + '';
            units = 'Tracks';
            break;
    }

    return (
        <>
            <div className='hr w-full my-4' style={{ backgroundColor: 'rgba(95, 221, 255, 0.14)' }} />
            <Link to={(type <= 2 ? '/metaboom/' : '/collection/') + tokenId}
                className='w-full flex rounded p-0 lg:px-2 hover:bg-darkBlueHL text-white h-18'>
                <div className='w-48 lg:w-3/5 flex'>
                    <p className='my-auto RussoOne text-2xl lg:text-3xl mr-2 lg:mr-4'>{rank}</p>
                    <img className={(type > 2 ? 'rounded-full border border-white w-14 lg:w-12 h-14 lg:h-12 ml-2 mr-2 lg:mr-7' : 'w-14 lg:w-16 h-14 lg:h-16 mr-2 lg:mr-5') + ' my-auto'} src={avatar} alt='avatar' />
                    <div className='my-auto w-26 lg:w-1/2'>
                        <p className='text-sm Roboto truncate'>{type <= 2 ? name : displayName}</p>
                        {type <= 2 ? <p className='text-xs text-gray-660 Roboto truncate'>{displayName}</p> : ''}
                    </div>
                </div>
                <div className='w-28 lg:w-2/5 justify-end flex my-auto'>
                    <div className={type === 3 ? 'w-16 mr-4 lg:mr-6' : 'w-12 mr-4 lg:mr-10'}>
                        <p className='w-full text-center gen2blue Roboto font-bold'>{counts}</p>
                        <p className='w-full text-center text-gray-660 Roboto text-sm'>{units}</p>
                    </div>
                    <IMG className='w-7 h-7 my-auto' src={type <= 2 ? 'RoundPlayGray' : 'ArrowRightBlue'} />
                </div>
            </Link>
        </>
    )
}

const RankBlock = () => {
    const [rankType, setRankType] = useState(1);
    const [leaderBoard, setLeaderBoard] = useState<any[]>([]);
    const GET_LEADERBOARD = gql`
        query GetDJLeaderBoard($type:Int!) {
            leaderboard: getDJLeaderBoard(type:$type)
        }
        `;
    const [getLeaderBoard, { loading }] = useLazyQuery(GET_LEADERBOARD, {
        onCompleted: (data) => {
            if (data.leaderboard)
                setLeaderBoard(JSON.parse(data.leaderboard));
        },
    })

    useEffect(() => {
        getLeaderBoard({ variables: { type: rankType } })
    }, [rankType, getLeaderBoard])

    function SwitchRankType(newType: number) {
        setRankType(newType);
    }

    let index = 1;
    return (
        <div className='w-full '>

            <p className='RussoOne MetaBoom text-3xl font-bold'>Leaderboard</p>

            <div className="flex mt-7 overflow-x-auto hideSBar">
                <TabMenu menuType={1} currType={rankType} text='Rabbit Hole' callBack={SwitchRankType} />
                <TabMenu menuType={2} currType={rankType} text='Most Shared' callBack={SwitchRankType} />
                <TabMenu menuType={3} currType={rankType} text='MetaBoom Collector' callBack={SwitchRankType} />
                <TabMenu menuType={4} currType={rankType} text='Song Collector' callBack={SwitchRankType} />
            </div>
            {loading ? <Loading /> :
                <div className='bg-blue-darkest rounded mt-7 py-4 px-5' >
                    <div className='w-full flex mb-4'>
                        <IMG src='Award' className='w-8 h-8 mr-2' />
                        <p className='RussoOne text-xl djPink my-auto'>Top 5</p>
                    </div>
                    {leaderBoard.map((info: any) => {
                        return <RankInfoBlock {...info} rank={index++} type={rankType} key={'leaderboard-' + index} />
                    })}
                </div>
            }
        </div>
    )
}

const ExplorePage = () => {

    const mbHistory: MBBlockProps[] = GetListenHistory();
    const GET_EVENT_INFO = gql`
        query GetL2eEventInfo {
            eventInfo: getL2eEventInfo
        }
        `;
    const { loading, data } = useQuery(GET_EVENT_INFO);

    if (loading) return <Loading />;
    if (!data || !data.eventInfo)
        return <div className='section-lg fansi_bgColor pb-16'>
            <div className='section relative p-6 lg:px-3'>
                <p className='p-6 text-center font-bold text-2xl'>Server Error, try again later</p>
            </div>
        </div>;

    const eventInfo = JSON.parse(data.eventInfo);
    const topMBs = eventInfo.topMbList;
    const topDJs = eventInfo.DJs;
    const FreshList = eventInfo.mbList.slice(0, 8);

    function CustomRightArrow({ onClick }: any) {
        function handleClick() {
            onClick();
        }

        return (
            <div className='absolute right-0 top-32 cursor-pointer' onClick={handleClick}>
                <IMG className="w-8 h-8 lg:w-12 h-12" src='RoundCArrowRight' />
            </div>
        );
    }

    function CustomLeftArrow({ onClick }: any) {
        function handleClick() {
            onClick();
        }

        return (
            <div className="absolute top-32 cursor-pointer " onClick={handleClick}>
                <IMG className="w-8 h-8 lg:w-12 h-12" src='RoundCArrowLeft' />
            </div>
        );
    }

    let mbKey = 1;

    return (
        <div className='section-lg relative' style={{ minHeight: '80vh' }}>
            <ScrollToTopOnce />
            <img className='absolute w-full top-0 left-0' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/MetaBoom/img/ExploreSection/ExploreBanner.jpg' alt='abanner'
                style={{ margin: '0 0 205px', padding: '0 0 152px', objectFit: 'contain', opacity: 0.6, backgroundImage: 'linear-gradient(to bottom, #000 0%, rgba(0, 0, 0, 0) 13%, rgba(0, 0, 0, 0) 83%, #000 100%), linear-gradient(to bottom, #000, #000)' }} />
            <div className='w-full relative'>
                <div className='section-md px-5 py-18'>
                    <>
                        <p className='RussoOne MetaBoom text-3xl font-bold'>Explore</p>
                        <div className='w-full flex mt-4 justify-between overflow-x-auto hideSBar'>
                            <Link to='/explore/recommend/0'>
                                <div className='w-64 mr-4'>
                                    <img className='rounded-lg w-full' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/MetaBoom/img/ExploreSection/explore-web3-music.png' alt='music' />
                                </div>
                            </Link>
                            <Link to='/explore/recommend/1'>
                                <div className='w-64 mr-4'>
                                    <img className='rounded-lg w-full' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/MetaBoom/img/ExploreSection/explore-c-pop.png' alt='pop' />
                                </div>
                            </Link>
                            <Link to='/explore/recommend/2'>
                                <div className='w-64 mr-4'>
                                    <img className='rounded-lg w-full' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/MetaBoom/img/ExploreSection/explore-relaxing.png' alt='relax' />
                                </div>
                            </Link>
                            <Link to='/explore/recommend/3'>
                                <div className='w-64 mr-4'>
                                    <img className='rounded-lg w-full' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/MetaBoom/img/ExploreSection/explore-dj-drop.png' alt='dj' />
                                </div>
                            </Link>
                        </div>
                    </>
                    {mbHistory.length > 5 ? <>
                        <p className='RussoOne MetaBoom text-3xl font-bold mt-14'>Listen again</p>
                        <div className='w-full flex mt-4 justify-start lg:justify-between overflow-x-auto hideSBar' >
                            {mbHistory.map((mb: MBBlockProps) => {
                                return (
                                    <div className='pr-1 mr-4' key={'History-' + mbKey++} style={{ minWidth: 154, width: 150 }}>
                                        <MBCard {...mb} />
                                    </div>
                                )
                            })}
                        </div>
                    </> : ''}
                    <>
                        <div className='w-full relative'>
                            <p className='RussoOne MetaBoom text-3xl font-bold mt-14 my-auto'>Top MetaBoom</p>
                            <Link to='/explore/rank/0' className='flex vertical-center right-0'>
                                <p className='gen2blue'>View All</p>
                                <IMG src='ArrowRightBlue' className='w-4 h-4 my-auto' />
                            </Link>
                        </div>
                        <div className='flex lg:hidden w-full mt-4 justify-between overflow-x-auto hideSBar'>
                            {topMBs.map((mb: MBBlockProps) => {
                                return (
                                    <div key={'TMB-' + mbKey++} className='w-full pr-6'>
                                        <TopMBBlock {...mb} />
                                    </div>
                                )
                            })}
                        </div>
                        <div className='hidden lg:block w-full mt-4'>
                            <Carousel
                                responsive={{
                                    default: {
                                        breakpoint: { max: 3840, min: 0 },
                                        items: 3,
                                        slidesToSlide: 1,
                                        partialVisibilityGutter: 0,
                                    }
                                }}
                                draggable={true}
                                autoPlay={false}
                                arrows={true}
                                partialVisible
                                customLeftArrow={<CustomLeftArrow />}
                                customRightArrow={<CustomRightArrow />}
                            >
                                {topMBs.map((mb: MBBlockProps) => {
                                    return (
                                        <div key={'TMB-' + mbKey++} className='w-full px-3'>
                                            <TopMBBlock {...mb} />
                                        </div>
                                    )
                                })}
                            </Carousel>
                        </div>
                    </>
                    <>
                        <div className='w-full relative'>
                            <p className='RussoOne MetaBoom text-3xl font-bold mt-14 my-auto'>Fresh Playlist</p>
                            <Link to='/explore/rank/1' className='flex vertical-center right-0'>
                                <p className='gen2blue'>View All</p>
                                <IMG src='ArrowRightBlue' className='w-4 h-4 my-auto' />
                            </Link>
                        </div>
                        <div className='flex lg:hidden w-full mt-4 justify-between overflow-x-auto hideSBar'>
                            {FreshList.map((mb: MBBlockProps) => {
                                return (
                                    <div className='pr-1 mr-4' key={'FL-' + mbKey++} style={{ minWidth: 154, width: 150 }}>
                                        <MBCard {...mb} />
                                    </div>
                                )
                            })}
                        </div>
                        <div className='hidden lg:block w-full mt-4'>
                            <Carousel
                                responsive={{
                                    default: {
                                        breakpoint: { max: 3840, min: 0 },
                                        items: 4,
                                        slidesToSlide: 1,
                                        partialVisibilityGutter: 0,
                                    }
                                }}
                                draggable={true}
                                autoPlay={false}
                                arrows={true}
                                customLeftArrow={<CustomLeftArrow />}
                                customRightArrow={<CustomRightArrow />}
                            >
                                {FreshList.map((mb: MBBlockProps) => {
                                    return (
                                        <div key={'MB-' + mbKey++} className='w-full px-3'>
                                            <MBCardLg {...mb} />
                                        </div>
                                    )
                                })}
                            </Carousel>
                        </div>
                    </>
                    <div className='w-full flex flex-wrap mt-14 relative'>
                        <div className='w-full lg:w-1/2 pr-0 lg:pr-5'>
                            <p className='RussoOne MetaBoom text-3xl font-bold mb-6'>Top MetaDJ</p>
                            <div className='w-full flex lg:block justify-between overflow-x-auto hideSBar'>
                                {topDJs.map((dj: any) => {
                                    return (
                                        <DJBlock {...dj} key={'DJ-' + mbKey++} />
                                    )
                                })
                                }
                            </div>
                        </div>
                        <div className='w-full lg:w-1/2 pl-0 lg:pl-5 mt-16 lg:mt-0'>
                            <RankBlock />
                        </div>
                        <div className='relative lg:absolute bottom-0 left-0 w-full lg:w-1/2 flex mt-16 lg:mt-0' >
                            <img className='h-44 lg:h-36 mr-0 lg:mr-6' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/MetaBoom/img/ExploreSection/mobile-phone.png' alt='phone' />
                            <div className=''>
                                <p className='gen2blue Roboto text-lg lg:text-4xl font-bold'>Listen on the Go !</p>
                                <div className='w-full my-auto lg:mt-3 block lg:flex'>
                                    <a href="https://play.google.com/store/apps/details?id=me.fansi.app"
                                        target="_blank" rel="noreferrer noopener" >
                                        <IMG src='GooglePlay' className="w-36 mr-0 lg:mr-6 mt-3 lg:mt-0" />
                                    </a>
                                    <a href="https://apps.apple.com/tw/app/metaboom/id6444411102"
                                        target="_blank" rel="noreferrer noopener" >
                                        <IMG src='AppleStore' className="w-36 mt-3 lg:mt-0" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export const TopMBRankBlock = () => {
    const GET_EVENT_INFO = gql`
        query GetL2eEventInfo {
            eventInfo: getL2eEventInfo
        }
        `;
    const { loading, data } = useQuery(GET_EVENT_INFO);

    if (loading) return <Loading />;
    if (!data || !data.eventInfo)
        return null;

    const eventInfo = JSON.parse(data.eventInfo);
    const topMBs = eventInfo.topMbList;
    function CustomRightArrow({ onClick }: any) {
        function handleClick() {
            onClick();
        }

        return (
            <div className='absolute right-0 top-32 cursor-pointer' onClick={handleClick}>
                <IMG className="w-8 h-8 lg:w-12 h-12" src='RoundCArrowRight' />
            </div>
        );
    }

    function CustomLeftArrow({ onClick }: any) {
        function handleClick() {
            onClick();
        }

        return (
            <div className="absolute top-32 cursor-pointer " onClick={handleClick}>
                <IMG className="w-8 h-8 lg:w-12 h-12" src='RoundCArrowLeft' />
            </div>
        );
    }
    let mbKey = 1;

    return (
        <>
            <div className='w-full relative'>
                <p className='RussoOne MetaBoom text-3xl font-bold mt-6 my-auto px-4'>Top MetaBoom</p>
                <Link to='/explore/rank/0' className='flex vertical-center right-0'>
                    <p className='text-gen2blue leading-loose'>Listen More</p>
                    <IMG src='ArrowRightBlue' className='w-4 h-4 my-auto  mr-4' />
                </Link>
            </div>
            <div className='flex lg:hidden w-full px-4 mt-4 justify-between overflow-x-auto hideSBar'>
                {topMBs.map((mb: MBBlockProps) => {
                    return (
                        <div key={'TMB-' + mbKey++} className='w-full pr-6'>
                            <TopMBBlock {...mb} />
                        </div>
                    )
                })}
            </div>
            <div className='hidden lg:block w-full mt-4'>
                <Carousel
                    responsive={{
                        default: {
                            breakpoint: { max: 3840, min: 0 },
                            items: 3,
                            slidesToSlide: 1,
                            partialVisibilityGutter: 0,
                        }
                    }}
                    draggable={true}
                    autoPlay={false}
                    arrows={true}
                    partialVisible
                    customLeftArrow={<CustomLeftArrow />}
                    customRightArrow={<CustomRightArrow />}
                >
                    {topMBs.map((mb: MBBlockProps) => {
                        return (
                            <div key={'TMB-' + mbKey++} className='w-full px-3'>
                                <TopMBBlock {...mb} />
                            </div>
                        )
                    })}
                </Carousel>
            </div>
        </>
    )
}

export default ExplorePage;