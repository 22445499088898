import { useEffect } from 'react';
import { Md5 } from 'ts-md5';
import { encode } from 'querystring';
import { saveAddress, storeReferrer, logVisit } from '@sharemint/sdk';

interface ShareProps {
  url: string;
  text: string;
}

export interface SongProps {
  id: number;
  img: string;
  title: string;
}

export interface attributesProps {
  trait_type: string;
  value: string;
}

export interface MBBlockProps {
  id: number;
  tokenId: number;
  djId?: number;
  owner?: boolean;
  name: string;
  displayName: string;
  boomImg: string;
  image?: string;
  tracks?: number;
  avatarUrl?: string;
  songList?: SongProps[];
  attributes?: attributesProps[];
}

export const trackTagInfo = ['CHILL', 'FOCUS', 'PARTY', 'WEB3', 'SYNTH', 'MAD', 'POP', 'LOUNGE'];

export function email_validate(email: string) {
  var re = {
    email: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  };

  if (!re.email.test(email)) {
    return false;
  } else {
    return true;
  }
}

export const convertPassword = (password: string): string => {
  return Md5.hashStr('FANSI_' + password + '_USER_!!!').toString();
};

export function IsInAppBrowser() {
  let inAppBrowser = false;
  var ua = navigator.userAgent || navigator.vendor;
  if (ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1 || ua.indexOf('Instagram') > -1 || ua.indexOf('Line') > -1 || ua.indexOf('Meta') > -1) inAppBrowser = true;
  return inAppBrowser;
}

export const fbShareUrl = ({ url, text }: ShareProps) => {
  const query = encode({
    display: 'popup',
    u: url,
    // href: url,
    quote: text,
  });
  return 'https://www.facebook.com/sharer/sharer.php?' + query;
};

export const tweetUrl = ({ url, text }: ShareProps) => {
  const query = encode({ url, text });
  return 'https://twitter.com/intent/tweet?' + query;
};

export function AddListenHistory(mb: MBBlockProps) {
  let userMBHistory = localStorage.getItem('userMBHistory');
  let info: MBBlockProps[] = [];
  if (userMBHistory) info = JSON.parse(userMBHistory);
  //check is new mb exist in list
  for (let i = 0; i < info.length; i++) {
    if (info[i].id === mb.id) info.splice(i, 1);
  }
  let newMB: MBBlockProps;
  newMB = {
    id: mb.id,
    tokenId: mb.id,
    name: mb.name + '',
    displayName: mb.displayName + '',
    boomImg: mb.boomImg + '',
    tracks: mb.songList?.length,
    avatarUrl: mb.avatarUrl + '',
    songList: mb.songList,
  };
  info.unshift(newMB);
  let newList = info.slice(0, 6);
  localStorage.setItem('userMBHistory', JSON.stringify(newList));
}

export function GetListenHistory(): MBBlockProps[] {
  let userMBHistory = localStorage.getItem('userMBHistory');
  if (userMBHistory) return JSON.parse(userMBHistory);
  return [];
}

//sharemint
export function sharemintSaveAddressMint(slug: string, transactionHash: string) {
  saveAddress({ slug: slug, transactionHash: transactionHash });
  return true;
}

export function sharemintSaveAddress(slug: string, address: string) {
  saveAddress({ slug: slug, address: address });
  return true;
}

export function sharemintStoreReferrer() {
  storeReferrer();
  return true;
}

export function sharemintLogVisit(slug: string) {
  logVisit({ slug: slug });
  return true;
}

export function ConvertNumber(number: number) {
  let converted = number + '';
  let dots = Math.floor(converted.length / 3);
  let output = '';
  for (let i = dots; i > 0; i--) {
    let divider = Math.pow(1000, i);
    let curr = Math.floor(number / divider);
    if (curr > 0 || i < dots) {
      number -= curr * divider;
      let currSec = curr + '';
      if (i < dots) {
        if (currSec.length < 2) currSec = '00' + currSec;
        if (currSec.length < 3) currSec = '0' + currSec;
      }
      output += currSec + ',';
    }
  }
  let currSec = number + '';
  if (dots > 0) {
    if (currSec.length < 2) currSec = '00' + currSec;
    if (currSec.length < 3) currSec = '0' + currSec;
  }
  output += currSec;
  return output;
}

interface CDTimerProps {
  targetTime: number;
  setDay: React.Dispatch<React.SetStateAction<string>>;
  setHour: React.Dispatch<React.SetStateAction<string>>;
  setMin: React.Dispatch<React.SetStateAction<string>>;
  setSec: React.Dispatch<React.SetStateAction<string>>;
  hideDay?: boolean;
}

export const CountDownTimer = ({ targetTime, setDay, setHour, setMin, setSec, hideDay }: CDTimerProps) => {
  useEffect(() => {
    function covertTime(time: number) {
      return time < 10 ? '0' + time : '' + time;
    }
    const CD = setInterval(() => {
      let curr = Math.round(new Date().getTime() / 1000);
      let remainTime = targetTime - curr;
      if (remainTime < 0) remainTime = 0;
      let days = Math.floor(remainTime / 86400);
      let hours = Math.floor((remainTime % 86400) / 3600);
      let mins = Math.floor((remainTime % 3600) / 60);
      let secs = remainTime % 60;
      if (!hideDay) setDay(covertTime(days));
      setHour(covertTime(hours + (hideDay ? days * 24 : 0)));
      setMin(covertTime(mins));
      setSec(covertTime(secs));
      if (remainTime < 1) window.location.reload();
    }, 1000);
    return () => clearInterval(CD);
  });
  return null;
};
