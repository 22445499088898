import { createRef, useEffect, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import { stringify } from 'querystring';
import gql from 'graphql-tag';
import { useState } from 'react';
import { ScoreProps } from './collections/Collection';
import { IMG } from '../lib/imgLib';
import {
    Button,
    Spinner,
    Loading,
    PopupMsgBlock,
    GetDefaultAvatar,
    GetDefaultCover,
    ScrollToTopOnce,
} from '../lib/components'
import Popup from 'reactjs-popup';
import { MONTHLY_INHERIT_CAP } from './collections/Collection';
import './css/tracker_style.css';
import { AddListenHistory, trackTagInfo } from '../lib/utils';
import { Helmet } from 'react-helmet-async';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import MBBackground from './img/MB-Background.jpg';
import { TabMenu } from './DjScore/Explore';


export interface songProps {
    id?: string;
    img: string;
    media: string;
    title: string;
    artist?: string;
    type: number;
    length: number;
    video?: string;
    contract?: string;
    tokenId?: string;
    chainId?: number;
}

export interface AlbumProps {
    id: number;
    owner: string;
    cover: string;
    songs: songProps[];
}

export function ConvertTime(total: number, stringify?: boolean) {
    let minute = isNaN(total) ? '--' : Math.floor(total / 60);
    let second = isNaN(total) ? '--' : Math.round(total % 60);
    if (minute === '--') {
        return [minute, second];
    }
    if (stringify)
        return minute + ':' + (second > 9 ? second + '' : '0' + second);
    return [minute + '', second > 9 ? second + '' : '0' + second];
}

const GET_EVENT_INFO = gql`
  query GetL2eEventInfo {
    DjBoard: getL2eEventInfo
  }
`;

export const MiniPlayer = () => {
    const GET_BOOM_INFO = gql`
        query GetMetaBoomInfo($tokenId: Int!) {
            boomInfo: getMetaBoomInfo(tokenId: $tokenId) {
            ownerId
            tokenId
            series
            displayName
            avatarUrl
            boomImg
            background
            name
            story
            rarity
            trackTag
            songList {
                id
                img
                media
                video
                title
                artist
                length
                contract
                tokenId
            }
            }
        }
    `;
    const [initial, setInitial] = useState(false);
    const [mbList, setMBList] = useState<number[]>([]);
    const [songIndex, setSongIndex] = useState(0);
    const [mediaPlaying, setMediaPlay] = useState(false);

    const { loading } = useQuery(GET_EVENT_INFO, {
        onCompleted: (data: any) => {
            if (initial) return; // only need to run once
            let currList = mbList;
            if (data.DjBoard) {
                const EventInfo = JSON.parse(data.DjBoard);
                if (EventInfo['mbList']) {
                    for (let i = 0; i < EventInfo['mbList'].length; i++)
                        currList.push(EventInfo['mbList'][i]['id']);
                }
            }
            function sort(array: number[]) {
                array.sort(() => Math.random() - 0.5);
            }
            if (currList.length < 1) currList.push(1);
            sort(currList);

            let newToken = mbList[0];
            LoadAlbumInfo(newToken);
        },
        onError: (err: any) => {
            console.warn(err);
            return (
                <div>
                    <p className="p-6 text-lg text-white">Network Error</p>
                </div>
            );
        },
    });
    const [boomInfo, setBoomInfo] = useState<any>({
        boomImg:
            'https://fansi-static.s3.ap-southeast-1.amazonaws.com/MetaBoom/parts/default/001.png',
        name: '',
        story: '',
        title: '',
    });
    const [currentSong, setCurrSong] = useState<songProps>({
        id: '0',
        img: '',
        media:
            '',
        title: '',
        artist: '',
        type: 1,
        length: 0,
    });
    // const [mediaRdy, setMediaRdy] = useState(false);
    const [tokenId, setTokenId] = useState(-1);
    const [getBoomInfo, { loading: loadingBoomInfo }] = useLazyQuery(
        GET_BOOM_INFO,
        {
            variables: { tokenId },
            fetchPolicy: 'network-only',
            onCompleted: (data: any) => {
                setBoomInfo(data.boomInfo);
                if (data.boomInfo.songList && data.boomInfo.songList.length > 0) {
                    setCurrSong(data.boomInfo.songList[0]);
                }

                if (!initial) setInitial(true);
            },
            onError: (err: any) => {
                console.warn(err);
            },
        }
    );

    if (loading || loadingBoomInfo) return <div></div>;

    function LoadAlbumInfo(newToken: number) {
        setTokenId(newToken);
        let currMBL = mbList;
        let currIndex = currMBL.indexOf(newToken);
        if (currIndex < 0) {
            currMBL.unshift(newToken);
            setMBList(currMBL);
        }
        getBoomInfo({ variables: { tokenId: newToken } });
    }

    let mediaRef: React.RefObject<HTMLVideoElement>;
    mediaRef = createRef();

    function SwitchPlayPause() {
        if (!mediaRef.current) return;
        // if (mediaRef.current && !videoElm) {
        //   setVideoElm(mediaRef.current);
        // }
        let player = mediaRef.current;
        if (player.paused) {
            setMediaPlay(true);
            player.play();
        } else {
            setMediaPlay(false);
            player.pause();
        }
        // setMediaRdy(true);
    }

    function SwitchSong(index: number) {
        if (index < 0) index = boomInfo.songList.length - 1;
        if (index >= boomInfo.songList.length) index = 0;
        if (boomInfo.songList[index]) {
            setSongIndex(index);
            let song = boomInfo.songList[index];
            setCurrSong(song);
            if (mediaRef.current) {
                if (mediaPlaying) {
                    mediaRef.current.autoplay = true;
                } else {
                    mediaRef.current.autoplay = false;
                }
            } else {
                setMediaPlay(false);
            }
        }
        // setMediaRdy(false);
    }
    function MediaLoaded() {
        // setMediaRdy(true);
        if (mediaRef.current)
            mediaPlaying ? mediaRef.current.play() : mediaRef.current.pause();
    }

    function SwitchMB(next: boolean, tokenId: number) {
        if (loadingBoomInfo) return;
        let index = mbList.indexOf(tokenId);
        if (index < 0) index = 0;

        index = index + (next ? 1 : -1);

        if (index >= mbList.length) index = 0;
        if (index < 0) index = mbList.length - 1;
        let newMB = mbList[index];
        LoadAlbumInfo(newMB);
    }
    return (
        <div className="w-full my-auto flex flex-wrap h-16 p-1"
            style={{ borderTop: '1px solid', borderColor: 'rgba(8, 157, 198, 0.51)', backdropFilter: 'blur(8px)', backgroundColor: 'rgba(0, 0, 0, 0.6)', }} >
            <video
                ref={mediaRef}
                onEnded={() => SwitchSong(songIndex + 1)}
                autoPlay
                playsInline
                className="hidden"
                src={currentSong['media']}
                onLoadedData={MediaLoaded}
            />
            <div className="justify-center lg:justify-start text-center lg:text-left w-2/3 lg:w-1/3 flex px-0 lg:px-8">
                <div className="flex truncate">
                    <IMG src='ArrowLeft' onClick={() => SwitchMB(false, tokenId)}
                        className="w-5 h-5 cursor-pointer mx-3 lg:mr-6 my-auto" />
                    <Link to={"/MetaBoom/" + tokenId}>
                        <img src={boomInfo['boomImg']} alt='' className="my-auto mr-2 lg:mr-4 w-11 h-11" />
                    </Link>
                    <div className="w-full my-auto pt-1" style={{ width: '50%' }}>
                        <p className='w-full truncate Roboto text-xs font-bold gen2blue' >
                            {currentSong['title']}
                        </p>
                        <p className='w-full h-6 truncate text-xs Roboto text-left' >
                            {boomInfo['name']}
                        </p>
                    </div>
                    <IMG src='ArrowRight' onClick={() => SwitchMB(true, tokenId)}
                        className="w-5 h-5 cursor-pointer ml-3 lg:ml-6 my-auto" />
                </div>
            </div>
            <div className="w-1/3 pt-0 lg:pt-2 px-6 lg:px-0 flex flex-wrap-reverse">
                <div className="w-full h-l0 relative flex">
                    <div className="flex mx-auto">
                        <IMG src='Previous' className="w-6 h-6 cursor-pointer mr-6 my-auto  hidden lg:block"
                            onClick={() => SwitchSong(songIndex - 1)} />
                        <IMG src={mediaPlaying ? 'Pause' : 'Play'}
                            className="w-8 h-8 cursor-pointer mr-6 my-auto"
                            onClick={SwitchPlayPause} />
                        <IMG src='Next'
                            className="w-6 h-6 cursor-pointer my-auto"
                            onClick={() => SwitchSong(songIndex + 1)} />
                    </div>
                    {/* <div
                        //onClick={() => setPanel(true)}
                        className="absolute right-0 mt-2 block lg:hidden flex"
                    >
                        <img src={iconStreamG} className="w-6 h-6 mr-2 my-auto" />
                        <p className="my-auto salmon">L2E</p>
                    </div> */}
                </div>
                {/* {videoElm ? (
                <ProgressBar MediaRef={videoElm} />
              ) : (
                <div className="h-10 w-full" />
              )} */}
            </div>
            <div className="w-full lg:w-1/3 pt-2 px-8 text-right hidden lg:block">
                <Link to={'/explore'}>
                    <Button
                        className="NotoSans my-auto text-center mr-5"
                        style={{ minWidth: 109 }}
                        appearance="rainbow"
                    >
                        <div className="flex justify-center">
                            <p className="text-xs">Listen more</p>
                            <IMG src='ArrowRightBlue' className="my-auto" />
                        </div>
                    </Button>
                </Link>
            </div>
            {/*<div className='flex px-6'>
                        <Link to='/mint' className='hidden lg:block my-auto'>
                            <Button className='px-4 w-44 h-12 flex ' style={{ backgroundColor: 'var(--salmon)' }}>
                                <img className='w-8 h-8 my-auto mr-2' src={iconBTC} />
                                <p className='Roboto font-bold italic text-xl my-auto'>{'Mint MetaBoom!'}</p>
                            </Button>
                        </Link>
                                            </div>*/}
        </div>)

};

export const BtnShare = ({ name, l2eBlock, userId, shareImg, story, boomboxId, btnStyle }:
    { name: string; l2eBlock: boolean; userId?: number; shareImg?: string; story: string; boomboxId: number; btnStyle?: number; }) => {
    const [nonce, setNonce] = useState('NA');
    const [showPopUp, setPopUp] = useState(false);
    const [popUpContent, setPopUpContent] = useState({
        title: '',
        content: '',
        shareTitle: '',
        type: '',
        shareImg: '',
        story: '',
        id: 0,
    });
    const [spinnerOn, setSpinner] = useState(false);
    const [getShareLink, { loading: loadingInfo }] = useLazyQuery(
        gql`
      query GetShareLink($tokenId: Int!) {
        info: getShareLink(tokenId: $tokenId)
      }
    `,
        {
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                if (data.info) {
                    let info = JSON.parse(data.info);
                    if (info.nonce) setNonce(info.nonce);
                }
                LoadPopUp({ title: 'Share My Playlist', share: true });
            },
            onError: (err: any) => {
                setNonce(''); //not login
                LoadPopUp({ title: 'Share My Playlist', share: true });
            },
        }
    );

    if (loadingInfo || spinnerOn)
        return (
            <>
                {btnStyle && btnStyle === 2 ?
                    <div className='w-8 h-8' /> :
                    <div className={'w-16 flex justify-center'}>
                        <Spinner />
                    </div>}
            </>
        );

    function LoadPopUp({ title, content, share, }: { title: string; content?: string; share?: boolean; }) {
        let popup = popUpContent;
        popup.title = title;

        if (share) {
            popup.shareTitle = `${name}｜MetaBoom - Let’s DJ in metaverse`;
            if (shareImg) {
                popup.shareImg = shareImg;
            }
            popup.story = story;
            popup.id = boomboxId;
        } else {
            popup.shareTitle = '';
        }
        if (content) popup.content = content;
        else popup.content = '';
        setPopUpContent(popup);
        setPopUp(true);
    }

    function GetShareInfo() {
        if (!userId || userId < 1) {
            window.location.href =
                window.location.origin +
                '/login?' +
                stringify({ n: window.location.pathname });
            setSpinner(true);
            return;
        }
        if (loadingInfo) return;
        if (nonce === 'NA') {
            getShareLink({ variables: { tokenId: boomboxId } });
        } else {
            LoadPopUp({ title: 'Share My Playlist', share: true });
        }
    }

    return (
        <>
            <button className={(btnStyle && btnStyle === 2 ? 'w-8 ' : 'w-16 ') + ' text-base text-center flex ' + (l2eBlock ? 'opacity-50 cursor-not-allowed' : '')}
                style={{ height: 32, borderColor: 'white', color: 'white' }} disabled={l2eBlock} onClick={GetShareInfo} >
                {(btnStyle && btnStyle === 2) ?
                    <IMG src='Share' className="w-8 h-8" /> :
                    <div className="m-auto flex">
                        <IMG src='ShareBlue' className="w-6 h-6 mr-1" />
                        <p className="Roboto gen2blue">Share</p>
                    </div>}
            </button>
            <PopupMsgBlock
                popSwitch={showPopUp}
                extraLink={nonce ? '&l2e=' + nonce : ''}
                {...popUpContent}
                handleClose={() => setPopUp(false)}
            />
        </>
    );
};

export const BtnFavorite = ({ userId, likes, setLikes, boomboxId, l2eBlock, btnStyle }:
    { userId?: number; likes: boolean; setLikes: any; boomboxId: number; l2eBlock: boolean; btnStyle?: number; }) => {
    const [mblikes, updLikes] = useState(likes);
    const [lastUpd, setUpdTime] = useState(new Date().getTime() - 60000);
    const [spinnerOn, setSpinner] = useState(false);

    const [updMBLikesDB, { loading: likesUpding }] = useMutation(
        gql`
      mutation UpdMBLikes($tokenId: Int!, $status: Boolean!) {
        result: updMBLikes(tokenId: $tokenId, status: $status)
      }
    `,
        {
            onCompleted: (data: any) => {
                let status = data.result ? true : false;
                updLikes(status);
                setLikes(status);
            },
            onError: (err: any) => {
                console.warn(err);
            },
        }
    );

    useEffect(() => {
        updLikes(likes);
    }, [likes])

    if (likesUpding || spinnerOn)
        return (
            <div className="w-24 flex justify-center">
                <Spinner />
            </div>
        );
    function HandleLikesChange() {
        if (!userId || userId < 1) {
            window.location.href =
                window.location.origin +
                '/login?' +
                stringify({ n: window.location.pathname });
            setSpinner(true);
            return;
        }
        if (likesUpding) return;
        let current = new Date().getTime();
        if (current - lastUpd < 1000) {
            alert('Please wait a while to do the action again');
            return;
        }
        setUpdTime(current);
        updMBLikesDB({
            variables: { tokenId: boomboxId, status: mblikes ? false : true },
        });
    }
    return (
        <button disabled={likesUpding} onClick={HandleLikesChange}
            className={((btnStyle && btnStyle === 2) ? 'w-8' : 'w-24') + ' text-base text-center rounded-full h-8 flex ' + (l2eBlock ? 'opacity-50' : '')}>
            <div className="m-auto flex">
                {btnStyle && btnStyle === 2 ?
                    <IMG style={{ width: 30, height: 30 }} src={mblikes ? 'FavoriteDarkWhite' : 'Favorite'} /> :
                    <>
                        <IMG className="w-6 h-6 mr-1" src={mblikes ? 'FavoriteDark' : 'FavoriteBlue'} />
                        <p className="Roboto my-auto gen2blue">Favorites</p>
                    </>}
            </div>
        </button>
    );
};

const BtnStory = ({ story, tags }: { story: string; tags: string[] }) => {
    const [showTooltip, setTooltip] = useState(false);

    const TooltipStyle = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "black",
            border: '1px solid #5fddff',
            borderRadius: '1rem',
            height: 288,
            widht: 96,
            padding: 0
        },
    }));
    return (
        <div className="w-6 h-6">
            <TooltipStyle title={<MBInfo story={story} tags={tags} />} placement="top" open={showTooltip}>
                <IconButton style={{ padding: '0px', position: 'absolute', right: 0 }} onClick={() => setTooltip(!showTooltip)}>
                    <IMG className="w-6 h-6" src='InfoBlue' />
                </IconButton>
            </TooltipStyle>
        </div>
    );
};

const MBInfo = ({ story, tags }: { story: string; tags: string[] }) => {
    let count = 1;
    return (
        <div className="h-72 w-60 relative">
            <div className="gen2blue absolute top-0 right-0 w-60 h-full p-6">
                <p className="Roboto text-base font-bold">About this Playlist</p>
                <p className="w-full h-32 overflow-y-auto Roboto text-white text-sm">
                    {story}
                </p>
                <div className="w-full mt-5 flex flex-wrap">
                    {tags.map((tag: any) => {
                        return (
                            <div className="mr-2 mb-2" style={{ padding: '2px 6px', borderRadius: 2, backgroundColor: 'var(--gen2blue)' }} key={'tag-' + count++}>
                                <p className="Roboto text-black text-xs">{tag}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>)
}

export const PlayListPanel = ({ name, story, playList, displayName, isOwner, userId, boomboxId, shareImg, likes, setLikes, trackTag, mbScore, avatarUrl, ownerId, boomImg, rarity }:
    { name: string; story: string; playList: songProps[]; displayName: string, isOwner?: boolean; userId?: number; boomboxId: number; shareImg?: string; likes: boolean; setLikes: any; trackTag: string[]; mbScore: ScoreProps; avatarUrl: string; ownerId: number; boomImg: string; rarity: number }) => {
    let l2eBlock = false;
    const [showStory, setStory] = useState(false);
    const [rankType, setRankType] = useState(1);
    const rarityTrack = [10, 10, 15, 20, 30, 35]
    if (!story)
        story =
            'Listen to this one and only music NFT playlist now. MetaBoom is everyone’s personal DJ booth, reimagined for the Web3 world.';
    let listIndex = -1;

    function SwitchRankType(newType: number) {
        setRankType(newType);
    }
    return (
        <div className="w-full h-full relative">
            <div className="w-full h-full relative">
                <div className='w-full lg:block hidden absolute z-40 '>
                    <div className='flex p-6 justify-between'>
                        <div className='pr-2'>
                            <p className="w-full text-center text-xl font-bold leading-none truncate">{name}</p>
                            <p className="w-full text-sm mt-2" style={{ color: '#6d6d6d' }} >
                                <span className='font-bold text-white'>{`Tracks ${playList.length}`}</span>
                                <span>{` / ${rarityTrack[rarity]}`}</span>
                            </p>
                        </div>
                        <div>
                            <IMG onClick={() => { console.log('click'); if (window.MBSwitch) window.MBSwitch(boomboxId, true) }} className='w-11 h-11 cursor-pointer' src='RoundPlayBlue' />
                        </div>
                    </div>
                </div>
                <div className='w-full lg:hidden absolute'>
                    <div className='lg:hidden pt-20 relative'>
                        <div className='relative w-full h-28 z-40 p-4'>
                            <img src={MBBackground} alt='' className='absolute left-0 top-0 w-full h-full object-cover z-10' />
                            <div className='relative w-full flex pl-28 z-40'>
                                <img className='absolute left-0 top-0 w-24 h-24' alt='' src={boomImg} />
                                <div className='relative w-full'>
                                    <p className='RussoOne truncate'>{name}</p>
                                    <p className=' text-sm mt-1 '>
                                        {playList.length + ' / ' + rarityTrack[rarity] + ' Tracks'}
                                    </p>
                                    <p className='w-26 text-sm mt-1 truncate'>
                                        By
                                        <span className=' gen2blue font-semibold ml-1 '>{displayName}</span>
                                    </p>
                                    <div className='absolute bottom-0 right-[56px] flex'>
                                        <BtnFavorite userId={userId} boomboxId={boomboxId} likes={likes} setLikes={setLikes} l2eBlock={l2eBlock} btnStyle={2} />
                                        <div className='w-2' />
                                        <BtnShare name={name} l2eBlock={l2eBlock} userId={userId} shareImg={shareImg} story={story + ''} boomboxId={boomboxId} btnStyle={2} />
                                    </div>
                                    <IMG className='absolute -bottom-[4px] right-0 w-12 h-12 cursor-pointer' onClick={() => { if (window.MBSwitch) window.MBSwitch(boomboxId, true) }} src='RoundPlayBlue' />
                                </div>
                            </div>
                        </div>
                    </div>
                    {showStory ? <div className='fixed left-0 top-0 bg-black w-full h-full mt-40 z-40 px-6' >
                        <div className="w-full flex flex-wrap " style={{ minHeight: 32 }}>
                            {trackTag.map((tag: any) => {
                                return (
                                    <div className="mr-2 mb-2" style={{ padding: '2px 6px', borderRadius: 2, backgroundColor: 'var(--gen2blue)' }} >
                                        <p className="Roboto text-black text-xs">{tag}</p>
                                    </div>
                                );
                            })}
                        </div>
                        <p className="w-full mt-6 overflow-y-auto Roboto text-white text-sm" style={{ height: '75vh' }}>
                            {story}
                        </p>
                        <p onClick={() => setStory(false)} className='fixed left-0 bottom-0 mb-20 w-full text-center font-bold Roboto gen2blue cursor-pointer'>Close Information</p>
                    </div> : ''}
                    <div className="lg:hidden w-full hr" style={{ backgroundColor: 'var(--gen2blue)' }} />
                </div>
                <div className={'w-full relative pt-44 lg:pt-24 h-full ' + (rankType === 1 ? 'pb-24' : 'pb-12')}>
                    <div className="flex justify-center overflow-x-auto hideSBar lg:pt-0 pt-8">
                        <TabMenu menuType={1} currType={rankType} text='Playlist' callBack={SwitchRankType} listType={1} />
                        <TabMenu menuType={2} currType={rankType} text='Score' callBack={SwitchRankType} listType={1} />
                    </div>
                    <div className={'px-5 w-full h-full pb-12 ' + (rankType === 1 ? 'lg:pb-0' : 'lg:pb-12')}>
                        <div className='w-full h-full' style={{ borderTopWidth: 2, borderColor: 'rgba(8, 157, 198, 1)' }}>
                            {rankType === 1 ?
                                <div className='relative w-full h-full overflow-y-auto pb-6'>
                                    <div className='w-full h-full '>
                                        {
                                            playList.map((song: songProps) => {
                                                listIndex++;
                                                let currIndex = listIndex;
                                                let link = 'https://opensea.io/assets/' + (song.chainId && song.chainId === 137 ? 'matic/' : 'ethereum/')
                                                if ((song.id + '').includes('F')) {
                                                    link = song.contract + '';
                                                } else {
                                                    link += song.contract + '/' + song.tokenId;
                                                }
                                                return (
                                                    <div className='w-full flex h-12 mt-4' key={'song-' + listIndex} >
                                                        <p className="my-auto w-4 mr-4">{currIndex + 1 + '.'}</p>
                                                        <img src={song.img ? song.img : GetDefaultCover(song.id)} alt='' className="h-12 w-12 mr-4" />
                                                        <div className="my-auto w-48 lg:w-35 overflow-hidden">
                                                            <div className="pr-6 flex relative">
                                                                <p className="NotoSans text-sm font-medium w-44 lg:w-32 truncate">
                                                                    {song.title}
                                                                </p>
                                                                <a href={link} target="_blank" rel="noreferrer noopener">
                                                                    <TrackIcon id={song.id + ''} className="w-4 h-4 mt-1 absolute top-0 right-0" />
                                                                </a>
                                                            </div>
                                                            <p className="text-gray-300 text-sm">
                                                                {ConvertTime(song.length, true)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>                                    
                                </div> :
                                <DJScorePanel mbScore={mbScore} isOwner={isOwner ? true : false} boomboxId={boomboxId} setStory={setStory} />
                            }
                        </div>
                    </div>
                </div>

                <div className='w-full absolute bottom-0'>
                    <div className="hidden lg:block w-full hr " style={{ backgroundColor: 'var(--gen2blue)' }} />
                    <div className="w-full px-8 lg:px-5 relative">
                        <div className="h-full relative">
                            <div className="w-full ">
                                <div className='mt-4 hidden lg:block w-full relative'>
                                    <div className="w-full flex pb-3">
                                        <div className="pr-4">
                                            <BtnFavorite userId={userId} boomboxId={boomboxId} likes={likes} setLikes={setLikes} l2eBlock={l2eBlock} />
                                        </div>
                                        <BtnShare name={name} l2eBlock={l2eBlock} userId={userId} shareImg={shareImg} story={story + ''} boomboxId={boomboxId} />
                                        {isOwner ?
                                            <Popup arrowStyle={{ color: '#000' }}
                                                trigger={<Link to={'/playlist/edit/' + boomboxId}>
                                                    <IMG className="absolute right-0 mt-1 mr-7 cursor-pointer " src='EditBlue' />
                                                </Link>}
                                                position="top center"
                                                on="hover" >
                                                <div className='px-2 py-1'>
                                                    <p className='text-white bg-black'>edit playlist</p>
                                                </div>
                                            </Popup>
                                            : ''}
                                        <div className='absolute right-0 mt-1'>
                                            <BtnStory story={story} tags={trackTag} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div >
    );
};

const DJScorePanel = ({ mbScore, isOwner, boomboxId, setStory }: { mbScore: ScoreProps; isOwner: boolean, boomboxId: number, setStory: any }) => {
    let mbEarned = mbScore.lastMonthlyScore
        ? Math.round(mbScore.lastMonthlyScore / 10)
        : 0;
    if (mbEarned > MONTHLY_INHERIT_CAP) mbEarned = MONTHLY_INHERIT_CAP;
    return (
        <div className="w-full relaive h-full">
            <div className='w-full h-full relative'>
                <div className='w-full h-full pr-2 overflow-y-auto'>
                        <div className="w-full flex mt-4">
                            <div className="w-1/2 flex">
                                <IMG className="w-6 lg:w-8 h-6 lg:h-8 mr-2 my-auto " src='Trophy' />
                                <p className="Roboto font-bold djPink my-auto ">DJ Score</p>
                            </div>
                            <p className="w-1/2 text-right text-2xl font-bold djPink NotoSans my-auto">
                                {mbScore.monthlyScore}
                            </p>
                        </div>
                        <div className="flex w-full mt-6 relative">
                            <IMG className="w-6 h-6 mr-4 my-auto " src='Stream' />
                            <p className="w-1/2 my-auto">Stream</p>
                            <p className="absolute right-0 text-right w-1/2 my-auto Roboto">
                                {mbScore.scoreRecord.stream}
                            </p>
                        </div>
                        <div className="flex w-full mt-5 relative">
                            <IMG className="w-6 h-6 mr-4 my-auto " src='Favorite' />
                            <p className="w-1/2 my-auto">Favorite</p>
                            <p className="absolute right-0 text-right w-1/2 my-auto Roboto">
                                {mbScore.scoreRecord.likes}
                            </p>
                        </div>
                        <div className="flex w-full mt-5 relative">
                            <IMG className="w-6 h-6 mr-4 my-auto " src='Share' />
                            <p className="w-1/2 my-auto">Share</p>
                            <p className="absolute right-0 text-right w-1/2 my-auto Roboto">
                                {mbScore.scoreRecord.clicks}
                            </p>
                        </div>
                        <div className="flex w-full mt-5 relative">
                            <IMG className="w-6 h-6 mr-4 my-auto " src='Star' />
                            <div className="w-2/3 flex">
                                <p className="my-auto">Earned</p>
                            </div>
                            <p className="absolute right-0 text-right w-1/2 my-auto Roboto">
                                {mbEarned}
                            </p>
                        </div>
                    <div className='w-full flex lg:hidden mt-8'>
                        <div className='px-7 w-full flex'>
                            <div className='w-full'>
                                <Button className='justify-center flex px-4 h-8 w-full ' onClick={() => setStory(true)} >
                                    <IMG className="w-4 h-4 my-auto mr-1 " src='InfoBlue' />
                                    <p className='gen2blue NotoSans font-medium text-sm my-auto'>Playlist Info</p>
                                </Button>
                            </div>
                            {isOwner ?
                                <div className='w-2/12 ml-6'>
                                    <Link to={'/playlist/edit/' + boomboxId}>
                                        <Button className='flex h-8 w-8'>
                                            <IMG className='absolute-center w-4 h-4' src='EditBlue' />
                                        </Button>
                                    </Link>
                                </div> : ''}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};


const MetaBoom = () => {
    const GET_BOOM_INFO_ALL = gql`
        query GetMetaBoomInfo($tokenId: Int!) {
            boomInfo: getMetaBoomInfo(tokenId: $tokenId) {
                ownerId
                tokenId
                series
                displayName
                avatarUrl
                boomImg
                background
                name
                story
                rarity
                trackTag
                songList {
                    id
                    img
                    media
                    video
                    title
                    artist
                    length
                    contract
                    tokenId
                    chainId
                }
            }
            likes: getMBLikes(tokenId: $tokenId)
            info: getMBScore(tokenId: $tokenId)
        }
    `;
    const [albumId] = useState(0);
    let { idNumber } = useParams();
    let projectId = parseInt(idNumber + '')
    const id = useMemo(() => {
        if (albumId > 0 && albumId !== projectId) return albumId;
        if (isNaN(projectId) || projectId < 1) return 0;
        return projectId;
    }, [projectId, albumId]);
    const [songIndex, setSongIndex] = useState(-1);
    const [videoElm, setVideoElm] = useState<HTMLVideoElement | undefined>(undefined);
    const [playlistOn] = useState(true);
    const [showPopUp, setPopUp] = useState(false);
    const [popUpContent] = useState({ title: '', content: '', shareTitle: '', type: '', });
    const [boomInfo, setBoomInfo] = useState<any>(null);
    const [ownerId, setOwnerId] = useState(0);
    const [isOwner, setOwnerShip] = useState(false);
    const [MetaBoom_IMG, setMBImg] = useState('');
    const [MetaBoom_IMG_Share, setMBSImg] = useState('');
    const [MetaBoom_Story, setMBStory] = useState('');
    const [song_list, setSongList] = useState<songProps[]>([]);
    const [DefaultSong, setDefaultSong] = useState(true);
    const [likes, setLikes] = useState(false);
    const [pageError, setPgErr] = useState(false);
    const [l2eInfo, setl2eInfo] = useState({ logType: 0, logInfo: '' });
    const [l2eChk, setL2eChk] = useState(false);
    const [trackTag, setTrackTag] = useState<string[]>([]);
    const [scoreInfo, setScoreInfo] = useState<ScoreProps>({
        monthlyListenerScore: 0,
        monthlyScore: 0,
        totalListenerScore: 0,
        totalScore: 0,
        scoreRecord: {
            clicks: 0,
            likes: 0,
            shares: 0,
            stream: 0,
        },
    });
    const [userId, setUserId] = useState(0);
    const [mbList, setMBList] = useState<number[]>([]);
    const [pageLoading, setPageLoad] = useState(false);
    const [initialed, setInitial] = useState(false);
    const [addL2eLog] = useMutation(
        gql`
      mutation UpdL2eLogs($logType: Int!, $logInfo: String!) {
        result: updL2eLogs(logType: $logType, logInfo: $logInfo)
      }
    `,
        {
            onCompleted: (data: any) => {
                switch (l2eInfo.logType) {
                    case 3:
                        sessionStorage.setItem('l2e' + id + l2eCode, 'YES');
                        break;
                }
            },
            onError: (err: any) => {
                console.warn(err);
            },
        }
    );

    const [getBoomInfo] = useLazyQuery(GET_BOOM_INFO_ALL, {
        variables: { tokenId: id },
        fetchPolicy: 'network-only',
        onCompleted: (data: any) => {
            if (!data || !data.boomInfo) {
                setPgErr(true);
                return;
            }

            //load album info
            let currMB = JSON.parse(JSON.stringify(data.boomInfo));
            currMB.id = currMB.tokenId;
            Object.preventExtensions(currMB);
            AddListenHistory(currMB);
            setBoomInfo(currMB);
            setOwnerId(currMB.ownerId)
            setOwnerShip(parseInt(currMB.ownerId) === userId && parseInt(currMB.ownerId) !== 0);
            let mbImg = currMB.boomImg.substring(0, currMB.boomImg.length - 5) + 'T.png';
            setMBImg(mbImg);
            setMBSImg(mbImg.replace('T.png', 'S.png'));
            setMBStory(currMB.story === '' ? 'Listen to this one and only music NFT playlist now. MetaBoom is everyone’s personal DJ booth, reimagined for the Web3 world.' : currMB.story);
            let currList = currMB.songList;
            setSongList(currList);
            setDefaultSong(currList[0] && currList[0]['id'] && parseInt(currList[0]['id']) < 0);
            let tmpTrackTag: string[] = [];
            if (currMB.trackTag && currMB.trackTag.length > 0) {
                for (let i = 0; i < currMB.trackTag.length; i++) {
                    tmpTrackTag.push(trackTagInfo[currMB.trackTag[i] - 1]);
                }
            }
            setTrackTag(tmpTrackTag);
            setSongIndex(0);
            // SetPlayingSong(currList[0]);

            if (data.info) {
                let info = JSON.parse(data.info);
                let currScore = scoreInfo;
                if (info && info.monthlyScore > 0 && info.monthlyScore !== currScore.monthlyScore) {
                    currScore = {
                        ...info,
                    };
                    setScoreInfo(currScore);
                }
            }
            setLikes(data.likes);
            setPageLoad(false);
        },
        onError: (err: any) => {
            console.warn(err);
            setPgErr(true);
        }
    });

    const GET_INITIAL_INFO = gql`
        query GetInitialInfo {
            me {
                id
                displayName
                avatarUrl
            }
            DjBoard: getL2eEventInfo
        }
    `;

    useQuery(GET_INITIAL_INFO, {
        onCompleted: (data: any) => {
            let currList = mbList;
            if (data.DjBoard) {
                const EventInfo = JSON.parse(data.DjBoard);
                if (EventInfo['mbList']) {
                    for (let i = 0; i < EventInfo['mbList'].length; i++) currList.push(EventInfo['mbList'][i]['id']);
                }
            }
            function sort(array: number[]) {
                array.sort(() => Math.random() - 0.5);
            }
            if (currList.length < 1) currList.push(1);
            sort(currList);
            if (id > 0) LoadAlbumInfo();

            if (data && data.me) {
                setUserId(parseInt(data.me.id));
            } else {
                setUserId(0);
            }

            setInitial(true);
        },
        onError: (err: any) => {
            console.warn(err);
            return <div className="p-6 text-lg">Invalid URL</div>;
        },
    });

    useEffect(() => {
        if (albumId > 0 && id === albumId) LoadAlbumInfo();
    }, [albumId])// eslint-disable-line react-hooks/exhaustive-deps


    let mediaRef: React.RefObject<HTMLVideoElement>;
    mediaRef = createRef();

    if (id < 1 || pageError) {
        window.location.href = window.location.origin;
        return <div className="absolute-center p-6 text-3xl">Invalid URL</div>;
    }

    if (!boomInfo || !initialed) return <Loading />;
    const currentUrl = new URL(window.location.href);

    let l2eCode = currentUrl.searchParams.get('l2e');
    if (l2eCode && !l2eChk && !DefaultSong) {
        setL2eChk(true);
        let info = sessionStorage.getItem('l2e' + id + l2eCode);
        if (!info) {
            const variables = {
                logType: 3,
                logInfo: JSON.stringify({ tokenId: id, nonce: l2eCode }),
            };
            setl2eInfo(variables);
            addL2eLog({ variables });
        }
    }

    function LoadAlbumInfo() {
        if (pageLoading) return;
        setPageLoad(true);
        let currMBL = mbList;
        let currIndex = currMBL.indexOf(id);
        if (currIndex < 0) {
            currMBL.unshift(id);
            setMBList(currMBL);
        }

        getBoomInfo({ variables: { tokenId: id } });
    }

    if (songIndex < 0 && song_list.length > 0) {
        setSongIndex(0);
    }

    if (mediaRef.current && !videoElm) {
        setVideoElm(mediaRef.current);
    }

    return (
        <>
            <ScrollToTopOnce />
            {pageLoading ? <div className='absolute left-0 top-0 h-screen w-full bg-black bg-opacity-50 z-50 flex'>
                <div className='w-40 h-20 m-auto' >
                    <Loading /></div>
            </div > : ''}
            <div className="section-lg mx-auto relative" style={{ backgroundColor: '#090909', minHeight: 620, height: '100vh' }} >
                <Helmet title={`${boomInfo.name}｜MetaBoom - Let’s DJ in metaverse`} >
                    <meta property='og:title' content={`${boomInfo.name}｜MetaBoom - Let’s DJ in metaverse`} />
                    <meta property='og:url' content={window.location.href} />
                    <meta key='name' name='description' content={MetaBoom_Story.substring(0, 100)} />,
                    <meta key='prop' property='og:description' content={MetaBoom_Story.substring(0, 100)} />
                    <meta property="og:image" content={MetaBoom_IMG_Share} />
                    <meta property="og:type" content="article" />
                    <meta property="twitter:card" content="player" />
                    <meta property="twitter:url" content={window.location.href} />
                    <meta property="twitter:title" content={`${boomInfo.name}｜MetaBoom - Let’s DJ in metaverse`} />
                    <meta property="twitter:description" content={MetaBoom_Story.substring(0, 100)} />
                    <meta property="twitter:image" content={MetaBoom_IMG_Share} />
                    <meta property="twitter:site" content="@metaboom_nft" />
                    <meta property="twitter:player" content={window.location.origin + '/MiniBoom/' + id} />
                    <meta property="twitter:player:width" content="505" />
                    <meta property="twitter:player:height" content="505" />
                </Helmet>

                <div className={'absolute overflow-hidden w-full pt-20 lg:pb-32'}
                    style={{ top: -80, minHeight: 700, height: '100vh' }} >
                    <div className='hidden lg:block  relative w-full h-full z-30' style={{ minHeight: 480 }} >
                        <img src={MBBackground} className="w-full h-screen absolute object-cover top-[-64px] left-0" alt='' />
                        <div className="hidden lg:block absolute left-0 w-1/4 h-full pl-22">
                            <div className="mt-12 h-90 w-40">
                                <div className="w-full flex">
                                    <Link to='/explore'>
                                        <Button className="mx-auto w-28 h-8 flex justify-center relative">
                                            <div className="absolute w-full h-full rounded-lg flex justify-center" style={{ backgroundColor: 'rgba(30, 57, 65, 0.7)' }}>
                                                <IMG className="w-4 h-4 my-auto mr-1 " src='ArrowLeftBlue' />
                                                <p className="mt-1 gen2blue NotoSans font-medium text-sm">
                                                    Explore
                                                </p>
                                            </div>
                                        </Button>
                                    </Link>
                                </div>
                                <div className='w-full ' style={{ height: '10vh', minHeight: 70 }} />
                                <div className="w-full  pt-24 relative">
                                    <img src="https://fansi-static.s3.ap-southeast-1.amazonaws.com/MetaBoom/img/mobile-demo.png"
                                        className="horizon-center top-0 w-20 " alt='' />
                                    <div className="w-full rounded-lg pt-18 pb-5"
                                        style={{ border: 'solid 0.1px rgb(95, 221, 255, 0.5)', backgroundColor: 'rgba(0, 0, 0, 0.64)' }} >
                                        <p className="w-full text-center Roboto font-bold gen2blue">
                                            Listen on the Go !
                                        </p>
                                        <a href="https://play.google.com/store/apps/details?id=me.fansi.app"
                                            target="_blank" rel="noreferrer noopener" >
                                            <IMG className="w-28 mx-auto mt-3 " src='GooglePlayDownload' />
                                        </a>
                                        <a href="https://apps.apple.com/tw/app/metaboom/id6444411102"
                                            target="_blank" rel="noreferrer noopener">
                                            <IMG className="w-28 mx-auto mt-3 " src='AppleStoreDownload' />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hidden lg:block absolute left-1/2 mt-64 lg:mt-0 top-0 lg:top-1/2 w-4/7 lg:w-1/5"
                            style={{ minWidth: 350, maxWidth: 600, msTransform: 'translate(-50%, -50%)', transform: 'translate(-50%,-50%)' }} >
                            <div className="w-full relative" style={{ paddingBottom: '100%' }}>
                                <div className='block lg:hidden absolute right-0' style={{ top: -88 }}>
                                    <BtnShare name={boomInfo.name} l2eBlock={false} userId={userId} shareImg={MetaBoom_IMG_Share} story={boomInfo.story + ''} boomboxId={id} btnStyle={2} />
                                </div>
                                <Link className="flex absolute left-0 lg:horizon-center w-3/5 bg-black h-13 py-1 px-2"
                                    style={{ zIndex: 90, borderRadius: 30, top: -48, border: 'solid 1px #6d6d6d', }}
                                    to={`/collection/${(ownerId > 0 ? ownerId : (100000000 + id))}`} >
                                    <img className="w-10 h-10 mr-4 rounded-full my-auto " alt='' style={{ border: 'solid 1px #6d6d6d' }}
                                        src={boomInfo.avatarUrl ? boomInfo.avatarUrl : GetDefaultAvatar(boomInfo.ownerId)} />
                                    <div className=" text-white my-auto w-2/3">
                                        <p className="NotoSans font-medium truncate leading-tight">
                                            {boomInfo.displayName}{' '}
                                        </p>
                                        <p className="NotoSans font-medium text-sm">{'MetaDJ'} </p>
                                    </div>
                                </Link>
                                <img className="w-full h-full absolute" alt='' src={MetaBoom_IMG} />

                                <div className="absolute w-full" style={{ bottom: 0 }}>
                                    <div className="absolute w-full top-0">
                                        <p className={'truncate w-full text-center NotoSans font-bold text-3xl'} >
                                            {boomInfo.name}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'w-1/4 h-full absolute right-0 z-40'} style={{ minWidth: 360 }}>
                            <div className="hidden lg:block w-full h-full  pb-8 pt-20 pr-16">
                                <div className="w-full h-full relative rounded-2xl"
                                    style={{ border: 'solid 1px', borderColor: 'var(--gen2blue)', backgroundColor: 'rgba(9, 9, 9, 0.9)', minWidth: 280 }} >

                                    {playlistOn ?
                                        <PlayListPanel trackTag={trackTag} likes={likes} setLikes={setLikes} story={boomInfo.story}
                                            name={boomInfo.name} playList={song_list} isOwner={isOwner}
                                            userId={userId} boomboxId={id} shareImg={MetaBoom_IMG_Share}
                                            mbScore={scoreInfo} displayName={boomInfo.displayName}
                                            avatarUrl={boomInfo.avatarUrl}
                                            ownerId={ownerId} boomImg={MetaBoom_IMG}
                                            rarity={boomInfo.rarity}
                                        /> : ''}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="block lg:hidden w-full h-full relative z-30" aria-label="MetaBoom Main" >
                        <div className={' absolute w-full h-full bottom-0'}
                            style={{ height: '100vh', backgroundColor: '#090909' }} >
                            <PlayListPanel trackTag={trackTag} likes={likes} setLikes={setLikes} story={boomInfo.story}
                                name={boomInfo.name} playList={song_list} displayName={boomInfo.displayName} isOwner={isOwner}
                                userId={userId} boomboxId={id} shareImg={MetaBoom_IMG_Share}
                                mbScore={scoreInfo} avatarUrl={boomInfo.avatarUrl} ownerId={ownerId} boomImg={MetaBoom_IMG} rarity={boomInfo.rarity} />
                        </div>
                    </div>
                </div>

                <PopupMsgBlock
                    popSwitch={showPopUp}
                    {...popUpContent}
                    handleClose={() => setPopUp(false)}
                />
            </div>
        </>
    );
};

export const TrackIcon = ({ id, className }: { id: string, className?: string }) => {
    let icon = 'EthBadge';
    if (id.includes('F')) icon = 'FansiBadge';
    if (id.includes('S')) icon = 'OpenSeaBadge';
    return <IMG className={className ? className : 'w-6 h-6'} src={icon} />
}

export default MetaBoom;
