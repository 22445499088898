import { useState } from 'react';
import { Link, useParams, Routes, Route } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Popup from 'reactjs-popup';
import gql from 'graphql-tag';
import qs from 'qs';

import FarmingQuest from './FarmingQuest';
import ProfileBlock from './ProfileBlock';
import { MBBlockProps } from '../../lib/utils';
import { IMG } from '../../lib/imgLib';
import {
    Loading,
    TabLink,
    Button,
    ScrollToTopOnce,
    GetDefaultAvatar,
    MusicManiacPopup,
    MetaDJPopup,
    Spinner,
    MusicScoreFarmingPopup,
} from '../../lib/components';

import frameBottom from '../img/frame_collection.svg';
import Favorite from './Favorite';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MONTHLY_INHERIT_CAP = 240000;

const UPDATE_PROFILE = gql`
  mutation SaveProfile($payload: UserMetaUpdate) {
    updateProfile(payload: $payload) {
      avatarUrl
    }
  }
`;

interface recordProps {
    clicks: number;
    likes: number;
    shares: number;
    stream: number;
}

export interface ScoreProps {
    lastMonthlyListenerScore?: number;
    lastMonthlyScore?: number;
    monthlyListenerScore: number;
    monthlyScore: number;
    totalListenerScore: number;
    totalScore: number;
    scoreRecord: recordProps;
}

const RarityInfo: { [k: string]: any } = {
    Antenna: {
        Stegosaurus: 'Epic',
        Rotini: 'Common',
        Coccyx: 'Common',
        Antenna: 'Common',
        Party: 'Mythical',
        Shooter: 'Epic',
        'Fire-in-the-hole': 'Rare',
        Space: 'Mythical',
        'Jolly roger': 'Epic',
        Cigarette: 'Common',
        'Golden scepter': 'Epic',
        Steampunk: 'Rare',
        Bolt: 'Common',
    },
    Body: {
        Dinosaur: 'Common',
        Cheese: 'Epic',
        Nudity: 'Common',
        Robot: 'Epic',
        'Booger Jelly Bean': 'Rare',
        Tank: 'Common',
        Camouflage: 'Common',
        Starfire: 'Rare',
        Earth: 'Epic',
        Cloud: 'Common',
        Meat: 'Common',
        Puzzle: 'Common',
        Trippy: 'Mythical',
        'Leopard & Gold': 'Mythical',
        Steampunk: 'Epic',
        Wall: 'Common',
        Fluid: 'Rare',
        Ribbon: 'Epic',
        Mummy: 'Common',
        Rainbow: 'Rare',
        'Purple cloud': 'Rare',
        'Warm fluid': 'Epic',
    },
    Buttons: {
        Claws: 'Rare',
        Fork: 'Rare',
        Bones: 'Rare',
        Switches: 'Common',
        Rainbow: 'Epic',
        'Battle Knobs': 'Common',
        'Death Knobs': 'Common',
        Crane: 'Mythical',
        Piano: 'Epic',
        Octopus: 'Epic',
        Cigarettes: 'Epic',
        Bitcoin: 'Mythical',
        Awl: 'Common',
        Pipes: 'Common',
        Drawer: 'Common',
        Typewriter: 'Common',
        'Cash register': 'Common',
        Mahjong: 'Rare',
    },
    Handles: {
        'Gold Chain': 'Epic',
        "Devil's cornrows": 'Mythical',
        tentacle: 'Epic',
        'Steam pipe': 'Common',
        Bolt: 'Common',
        Polypteridae: 'Rare',
        Sausages: 'Epic',
        Laser: 'Mythical',
        Bones: 'Common',
        'Coax-C': 'Common',
        'Coax-G': 'Common',
        Spacesuit: 'Rare',
        "McDonald's": 'Epic',
    },
    Panel: {
        'Private parts': 'Common',
        Pizza: 'Mythical',
        'Cross bones': 'Common',
        'Laser Panel': 'Rare',
        Eyes: 'Common',
        Cannon: 'Rare',
        Bullet: 'Common',
        'Solar Panel': 'Common',
        Submarine: 'Epic',
        SMTM: 'Epic',
        'Golden leopard': 'Epic',
        Gauge: 'Mythical',
        'Meat slice': 'Common',
        Skateboard: 'Epic',
    },
    Side: {
        'Dorsal fin': 'Common',
        Banana: 'Mythical',
        Bones: 'Common',
        Sensor: 'Epic',
        Balloon: 'Rare',
        'Grenade-G': 'Common',
        'Grenade-C': 'Common',
        Moon: 'Rare',
        Toy: 'Rare',
        'White Gloves': 'Common',
        Fighter: 'Common',
        Gangster: 'Common',
        'Robot hand': 'Epic',
        'Robot arm': 'Mythical',
        Knife: 'Epic',
        Lightning: 'Epic',
        'Rainbow tree': 'Epic',
        'Steel tree': 'Common',
        Tree: 'Common',
        Wing: 'Common',
        'Cocunut tree': 'Epic',
        Skeleton: 'Common',
        'Magic mushrooms': 'Mythical',
        BTC: 'Epic',
        'Poison Skull': 'Rare',
        Bolts: 'Common',
        Candle: 'Common',
        Demon: 'Rare',
        'Golden skull': 'Epic',
        Toadstool: 'Mythical',
        Gold: 'Epic',
        'Dark Robot': 'Mythical',
        'Robot Skull': 'Common',
    },
    Speaker: {
        Tyrannosaurus: 'Common',
        'Taste of Crypto': 'Epic',
        Madness: 'Rare',
        Monster: 'Epic',
        Instinct: 'Mythical',
        Magnate: 'Mythical',
        'Flying goggle': 'Epic',
        Graffeur: 'Rare',
        'Devil flower': 'Common',
        Nightmare: 'Common',
        'The Vortex': 'Common',
        Cyborg: 'Rare',
        'Ape in': 'Epic',
        'BTC helmet': 'Common',
        Bloodthirst: 'Common',
        LED: 'Rare',
        Piranha: 'Common',
        Diver: 'Common',
        Denture: 'Common',
    },
    'Tape section': {
        'Sharp teeth': 'Common',
        'French fries': 'Epic',
        Brain: 'Common',
        'E-brain': 'Epic',
        'Tape Monster': 'Mythical',
        Ammeters: 'Rare',
        Voltmeter: 'Common',
        'Space backpack': 'Mythical',
        'Treasure Map': 'Common',
        Weed: 'Epic',
        'King of Gold': 'Epic',
        Steampunk: 'Rare',
        'Spray Paint': 'Common',
    },
    Top: {
        Dinosaur: 'Mythical',
        Hamburgers: 'Epic',
        Skulls: 'Common',
        Motherboard: 'Common',
        Creativity: 'Epic',
        'Turret-G': 'Common',
        Turret: 'Common',
        'Space Station': 'Mythical',
        Kraken: 'Common',
        'Meat & butter': 'Common',
        Flame: 'Rare',
        'Golden mountain': 'Rare',
        Poison: 'Epic',
        Concrete: 'Common',
        'Purple cloud': 'Common',
        Cloud: 'Common',
    },
};

const DJScoreBlock = ({ icon, title, score }: any) => {
    return (
        <div className='w-1/4 flex flex-wrap'>
            <div className='flex mx-auto'>
                <IMG src={icon} className='my-auto w-6 h-6' />
                <p className='hidden lg:block ml-1 my-auto text-base text-left font-bold'>
                    {title}
                </p>
            </div>
            <p className='w-full text-center my-auto text-base font-bold gen2blue'>
                {score}
            </p>
        </div>
    );
};

export const MBCard = ({ tokenId, name, displayName, boomImg, image }: MBBlockProps) => {
    if (!boomImg && image) boomImg = image;

    return (
        <Link to={'/metaboom/' + tokenId} className='text-white'>
            <div className={'text-white relative bgNFT w-full p-2 rounded'} style={{ minWidth: 125 }}>
                <div className='relative w-full h-0' style={{ paddingBottom: '100%' }}>
                    <img className='absolute w-full h-full rounded' alt='' src={boomImg} />
                </div>
                <p className='mt-1 Roboto text-xs px-1 truncate'>{name}</p>
                <p className='Roboto text-xs px-1 text-gray-660 truncate mb-2' >{displayName ? displayName : 'MetaDJ'}</p>
            </div>
        </Link>
    )
}

export const MBCardLg = ({ tokenId, djId, name, displayName, boomImg, image, tracks, avatarUrl, attributes, owner }: MBBlockProps) => {
    const [showPopUp, setPopUp] = useState(false);
    const [showTooltip, setTooltip] = useState(false);
    const [popUpType, setPopupType] = useState(1);

    if (!tracks || tracks < 0) tracks = 0;
    if (!avatarUrl) avatarUrl = GetDefaultAvatar(djId);
    if (!displayName) displayName = 'Meta DJ';
    if (!boomImg && image) boomImg = image;

    //1 MB image 2 MB part info
    function ShowPopUp(type: number) {
        switch (type) {
            case 2:
                setPopupType(2);
                if (attributes) setPopUp(true);
                break;
            default:
                setPopupType(1);
                setPopUp(true);
                break;
        }
    }

    const TooltipStyle = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "black",
            border: '1px solid #5fddff',
            borderRadius: '1rem',
        },
    }));

    return (
        <div className={'w-full rounded relative boderNFT border border-black bg-darkestBlue'} style={{ padding: '18px 14px' }}>
            <Popup open={showPopUp} closeOnDocumentClick={false} >
                <div className='fixed w-full h-full bg-black text-white bg-opacity-80 top-0 left-0 flex'>
                    <div className='relative m-auto w-full lg:w-1/2  p-6'>
                        {popUpType === 1 ? <>
                            <IMG src='Close' onClick={() => setPopUp(false)}
                                className='fixed right-0 top-0 w-8 h-8 m-8 cursor-pointer' />
                            <div className='relative w-full mx-auto' style={{ maxWidth: 580 }}>
                                <div className='relative w-full h-0' style={{ paddingBottom: '100%' }}>
                                    <img className='absolute w-full h-full rounded' alt='' src={boomImg} />
                                </div>
                            </div>
                            {owner ? <a href={boomImg.substring(0, boomImg.length - 5) + 'T.png'} download={name + '.png'} className='text-white focus:outline-none' target='_blank' rel='noreferrer noopener' >
                                <div className='mt-6 mx-auto border border-white rounded w-40'>
                                    <p className='w-full text-center' >Download Image</p>
                                </div>
                            </a>
                                : ''}
                        </> :
                            <div className='relative w-80 m-auto border border-gen2blue rounded bg-black px-4 py-3'>
                                <IMG src='Close' onClick={() => setPopUp(false)}
                                    className='absolute right-0 top-0 w-6 h-6 m-4 cursor-pointer' />
                                <p className='mt-3 Roboto font-bold px-1 truncate'>{name}</p>
                                <p className='Roboto text-sm px-1 text-gray-660 truncate'>{(tracks > 0 ? tracks : 8) + ' tracks'}</p>
                                <div className='w-full flex flex-wrap mb-8'>
                                    {attributes ?
                                        Object.keys(attributes).map((k: any) => {
                                            let info = attributes[k];
                                            if (info['trait_type'] === 'Level') return null; //skip
                                            let rarityInfo = 'Common';
                                            let icon = 'RarityC';
                                            if (RarityInfo[info['trait_type']]) {
                                                if (RarityInfo[info['trait_type']][info['value']]) {
                                                    switch (RarityInfo[info['trait_type']][info['value']]) {
                                                        case 'Mythical':
                                                            rarityInfo = 'Mythical';
                                                            icon = 'RarityM';
                                                            break;
                                                        case 'Epic':
                                                            rarityInfo = 'Epic';
                                                            icon = 'RarityE';
                                                            break;
                                                        case 'Rare':
                                                            rarityInfo = 'Rare';
                                                            icon = 'RarityR';
                                                            break;
                                                    }
                                                }
                                            }
                                            return (
                                                <div key={'back-card-' + k} className='w-1/2 px-1'>
                                                    <div className='w-full p-2 mt-4 rounded relative bg-darkestBlue'>
                                                        <Popup position='top center' on='hover'
                                                            trigger={
                                                                <div className='absolute top-0 right-0 pr-2 pt-2 inline-block'>
                                                                    <IMG src={icon} className='relative w-6 h-6' />
                                                                </div>} >
                                                            <div className='rounded-2xl px-4 py-1 bg-white'>
                                                                {rarityInfo}
                                                            </div>
                                                        </Popup>
                                                        <p className='gen2blue Roboto italic'>
                                                            {info['trait_type']}
                                                        </p>
                                                        <p className='text-sm'>{info['value']}</p>
                                                    </div>
                                                </div>
                                            );
                                        }) : ''}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Popup>
            <Link to={'/metaboom/' + tokenId} className='text-white'>
                <div className='relative w-full h-0' style={{ paddingBottom: '100%' }}>
                    <img className='absolute w-full h-full rounded' alt='' src={boomImg} />
                </div>
            </Link>
            <div className='relative w-full flex my-2'>
                <IMG src='Search' className='w-8 h-8 cursor-pointer mr-3' onClick={() => ShowPopUp(1)} />
                {attributes ? <IMG src='Info' className='w-8 h-8 cursor-pointer ' onClick={() => ShowPopUp(2)} /> : ''}
                {owner ? <Link to={'/playlist/edit/' + tokenId}><IMG src='Edit' className='absolute right-0 w-8 h-8 my-auto' /></Link> : ''}
            </div>
            <Link to={'/metaboom/' + tokenId} className='text-white'>
                <p className='mt-3 Roboto font-bold px-1 truncate'>{name}</p>
                <p className='Roboto text-sm px-1 text-gray-660 truncate'>{(tracks > 0 ? tracks : 8) + ' tracks'}</p>
            </Link>
            <div className='w-full flex px-1 mt-4 relative'>
                <Link to={djId ? ('/collection/' + djId) : ('/metaboom/' + tokenId)} className='text-white flex w-4/5'>
                    <img className='rounded-full border border-white mr-2' alt='' src={avatarUrl} style={{ width: 26 }} />
                    <p className='text-sm my-auto truncate'>{displayName ? displayName : 'MetaDJ'}</p>
                </Link>

                {/* <Popup trigger={<IMG src='Ellipsis' className='my-auto absolute right-0 w-8 h-8' />}
                    position='top center'
                    arrowStyle={{ color: '#5fddff', strokeWidth: 2, stroke: '#5fddff' }}
                    on='click' >
                    <div className='py-4 px-3 rounded-2xl bg-black border border-gen2blue'>
                        <a href={'https://opensea.io/assets/ethereum/0x4c22d3b875437d43402f5b81ae4f61b8f764e1b1/' + tokenId}
                            target='_blank' rel='noreferrer noopener' className='focus:outline-none'>
                            <div className='w-full flex'>
                                <IMG src='OpenSea' className='w-6 h-6 mr-2' />
                                <p className='Roboto text-sm text-white my-auto'>View on Opensea</p>
                            </div>
                        </a>
                    </div>
                </Popup> */}

                <TooltipStyle title={<ViewOnOpensea tokenId={tokenId} />} placement="top" open={showTooltip}>
                    <IconButton style={{ padding: '0px', position: 'absolute', right: 0 }} onClick={() => setTooltip(!showTooltip)} >
                        <IMG src='Ellipsis' className='my-auto w-8 h-8' />
                    </IconButton>
                </TooltipStyle>

            </div>
        </div>
    )
}

const ViewOnOpensea = ({ tokenId }: { tokenId: number }) => {
    return (
        <div className='py-3 px-3'>
            <a href={'https://opensea.io/assets/ethereum/0x4c22d3b875437d43402f5b81ae4f61b8f764e1b1/' + tokenId}
                target='_blank' rel='noreferrer noopener' className='focus:outline-none'>
                <div className='w-full flex'>
                    <IMG src='OpenSea' className='w-6 h-6 mr-2' />
                    <p className='Roboto text-sm text-white my-auto'>View on Opensea</p>
                </div>
            </a>
        </div>
    )
}

const MetaDJCollection = ({ tokenId }: { tokenId: number }) => {
    const [showPopUp, setPopUp] = useState(false);
    const [showDJPopUp, setDJPopUp] = useState(false);
    const GET_BOOM_INFO = gql`
        query GetMetaBoomInfo($tokenId: Int!) {
            boomInfo: getMetaBoomInfo(tokenId: $tokenId) {
                ownerId
                tokenId
                series
                displayName
                avatarUrl
                boomImg
                background
                name
                story
                rarity   
                attributes{
                    trait_type
                    value
                }
            }
        }
    `;
    const { loading, data } = useQuery(GET_BOOM_INFO, { variables: { tokenId } });
    if (loading) return <Loading />
    if (!data || !data.boomInfo) return <div className='w-full h-96 relative'><p className='absolute-center text-3xl'>INVALID URL</p></div>
    const boomInfo = data.boomInfo;
    if (boomInfo.ownerId > 0) { //redirect to owner's collection if has owner
        window.location.href = window.location.origin + '/collection/' + boomInfo.ownerId;
        return <Loading />
    }

    return (
        <div className='w-full fansi_dark_bg'>
            <img className='section-lg absolute top-0 left-0 right-0 object-contain' alt='' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/MetaBoom/banner/collectionBG.png' />
            <ScrollToTopOnce />
            <div className='section-md relative pb-12 px-4 lg:px-0'>
                <div className='flex mt-6'>
                    <p className='mt-0 lg:mt-12 RussoOne text-2xl lg:text-6xl font-bold MetaBoom'> DJ Collection </p>
                </div>
                <div className='section-md mt-6 mb-6 lg:mb-8 px-0'>
                    <div className='w-full py-4 px-4 lg:px-6 mb-6 rounded-lg bg-black' style={{ border: '1px solid', borderColor: 'var(--gen2blue)' }} >
                        <div className='flex relative rounded-2xl'>
                            <img className='my-auto w-16 h-16 rounded-full mr-4 lg:mr-6' alt='' src={GetDefaultAvatar(tokenId)} />
                            <p className='my-auto text-xl lg:text-2xl w-32 lg:w-2/3 font-bold Roboto italic whitespace-pre'>
                                {boomInfo.displayName}
                            </p>
                        </div>

                        <hr className='px-4 pt-6 gen2blue' />
                        <div className='flex-none lg:flex rounded-2xl'>
                            <div className='flex w-full lg:w-2/5'>
                                <div className='lg:justify-end justify-start  w-full flex lg:w-1/2 mt-3 mb-3 lg:mt-8 lg:mb-4'>
                                    <p className='my-auto text-lg lg:text-2xl font-bold Roboto italic'> Junkie score </p>
                                    <IMG src='Alert' onClick={() => { setPopUp(true) }} className='cursor-pointer ml-4 my-auto w-6 h-6' />
                                </div>
                                <div className='w-full justify-end lg:justify-start flex lg:w-1/2 ml-0 lg:ml-8'>
                                    <IMG src='Diamond' className='mr-4 my-auto w-8 lg:w-11 h-8 lg:h-11' />
                                    <p className='my-auto text-xl lg:text-3xl font-bold djPink'>0 </p>
                                </div>
                            </div>
                            <div className='flex w-full lg:justify-between lg:w-3/5 mt-1 mb-6 lg:mt-6 lg:mb-4'>
                                <DJScoreBlock icon='Stream' title='Stream' score={0} />
                                <DJScoreBlock icon='Favorite' title='Favorite' score={0} />
                                <DJScoreBlock icon='Share' title='Share' score={0} />
                                <DJScoreBlock icon='Star' title='Earned' score={0} />
                            </div>
                        </div>
                        <div className='flex-none lg:flex rounded-2xl'>
                            <div className='flex w-full lg:w-2/5'>
                                <div className='lg:justify-end justify-start w-full flex lg:w-1/2 mt-3 mb-3 lg:mb-4'>
                                    <p className='my-auto text-lg lg:text-2xl font-bold Roboto italic'> DJ Score </p>
                                    <IMG src='Alert' onClick={() => { setDJPopUp(true); }} className='cursor-pointer ml-4 my-auto w-6 h-6' />
                                </div>
                                <div className='w-full justify-end lg:justify-start flex lg:w-1/2 ml-0 lg:ml-8'>
                                    <IMG src='TrophyPink' className='mr-4 my-auto w-8 lg:w-11 h-8 lg:h-11' />
                                    <p className='my-auto text-xl lg:text-3xl font-bold djPink'>0 </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-32 h-12 lg:h-8'>
                        <p className=' font-bold OpenSans gen2blue' >DJ's Collection</p>
                        <div className='w-28 bg-gen2blue' style={{ height: 2 }} />
                    </div>
                </div>

                <div className='w-full flex flex-wrap rounded-lg' >
                    <div key={'BoomCard-' + tokenId} className={'w-1/2 lg:w-1/3 mt-6 lg:mt-12 '} >
                        <MBCardLg {...boomInfo} owner={false} />
                    </div>
                </div>
            </div >
            <div className='relative w-full h-20 lg:h-40 fansi_bgColor'>
                <div className='relative w-full h-8 lg:h-28 overflow-hidden '>
                    <img
                        className='absolute bottom-0 lg:object-none w-full object-fill'
                        src={frameBottom}
                        alt='frame_bottom'
                    />
                </div>
            </div>
            <MusicManiacPopup
                popSwitch={showPopUp}
                handleClose={() => setPopUp(false)}
            />
            <MetaDJPopup
                popSwitch={showDJPopUp}
                handleClose={() => setDJPopUp(false)}
            />
        </div >
    );
}

const CollectionPanel = ({ collection, owner }: { collection: string[], owner: boolean }) => {
    let mbKey = 0;
    return (
        <div className='w-full flex flex-wrap test'>
            {collection.length > 0 ?
                collection.map((k: any) => {
                    let mb = JSON.parse(k);
                    let padding = '';
                    switch (mbKey % 2) {
                        case 1:
                            padding = 'pl-2 pr-0 ';
                            break;
                        default:
                            padding = 'pl-0 pr-2 ';
                            break;
                    }
                    switch (mbKey % 3) {
                        case 1:
                            padding += ' lg:px-5';
                            break;
                        case 2:
                            padding += ' lg:pl-10 lg:pr-0';
                            break;
                        default:
                            padding += ' lg:pl-0 lg:pr-10';
                            break;
                    }
                    return <div key={'BoomCard-' + mbKey++} className={'w-1/2 lg:w-1/3 mt-6 lg:mt-12 ' + padding} >
                        <MBCardLg {...mb} owner={owner} />
                    </div>
                }) :
                <div className='w-full text-center h-full py-20' style={{ backgroundColor: '#0f1e23', borderRadius: '8px' }}>
                    <IMG src='Mb2Mockup' className='mx-auto w-32 h-28' />
                    <p className='RussoOne text-2xl my-4'>{`Become a Meta DJ`}</p>
                    <p className='RussoOne whitespace-pre text-sm lg:text-sm'>{`Earning token while listening to music is \ngreat, but do you know DJs token reward \nis much higher than listeners? Get a \nMetaBoom to secure your DJ role \nand earn more token while sharing your \nmusic to your friends!`}</p>
                    <Button className='mx-auto justify-center text-base px-2 lg:px-4 mt-6 w-60'>
                        <a className='text-white' href={'https://opensea.io/collection/metaboom-nft'} target='_blank' rel='noreferrer noopener' >
                            <p className='my-2'>Get one MB</p>
                        </a>
                    </Button>
                </div>}
        </div>
    )
}

const DJPanel = ({ id }: { id: number }) => {
    const [showMusicFarmingPopUp, setMusicFarmingPopUp] = useState(false);
    const [avatar, setAvatar] = useState('');
    const [avatarType, setAvatarType] = useState(1);
    const [showEdit, setShowEdit] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [displayName, setDisplayName] = useState('');
    const [showAvatarWindow, setAvatarWIndow] = useState(false);
    const [questScore, setQuestScore] = useState<number[]>([0, 0, 0, 0]);
    const [owner, setOwner] = useState(false);
    const [user, setUser] = useState<any>(null);
    const [me, setMe] = useState<any>(null);
    const [collection, setCollection] = useState<string[]>([]);
    const [avatarList, setAvatarList] = useState<string[]>([]);
    const [defaultId, setDefaultId] = useState(0);
    const Get_DJ_Score = gql`
        query GetDJScore($id: Int!) {
            avatarList: getAvatarList
            score: getUserQuestScore(userId:$id)
            collection: getBoomCollection(id: $id)
            user(id:$id) {
                id
                email
                avatarUrl
                displayName
            }
            me {
                id
                email
                walletAddress
            }
        }
        `;
    const { loading } = useQuery(Get_DJ_Score, {
        variables: { id },
        onCompleted: (data: any) => {
            if (data) {
                if (data.score) setQuestScore(data.score);
                if (data.me && data.me.id && (parseInt(data.me.id) === id || id < 1)) {
                    setMe(data.me);
                    setOwner(true);
                }
                if (data.user) setUser(data.user);
                if (data.collection && data.collection.length > 0) {
                    setCollection(data.collection);
                    let boomBox = JSON.parse(data.collection[0]);
                    setDefaultId(boomBox.tokenId)
                }
                if (data.avatarList) setAvatarList(data.avatarList);
            }
        }
    });

    const [save] = useMutation(
        UPDATE_PROFILE,
        {
            onCompleted: () => {
                setProcessing(true);
                window.location.reload();
            },
        }
    );

    if (loading) return <Loading />;
    const url = window.location.href.toLowerCase();
    if (!me && id < 1) {
        let showIntro = (url.includes("myfarming") && id < 1) ? true : false;
        return (
            <div className='w-full relative'>
                {showIntro ? <div className='w-full flex'>
                    <img className='hidden lg:block mx-auto' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/MetaBoom/img/MusicFarmingIntro.jpg' alt='intro' />
                    <img className='block lg:hidden mx-auto' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/MetaBoom/img/MusicFarmingIntroMobile.jpg' alt='intro' />
                </div> : ''}
                <div className={'w-full flex ' + (showIntro ? '' : ' pt-48')}>
                    <div className="mx-auto mt-6 mb-48 ">
                        <Link to={"/login?" + qs.stringify({ n: window.location.pathname })} >
                            <Button className='w-64 h-10 ' appearance='rainbow'>
                                Login
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className='section-md relative pb-12 px-5 lg:px-0'>
            <div className='flex mt-6'>
                <p className='mt-0 lg:mt-12 RussoOne text-3xl lg:text-6xl font-bold MetaBoom'>
                    {(owner ? 'My Panel' : 'DJ Collection')}
                </p>
                {owner && collection.length > 0 ?
                    <div className='pr-6 lg:pr-0 absolute right-0 mt-0 lg:mt-16'>
                        <Link to={'/playlist/edit/' + defaultId}>
                            <Button className='flex justify-center px-2 lg:px-6 py-0 lg:py-2' appearance='rainbow' >
                                <IMG src='EditBlack' className='my-auto mr-2 w-7 h-7' />
                                <p className='NotoSans my-auto text-black text-base lg:text-xl'>{`Edit Playlist`}</p>
                            </Button>
                        </Link>
                    </div> : ''}
            </div>
            <div className='section-md mt-6 lg:mt-20 mb-6 lg:mb-8 px-0'>
                {user ?
                    <div className='w-full py-4 px-0 lg:px-6 mb-6 rounded-lg' >
                        <div className='w-full flex flex-col lg:flex-row'>
                            <img className='hidden lg:block my-auto w-40 h-40 rounded-full lg:mr-12 cursor-pointer bg-black border border-gen2blue' alt=''
                                onClick={() => setAvatarWIndow(true)} src={user.avatarUrl ? user.avatarUrl : GetDefaultAvatar(id)} />
                            <div className='w-full'>
                                {owner ?
                                    <div className='flex relative rounded-2xl'>
                                        <div className='block lg:hidden flex' onClick={() => setAvatarWIndow(true)} >
                                            <img className='my-auto w-20 h-20 rounded-full mr-12 bg-black border border-gen2blue' alt=''
                                                src={user.avatarUrl ? user.avatarUrl : GetDefaultAvatar(id)} />
                                            <p className='ml-3 my-auto text-2xl font-bold Roboto italic'>
                                                {user.displayName}
                                            </p>
                                        </div>
                                        <div className='hidden lg:flex relative w-full h-16  cursor-pointer' onClick={() => setAvatarWIndow(true)} onMouseEnter={() => setShowEdit(true)} onMouseLeave={() => setShowEdit(false)} >
                                            <div className={'w-16 h-16 rounded-full bg-black bg-opacity-70 ' + (showEdit ? 'block' : 'hidden')}>
                                                <IMG src='EditPhoto' className='mx-auto w-6 h-6 mt-2' />
                                                <p className='text-center text-sm'>Edit</p>
                                            </div>
                                            <IMG src='Edit' className='my-auto mx-1 w-9 h-9' />
                                            <p className='my-auto lg:text-2xl w-32 lg:w-2/3 font-bold Roboto italic'>
                                                {user.displayName}
                                            </p>
                                        </div>
                                        <Popup open={showAvatarWindow} closeOnDocumentClick={false} closeOnEscape={false}
                                            onClose={() => setAvatarWIndow(false)} modal
                                            contentStyle={{ backgroundColor: 'rgba(0,0,0,0.5)', width: '100%', height: '100%', }} >
                                            <div className='relative modal p-6 w-4/5 h-4/5 mx-auto my-auto'
                                                style={{ backgroundColor: '#282828', maxWidth: 500, maxHeight: 582, }} >
                                                <IMG src='Close' onClick={() => setAvatarWIndow(false)} className='w-8 h-8 absolute top-0 right-0 m-6 z-40 cursor-pointer' />
                                                <p className='text-3xl mb-6 text-white italic'> Change Avatar </p>
                                                <div className='pl-4 lg:pl-10 mx-auto flex'>
                                                    <img className='w-24 h-24 lg:w-36 lg:h-36 rounded-full border-white' alt=''
                                                        src={avatar === '' ? (user.avatarUrl ? user.avatarUrl : GetDefaultAvatar(id)) : avatar} style={{ borderWidth: 2 }} />
                                                    <div className='px-4 lg:px-10 '>
                                                        <p className='text-sm text-gray-cd'> Name </p>
                                                        <div className='rounded-2xl mt-2'>
                                                            <input type='text' className='text-white pl-2 mx-auto h-6 lg:h-10 justify-center text-base w-full rounded-2xl'
                                                                style={{ backgroundColor: 'rgba(255, 255, 255, 0.15)', }} placeholder={user.displayName}
                                                                onChange={(e) => { setDisplayName(e.target.value); }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex my-6'>
                                                    <div className='w-1/2 text-center cursor-pointer'
                                                        onClick={() => { setAvatarType(1); }} >
                                                        <span style={{ borderBottom: avatarType === 1 ? 'solid' : '', borderColor: avatarType === 1 ? '#e5ac6e' : '', color: avatarType === 1 ? '#e5ac6e' : 'white' }} >
                                                            Metamoji
                                                        </span>
                                                    </div>
                                                    {collection.length > 0 ? (
                                                        <div className='w-1/2 text-center cursor-pointer' onClick={() => { setAvatarType(2); }} >
                                                            <span style={{ borderBottom: avatarType === 2 ? 'solid' : '', borderColor: avatarType === 2 ? '#e5ac6e' : '', color: avatarType === 2 ? '#e5ac6e' : 'white', }} >
                                                                MetaBoom
                                                            </span>
                                                        </div>
                                                    ) : ''}
                                                </div>
                                                <div className='overflow-y-auto max-h-48 lg:max-h-52'
                                                    style={{ minHeight: 150, backgroundColor: '#151515', }} >
                                                    {avatarType === 1 ?
                                                        <div className='flex flex-wrap p-6 rounded-2xl'>
                                                            {avatarList.length > 0 ? (
                                                                avatarList.map((k: any) => {
                                                                    return (
                                                                        <div key={'avatar-' + k} className='w-16 h-16 lg:w-20 lg:h-20 p-1'
                                                                            style={{ minWidth: 73, minHeight: 73 }} onClick={() => { setAvatar(k); }} >
                                                                            <img src={k} className='text-white mx-auto w-full h-full rounded-full' alt='' style={{ borderWidth: avatar === k ? 2 : 0, borderColor: '#e5ac6e', backgroundColor: 'black', }} />
                                                                        </div>)
                                                                })) : ''}
                                                        </div> :
                                                        <div className='flex flex-wrap p-6 rounded-2xl'>
                                                            {collection.map((k: any) => {
                                                                const boxInfo = JSON.parse(k);
                                                                let boomImg = boxInfo.image.substring(0, boxInfo.image.length - 5) + 'T.png';
                                                                return (
                                                                    <div key={'avatar2-' + k} className='w-16 h-16 lg:w-20 lg:h-20 p-1' style={{ minWidth: 73, minHeight: 73 }}
                                                                        onClick={() => { setAvatar(boomImg) }} >
                                                                        <img className='mx-auto w-full h-full bg-black' alt=''
                                                                            style={{ borderWidth: avatar === boomImg ? 2 : 0, borderColor: '#e5ac6e' }}
                                                                            src={boomImg} />
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>}
                                                </div>
                                                <div className='flex'>
                                                    <div className='w-1/3'>
                                                        <Button className='mx-auto h-10 justify-center text-base px-2 lg:px-4 mt-6' onClick={() => setAvatarWIndow(false)} >
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                    <div className='ml-8 items-right w-3/5 text-right'>
                                                        {processing ?
                                                            <div className='w-full mt-6 px-10'>
                                                                <Spinner className='mt-6' />
                                                            </div> :
                                                            <Button className='w-full h-10 NotoSans text-base mt-6' appearance='rainbow'
                                                                onClick={() => {
                                                                    save({
                                                                        variables: {
                                                                            payload: {
                                                                                avatarUrl: avatar === '' ? (user.avatarUrl ? user.avatarUrl : GetDefaultAvatar(id)) : avatar,
                                                                                displayName: displayName === '' ? user.displayName : displayName
                                                                            }
                                                                        }
                                                                    });
                                                                }} >
                                                                Save
                                                            </Button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Popup>
                                    </div> :
                                    <div className='flex relative rounded-2xl'>
                                        <p className='my-auto text-xl lg:text-2xl w-32 lg:w-2/3 font-bold Roboto italic whitespace-pre'>
                                            {user.displayName}
                                        </p>
                                    </div>}
                                <hr className='mt-7 mb-4' style={{ borderColor: '#044556' }} />
                                <div className='flex flex-col lg:flex-row'>
                                    <div className='w-full lg:w-1/3'>
                                        <div className='flex'>
                                            <p className='my-auto text-lg lg:text-2xl font-bold Roboto italic leading-none'> Music Score </p>
                                            <IMG src='Alert' onClick={() => { setMusicFarmingPopUp(true); }} className='cursor-pointer ml-4 my-auto w-6 h-6' />
                                        </div>
                                        <div className='flex mt-2'>
                                            <IMG src='QuestRainbow' className='my-auto  w-10 lg:w-12 h-10 lg:h-12' />
                                            <p className='mx-4 my-auto text-2xl lg:text-32p font-bold'> {questScore[3]} </p>
                                            {collection.length < 1 ?
                                                <a href={'https://opensea.io/collection/metaboom-nft'}
                                                    target='_blank' rel='noreferrer noopener' className='my-auto focus:outline-none'>
                                                    <div className='flex'>
                                                        <p className='Roboto text-sm gen2blue underline'>Become a Meta DJ</p>
                                                        <IMG src='ArrowRightBlue' className='my-auto ml-1 w-4 h-4' />
                                                    </div>
                                                </a> : ''}
                                        </div>
                                    </div>
                                    <div className='mt-7 lg:mt-0 w-full lg:w-2/3'>
                                        <div className='flex'>
                                            <p className='my-auto text-lg lg:text-2xl font-bold Roboto italic leading-none'>
                                                Music Score Farming
                                            </p>
                                        </div>
                                        <p className='text-xs text-gray-cd italic mb-1'>Scores will refresh in a moment after completion</p>
                                        <div className='flex'>
                                            <div className='flex mr-8'>
                                                <IMG src='QuestYellow' className='my-auto w-8 lg:w-10 h-8 lg:h-10' />
                                                <p className='my-auto ml-1 text-32p NotoSans font-bold'>
                                                    {questScore[2]}
                                                </p>
                                            </div>
                                            <div className='flex mr-8'>
                                                <IMG src='QuestRed' className='my-auto w-8 lg:w-10 h-8 lg:h-10' />
                                                <p className='my-auto ml-1 text-32p NotoSans font-bold'>
                                                    {questScore[1]}
                                                </p>
                                            </div>
                                            <div className='flex mr-8'>
                                                <IMG src='QuestBlue' className='my-auto w-8 lg:w-10 h-8 lg:h-10' />
                                                <p className='my-auto ml-1 text-32p NotoSans font-bold'>
                                                    {questScore[0]}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ''}
            </div>
            <div className='relative flex h-12 lg:h-8 mt-8 lg:mt-16'>
                <div className='absolute bottom-0 w-full h-px bg-[#044556]' />
                <TabLink to={`/collection/${id}`}>
                    <p className='leading-tight my-auto' >{'Collection'}</p>
                </TabLink>
                {owner ?
                    <>
                        <TabLink to={`/collection/0/myFarming`}>
                            <div className='flex'>
                                <p className='leading-tight my-auto mr-1' >Farming</p>
                                <p className='leading-tight my-auto djPink text-xs rounded-full p-1' style={{ border: 'solid 1px #ff52b9' }} >New</p>
                            </div>
                        </TabLink>
                        <TabLink to={`/collection/0/myFavorites`}>
                            <p className='leading-tight my-auto'>Favorites</p>
                        </TabLink>
                        <TabLink to={`/collection/0/myProfile`}>
                            <p className='leading-tight my-auto' >Profile</p>
                        </TabLink>
                    </> : ''}
            </div>
            <Routes>
                {owner ? <>
                    <Route path='/myFavorites' element={<Favorite id={id} />} />
                    <Route path='/myFarming' element={<FarmingQuest setQuestScore={setQuestScore} />} />
                    <Route path='/myProfile' element={<ProfileBlock user={me} walletAddress={me.walletAddress} />} /> </> : ''}
                <Route path='/*' element={<CollectionPanel collection={collection} owner={owner} />} />
            </Routes>
            <MusicScoreFarmingPopup popSwitch={showMusicFarmingPopUp} handleClose={() => setMusicFarmingPopUp(false)} />
        </div>
    )
}

const Collection = () => {
    let { id } = useParams();
    let projectId = parseInt(id + '');
    if (isNaN(projectId)) projectId = 0;
    if (projectId > 100000000)
        return <MetaDJCollection tokenId={(projectId - 100000000)} />

    return (
        <div className='w-full'>
            <img className=' lg:block hidden absolute top-0 left-0 right-0 object-contain' alt='' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/MetaBoom/banner/collectionBG.png' />
            <img className='lg:hidden block absolute top-0 left-0 right-0 object-contain' alt='' src='https://fansi-static.s3.ap-southeast-1.amazonaws.com/MetaBoom/banner/collectionBG-mobile.png' />
            <ScrollToTopOnce />
            <DJPanel id={projectId} />
        </div>
    )
}

export default Collection;
