import { IMG } from '../lib/imgLib';
import __ from '../lib/gettext';

const Footer = () => {
    const FOOTER_BLACKLIST = ['/MetaBoom', '/story', '/playlist/edit/', '/login', '/miniboom']
    const url = window.location.href.toLowerCase().replace(window.location.origin, '');
    let showFooter = true;
    FOOTER_BLACKLIST.forEach((blackList) => {
        blackList = blackList.toLowerCase();
        if (url.includes(blackList)) {
            showFooter = false;
        }
    });
    return (
        <>
            {showFooter ?
                <div className='relative w-full pt-8 pb-24 bg-black' aria-label='footer'>
                    <div className='section-lg flex'>
                        <div className='relative mx-auto w-3/4 h-60 lg:h-28 z-10 flex flex-wrap-reverse'>
                            <div className='w-full lg:w-2/3'>
                                <IMG className='mx-auto lg:ml-0 w-32 lg:w-44 h-auto object-contain' src='Logo' />
                                <div className='w-full relative lg:absolute bottom-0 left-0 flex flex-wrap-reverse mt-3 lg:mt-0'>
                                    <p className='w-full lg:w-48 Roboto text-xs text-center mt-1 lg:mt-0 lg:text-left mr-0 lg:mr-7' style={{ color: '#6d6d6d' }}>{'©' + new Date().getFullYear() + ' FANSI. All rights reserved.'}</p>
                                    <div className='flex mx-auto lg:ml-0 '>
                                        <a className='text-white text-xs mr-7' href='https://www.fansi.me/privacypolicy' target="_blank" rel="noreferrer noopener">
                                            Privacy Policy
                                        </a>
                                        <a className='text-white text-xs mr-7' href='https://www.fansi.me/tos' target="_blank" rel="noreferrer noopener">
                                            Terms of Service
                                        </a>
                                        <a className='text-white text-xs mr-7' href='https://classic.fansi.me' target="_blank" rel="noreferrer noopener">
                                            FANSI classic
                                        </a>
                                        <a className='text-white text-xs' href='https://sweet-angle-e85.notion.site/FANSI-MetaBoom-Support-Center-9279528833bd488dac5fb2d6eb3153f7' target="_blank" rel="noreferrer noopener">
                                            Support
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full lg:w-1/3 relative'>
                                <div className='relative lg:absolute top-0 right-0'>
                                    <div className='w-full flex'>
                                        <div className='flex mx-auto'>
                                            <a className='mr-6' href={__('FANSI_LinkTree')} target="_blank" rel="noreferrer noopener">
                                                <IMG className='w-7 h-7' src='LinkTree' />
                                            </a>
                                            <a className='mr-6' href={__('FANSI_IG')} target="_blank" rel="noreferrer noopener">
                                                <IMG className='w-7 h-7' src='IG' />
                                            </a>
                                            <a className='mr-6' href={__('FANSI_Twitter')} target="_blank" rel="noreferrer noopener">
                                                <IMG className='w-7 h-7' src='Twitter' />
                                            </a>
                                            <a className='' href={__('DC_INVITE_LINK')} target="_blank" rel="noreferrer noopener">
                                                <IMG className='w-7 h-7' src='Discord' />
                                            </a>
                                        </div>
                                    </div>
                                    <div className='w-full flex'>
                                        <div className='mt-3 flex mx-auto'>
                                            <a href={__('APP_Google')} target="_blank" rel="noreferrer noopener" >
                                                <IMG src='GooglePlay' className="w-26 mr-6" />
                                            </a>
                                            <a href={__('APP_Apple')} target="_blank" rel="noreferrer noopener" >
                                                <IMG src='AppleStore' className="w-26" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : ''
            }
        </ >
    )
};

export default Footer;
