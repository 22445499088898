import React from 'react';
import __ from '../lib/gettext';

interface Props {
  children: any;
}

class ErrorBoundary extends React.Component<Props> {
  state = { hasError: false };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className='error-boundary bg-white full-bg-white container section'>
          <h1 className={'py-8 text-black bg-white'}>
            🚧 {__('Something went wrong')}
          </h1>
          <p className='my-2'>{__('[Network Error]')}</p>
          <p className='my-2'>{__('[Try Refresh]')}</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
