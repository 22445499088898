import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Link } from "react-router-dom";
import UserAuth from "../../lib/userAuth";
import { IMG } from '../../lib/imgLib';
import { stringify } from "querystring";
import { error,Button, } from "../../lib/components";

const ProfileBlock = ({ user, walletAddress }: any) => {
    const [unlinkMM] = useLazyQuery(
        gql`
            query UnlinkMetaMask {
                unlink: unlinkMetaMask
            }
        `,
        {
            fetchPolicy: "network-only",
            onCompleted: (data) => {
                if (data.unlink && data.unlink === true) {
                    logOut();
                    window.location.href = window.location.origin + "/login";
                }
            },
            onError: (err) => {
                error(err);
            },
        }
    );

    const logOut = () => {
        UserAuth.signOut();
        window.location.reload();
    };

    let email = user.email;
    if (user.email.indexOf('metamask.fansi.me') !== -1 || user.email.indexOf('metaboom.fansi.me') !== -1)
        email = '';

    return (
        <div className="h-full py-0 lg:py-20 mx-auto w-full mt-6 lg:mt-12 rounded" style={{ backgroundColor: '#0f1e23' }}>
            <div className="mx-auto  Roboto mt-20 mb-20 w-full px-8 lg:px-0" style={{ maxWidth: 499, minWidth: 266 }} >
                <div className="w-full flex Roboto">
                    <span className='mb-3'>Email</span>
                    <span className='whitespace-pre text-gen2blue' >{' (Login account)'}</span>
                </div>
                <div className='w-full flex mt-3'>
                    <div className={(email ? 'w-full' : 'w-2/3') + ' h-10 px-2 border rounded flex'} style={{ backgroundColor: "#3b3a3a", borderColor: "#5b5b5b" }} >
                        <p className='my-auto' style={{ color: "#5b5b5b" }}>
                            {email ? email : 'Connect your email'}
                        </p>
                    </div>
                    {email ? '' : <div className='w-1/3 pl-4'>
                        <Link to={'/login?' + stringify({ n: window.location.pathname }) + '&UpdAccount=YES&accountStatus=2'}>
                            <div className='w-full flex h-10 bg-gen2blue justify-center rounded'>
                                <p className='my-auto mr-2 NotoSans font-medium text-sm text-black' >Connect Email</p>
                                <IMG src='ArrowRightBlack' className='my-auto w-4 h-4' />
                            </div>
                        </Link>
                    </div>}
                </div>
                {walletAddress ? <>
                    <p className="mt-8">Wallet</p>
                    <div className="flex flex-wrap mt-4 lg:mt-0">
                        <div className="flex w-full lg:w-1/3">
                            <IMG src='MM' className="w-8 h-8 my-auto" />
                            <p className="ml-2 my-auto text-gen2blue" >
                                {walletAddress.substring(0, 6) + "..." + walletAddress.substring(36)}
                            </p>
                        </div>
                        {email ? <div className="w-full lg:w-2/3 mt-4 lg:mt-0 lg:text-right text-center">
                            <Button
                                className="w-full lg:w-56 mx-auto justify-center text-base px-2 lg:px-4"
                                style={{ borderColor: 'white' }}
                                onClick={() => {
                                    if (window.confirm("Are you sure you want to disconnect with your wallet?")) {
                                        unlinkMM();
                                    } else {
                                        return;
                                    }
                                }} >
                                <p className="my-2">Disconnect</p>
                            </Button>
                        </div> : ''}
                    </div>
                </> : ''}
            </div>
        </div>
    )
}


export default ProfileBlock;