import { useState } from 'react';
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { IMG } from '../../lib/imgLib';
import { MBCardLg } from './Collection';
import { Link } from "react-router-dom";
import {
    Loading,
    Button,
} from "../../lib/components";

const Favorite = ({ id }: { id?: number }) => {
    const [favorites, setFavorites] = useState<string[]>([]);
    const Get_Boom_Collection = gql`
        query GetBoomCollection {
            favorites: getMBLikesCollection
        }
        `;
    const { loading } = useQuery(Get_Boom_Collection, {
        variables: { id },
        onCompleted: (data: any) => {
            if (data.favorites) setFavorites(data.favorites);
        }
    });
    if (loading) return <Loading />;
    let favKey = 0;
    return (<div className="w-full flex flex-wrap">
        {favorites.length > 0 ? (
            favorites.map((k: any) => {
                let mb = JSON.parse(k);
                let padding = '';
                switch (favKey % 2) {
                    case 1:
                        padding = 'pl-2 pr-0 ';
                        break;
                    default:
                        padding = 'pl-0 pr-2 ';
                        break;
                }
                switch (favKey % 3) {
                    case 1:
                        padding += ' lg:px-5';
                        break;
                    case 2:
                        padding += ' lg:pl-10 lg:pr-0';
                        break;
                    default:
                        padding += ' lg:pl-0 lg:pr-10';
                        break;
                }
                return (
                    <div key={"FavCard-" + favKey++} className={'w-1/2 lg:w-1/3 mt-6 lg:mt-12 ' + padding} >
                        <MBCardLg {...mb} />
                    </div>
                );
            })
        ) : (
            <div className="w-full text-center mt-10 mb-20">
                <IMG src='FavoriteBlueEmpty' className="mx-auto"
                    style={{ width: 115, height: 115 }} />
                <p className="font-bold text-2xl my-4">{`No favorites?`}</p>
                <p className="whitespace-pre text-sm lg:text-base">{`Go explore to support the MUSIC.`}</p>
                <Link
                    to="/explore"
                    className="flex text-white justify-center"
                >
                    <Button
                        className="mx-auto justify-center text-base px-2 lg:px-4 mt-8"
                        style={{ width: 229 }}
                    >
                        <p className="my-2">Start explore</p>
                    </Button>
                </Link>
            </div>
        )}
    </div>

    );
}

export default Favorite;