import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Popup from "reactjs-popup";

import { IMG } from '../../lib/imgLib';
import {
    Loading,
    error,
} from "../../lib/components";

const S_KEY = 'MB_FARMING:';
interface QuestProps {
    id: number;
    questId: number;
    projectId?: number;
    progress: number;
    endDate?: number;
    status: number; // 0 initial, 1 in progress, 2 fail, 99 complete
    extra?: string;
}

const AppDownloadBlock = ({ quest, showPopup }: { quest: QuestProps, showPopup: boolean }) => {
    function GetOS(): string {
        var userAgent = navigator.userAgent || navigator.vendor;
        if (/android/i.test(userAgent)) {
            return 'Android';
        }
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent)) {
            return 'iOS';
        }

        return "Others";
    }

    let os = GetOS();
    const [updating, setUpd] = useState(false);
    const [chkQuest] = useLazyQuery(
        gql`
            query ChkUserQuset($id:Int!, $questId:Int) {
                progress: chkUserQuset(id:$id, questId:$questId)
            }
            `,
        {
            fetchPolicy: "network-only",
            onCompleted: () => {
                window.location.reload();
            },
            onError: (err: any) => {
                error(err);
            },
        }
    );

    function storeRedirect(store: number) {
        if (updating) return;
        setUpd(true);
        chkQuest({ variables: { id: quest.id, questId: quest.questId } });
        let link = 'https://play.google.com/store/apps/details?id=me.fansi.app';
        if (store === 2) link = 'https://apps.apple.com/tw/app/metaboom/id6444411102';
        window.open(link, '_blank', 'noreferrer');
    }

    return <>
        <Popup open={showPopup} closeOnDocumentClick={false} >
            <div className='fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-80'>
                <div className='absolute-center p-4 bg-black w-full rounded border-gen2blue border' style={{ maxWidth: 355 }}>
                    <div className='flex'>
                        <p className='gen2blue RussoOne text-2xl'>Download Now</p>
                        <IMG className='w-5 h-5 ml-2 my-auto' src='ArrowRightBlue' />
                    </div>
                    <div className='flex flex-wrap mt-4 justify-center'>
                        {os !== 'iOS' ? <div onClick={() => storeRedirect(1)}>
                            <IMG className='w-36 h-11 cursor-pointer' src='GooglePlay' />
                        </div> : ''}
                        {os !== 'Android' ? <div onClick={() => storeRedirect(2)}>
                            <IMG className='w-36 h-11 cursor-pointer' src='AppleStore' />
                        </div> : ''}
                    </div>
                </div>
            </div>
        </Popup>
    </>
}

const QuestVerifyBtn = ({ id, questId, className }: { id: number, questId: number, className: string }) => {
    const [chkQuest] = useLazyQuery(
        gql`
            query ChkUserQuset($id:Int!) {
                progress: chkUserQuset(id:$id)
            }
            `,
        {
            fetchPolicy: "network-only",
            onCompleted: (data) => {
                if (!data || !data.progress) {
                    setMsg(msgContnet[1]);
                    setPopUp(true);
                }
                switch (data.progress) {
                    case 1:
                        setMsg(msgContnet[2]);
                        setPopUp(true);
                        break;
                    case 3:
                        setMsg(msgContnet[0]);
                        setPopUp(true);
                        break;
                    default:
                        setMsg(msgContnet[1]);
                        setPopUp(true);
                        break;
                }
                setTimeout(() => {
                    window.location.reload();
                }, 2000);

            },
            onError: (err: any) => {
                error(err);
            },
        }
    );
    const msgContnet = ['Please wait 1 minute to check again', 'Verification failed...', 'Verification Success!'];
    const [lastChk, setChkTime] = useState(0);
    const [msg, setMsg] = useState(msgContnet[0]);
    const [showPopUp, setPopUp] = useState(false);
    const btnColor = questId === 1 ? 'var(--mbRed)' : 'var(--mbYellow)';
    function ChkQuestProgress() {
        const chkGAP = 3000;
        const curr = new Date().getTime();
        let gap = curr - lastChk;
        if (gap < chkGAP) {
            setMsg(msgContnet[0]);
            setPopUp(true);
            return;
        }
        setChkTime(curr);
        chkQuest({ variables: { id } })
    }

    return (
        <>
            <Popup
                open={showPopUp}
                closeOnEscape={true}
                contentStyle={{ position: 'fixed', width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.3)' }}
                onClose={() => setPopUp(false)}>
                <div className='fixed left-0 top-0 w-screen h-screen bg-black bg-opacity-50'>
                    <div className='absolute-center w-full bg-black pt-6 pb-24 px-5' style={{ maxWidth: 340, minHeight: 400, border: 'solid 1px #089dc6' }}>
                        <IMG className='absolute right-0 top-0 w-6 h-6 m-6 cursor-pointer' src='Close' onClick={() => setPopUp(false)} />
                        <p className='Roboto text-3xl font-bold text-gen2blue'>Oops!</p>
                        <div className='relative w-full h-48 flex'>
                            <p className='w-full Roboto text-3xl font-bold text-center text-white my-auto'> {msg}</p>
                        </div>
                        <div className='absolute left-0  bottom-0 w-full px-10 pb-10'>
                            <button className='w-full h-12 rounded bg-gen2blue focus:outline-none' onClick={() => setPopUp(false)}>
                                <p className='my-auto NotoSans text-3xl font-medium text-black w-full text-center'>Got it</p>
                            </button>
                        </div>
                    </div>
                </div>
            </Popup>
            <button className={className} style={{ borderColor: btnColor }} onClick={ChkQuestProgress}>
                <p className='my-auto text-full text-center NotoSnas font-medium text-xs lg:text-sm' style={{ color: btnColor }}>Verify</p>
            </button>
        </>
    )
}

function parseQuestExtraInfo(extra: string) {
    let artist = 'anonymous';
    let projectTitle = 'Title'
    try {
        let extraInfo = JSON.parse(extra);
        if (extraInfo['artist']) {
            let name = JSON.parse(extraInfo['artist']);
            if (name['en']) artist = name['en'];
        }
        if (extraInfo['title']) projectTitle = extraInfo['title'];
    } catch (err) {
        console.warn(err);
    }
    return { artist, projectTitle };
}

const FarmingBlock = ({ quest, blank }: { quest: QuestProps, blank?: boolean }) => {
    const { id, questId, projectId, extra, status, progress, endDate } = quest;
    const CURR_QUEST_LIST = [1, 2, 3, 101, 102, 103, 104, 201, 202];//current display quest
    const END_DAY_LIST = [1, 2, 3]; //quest that shows end day
    const PERM_LIST = [201, 202]; //never expire
    const DAILY_LIST = [101, 102, 103, 104]; //daily quest
    const [appPopup, setAppPopup] = useState(false);
    if (CURR_QUEST_LIST.indexOf(questId) < 0) return null;
    let endTime = endDate + '';
    let daysLeft = 0;
    if (END_DAY_LIST.indexOf(questId) > -1 && endTime && endTime.length >= 8) {
        endTime = endTime.substring(0, 4) + '-' + endTime.substring(4, 6) + '-' + endTime.substring(6, 8) + ' 23:59:59+0800';
        daysLeft = Math.floor((new Date(endTime).getTime() - new Date().getTime()) / 86400000);
    }
    const RED = 'var(--mbRed)';
    const YELLOW = 'var(--mbYellow)';
    const BLUE = 'var(--gen2blue)';
    let icon = 'QuestBlue';
    let arrow = 'ArrowRightBlue';
    if (status === 99) arrow = 'CheckBlue';
    if (status === 2) arrow = 'CloseB';
    let title = 'Quest Title';
    let content = 'Quest Content';
    let color = BLUE;
    const { artist, projectTitle } = parseQuestExtraInfo(extra ? extra : '{}');

    const quest3extra = 'Get the NFT!';
    let cursor = '';
    let clickList = [3, 101, 102, 103, 104, 201, 202]
    if (status < 1 && clickList.indexOf(questId) !== -1) cursor = 'cursor-pointer';
    switch (questId) {
        case 1:
            icon = 'QuestRed';
            arrow = 'ArrowRightRed';
            title = 'Mint a FANSI Rising Star  (' + progress + '/1)';;
            color = RED;
            content = artist + '\'s first music NFT, "' + projectTitle + '" has been released on FANSI Rising Star. Mint it now to gain advantages in upcoming events.';
            if (status === 99) arrow = 'CheckRed';
            if (status === 2) arrow = 'CloseR';
            break;
        case 2:
            icon = 'QuestYellow';
            title = 'FANSI Rising Star Supporter  (' + progress + '/10)';
            color = YELLOW;
            content = artist + '\'s first music NFT, "' + projectTitle + '" has been released on FANSI Rising Star. Mint it 10 times to become a huge supporter!';
            if (status === 99) arrow = 'CheckYellow';
            if (status === 2) arrow = 'CloseY';
            break;
        case 3:
            icon = 'QuestRed';
            arrow = 'ArrowRightRed';
            title = 'Import ' + artist + '\'s first NFT in your MetaBoom playlist';
            color = RED;
            content = ' Hey MetaBoom holders, import ' + artist + '\'s first music NFT, "' + projectTitle + '" into your MetaBoom playlist to prove that you\'re a true MetaDJ!';
            if (status === 99) arrow = 'CheckRed';
            if (status === 2) arrow = 'CloseR';
            break;
        case 101: //daily quest start
            title = 'Listen MetaBoom for 10 minutes (' + progress + '/10)';
            content = 'Find a MetaBoom that you like and listen to it for 10 minutes to complete the quest';
            break;
        case 102:
            title = 'Share a MetaBoom playlist on Twitter (' + progress + '/1)';;
            content = 'Recommned a MetaBoom playlist on Twitter, and you will find the listening experinece is also good on Twitter.';
            break;
        case 103:
            title = 'Edit your MetaBoom playlist (' + progress + '/1)';;
            content = 'Your MetaBoom playlist reflects your music taste. Edit it to complete the quest. If you don\'t have one yet, get one now!';
            break;
        case 104:
            icon = 'QuestRed';
            arrow = 'ArrowRightRed';
            title = 'Listen MetaBoom for 10*10 minutes (' + progress + '/10)';
            color = RED;
            content = 'Find a MetaBoom that you like and listen to it for 10*10 minutes to complete the quest';
            if (status === 99) arrow = 'CheckRed';
            break;
        case 201://email bounding
            title = 'Mark of Trust';
            content = 'Connectyour email to prove your loyalty as a MetaBoom Junkie. Your email will be kept confidential and will only be used for identity verification purposes.';
            break;
        case 202://app download promote
            title = 'Download MetaBoom APP';
            content = 'The MetaBoom APP 2.0 is now available! Click to download and complete the mission.';
            break;

    }

    function QuestAction(e: any) {
        e.stopPropagation();
        switch (questId) {
            case 3:
            case 103:
                if (status < 1) window.open((window.location.origin + '/collection/0'), '_blank', 'noreferrer');
                break;
            case 101:
            case 102:
            case 104:
                if (status < 1) window.open((window.location.origin + '/explore'), '_blank', 'noreferrer');
                break;
            case 201:
                if (status < 1) window.open((window.location.origin + '/collection/0/myprofile'), '_blank', 'noreferrer');
                break;
            case 202:
                setAppPopup(true);
                break;
        }
    }

    function GoProject(e: any) {
        e.stopPropagation();
        if (questId === 3 && status < 1) window.open(('https://fansi.me/hatching/project/' + projectId), '_blank', 'noreferrer');
    }

    let completedTime = '';
    if (endDate && status === 99) {
        let endTime = '2023-06-15';
        if (endDate < 20990101) {
            let strDate = endDate + '';
            endTime = strDate.substring(0, 4) + '-' + strDate.substring(4, 6) + '-' + strDate.substring(6, 8);
        }
        completedTime = 'Completed in ' + endTime;
    }

    return (
        <>
            <div className={'w-full relative flex pr-9 lg:pr-18 ' + cursor + (status === 2 ? ' opacity-35' : '')} onClick={QuestAction}>
                {questId === 202 ? <AppDownloadBlock quest={{ id, questId, projectId, extra, status, progress }} showPopup={appPopup} /> : ''}
                <IMG className='mr-1 lg:mr-5 my-auto w-8 h-8 lg:w-12 lg:h-12' src={icon} />
                <div className='w-4/5'>
                    <p className="Roboto font-bold text-sm lg:text-base" style={{ color }}>{title}</p>
                    {status === 99 ? <p className='text-xs text-white'>{completedTime}</p> : ''}
                    {status === 2 && endDate && endDate < 20901231 ? <p className='text-xs text-white'>Overdue</p> : ''}
                    {DAILY_LIST.indexOf(questId) > -1 && status === 0 ? <p className='text-xs text-white'>Refresh everyday</p> : ''}
                    {PERM_LIST.indexOf(questId) > -1 && status === 0 ? <p className='text-xs text-white'>Permanent quest</p> : ''}
                    {END_DAY_LIST.indexOf(questId) > -1 && status === 0 ?
                        <p className='text-xs text-white'>{'Ends ' + (daysLeft > 0 ? ('in ' + daysLeft + ' day' + (daysLeft > 1 ? 's' : '')) : 'today')} </p> : ''}
                    <p className="text-xs" style={{ color: '#939393' }}>{content}
                        {questId === 3 && status < 1 ?
                            <span className='ml-1 cursor-pointer text-gen2blue' onClick={GoProject}>{quest3extra}</span> : ''}
                    </p>
                </div>
                <div className='vertical-center right-0 pr-0 lg:pr-2'>
                    {status === 0 && (questId === 1 || questId === 2) ?
                        <QuestVerifyBtn className='w-10 lg:w-15 h-6 lg:h-7 border rounded' id={id} questId={questId} /> :
                        <IMG className={'w-8 lg:w-10 h-8 lg:h-10 ' + cursor} src={arrow} onClick={(e: any) => QuestAction(e)} />}
                </div>
            </div>
            {blank ? <div className='w-full h-6' /> : <hr className='hp-px my-3' style={{ color: 'rgba(95,221,255, 0.14)' }} />}
        </>
    )
}

const ProjectQuestBlock = ({ quests, noticeOff }: { quests: QuestProps[], noticeOff?: boolean }) => {
    const [collapse, setCollapse] = useState(true);
    const [projectTitle, setProjectTitle] = useState('');
    const [projectId, setProjectId] = useState(0);
    const [status, setStatus] = useState(0)
    const [subQuest, setSubQuest] = useState<QuestProps[]>([]);
    const [showNotice, setNotice] = useState(false);

    useEffect(() => {
        let currSubs: QuestProps[] = [];
        let currArtist = '';
        let remaintQuest = false
        let projectId = 0;
        for (let i = 0; i < quests.length; i++) {
            let quest = quests[i];
            if (quest.projectId) projectId = quest.projectId;
            if (quest.status === 0) remaintQuest = true;
            if (!currArtist && quest.extra) {
                let info = parseQuestExtraInfo(quest.extra);
                currArtist = info.artist;
                setProjectTitle(info.projectTitle);
            }
            if (quest.questId === 4) {
                setStatus(quest.status);
            } else {
                currSubs.push(quest);
            }
        }
        setProjectId(projectId);
        setSubQuest(currSubs);

        //set notice
        if (remaintQuest && !noticeOff) { //check notice if on
            let notice = false;
            let chkList = JSON.parse(localStorage.getItem(S_KEY + 'ProjectQuest') + '');
            if (!chkList || (chkList && chkList.indexOf(projectId) < 0)) notice = true;
            setNotice(notice);
        }
    }, [setProjectTitle, setStatus, setSubQuest, setNotice, noticeOff, quests])

    function SwitchMenu() {
        setCollapse(collapse ? false : true);
        if (showNotice) {
            let chkList = JSON.parse(localStorage.getItem(S_KEY + 'ProjectQuest') + '');
            if (!chkList) chkList = [];
            if (chkList.indexOf(projectId) < 0) {
                chkList.push(projectId);
                localStorage.setItem(S_KEY + 'ProjectQuest', JSON.stringify(chkList));
            }
            setNotice(false);
        }
    }

    let count = 0;
    return (
        <div className='py-3 lg:py-5 px-2 lg:px-6 rounded mb-4 relative' style={{ backgroundColor: '#142a31' }}>
            {showNotice ? <div className='absolute w-3 h-3 rounded-full' style={{ backgroundColor: '#ff52b9', top: -4, right: -4 }} /> : ''}
            <div className='w-full flex relative pr-7  cursor-pointer' onClick={SwitchMenu}>
                <IMG src={collapse ? 'ArrowDown' : 'ArrowUp'} className='vertical-center right-0 w-6 lg:w-7 h-6 lg:h-7' />
                <IMG src='QuestYellow' className='w-8 lg:w-10 h-8 lg:h-10 my-auto mr-3 lg:mr-5' />
                <p className="Roboto font-bold text-base lg:text-lg my-auto text-quest-yellow" >{projectTitle}</p>
                {status === 99 ? <IMG src='CheckYellow' className='w-6 lg:w-7 h-6 lg:h-7 my-auto ml-2 lg:ml-4' /> : ''}
            </div>
            {!collapse ?
                <div className='w-full'>
                    <p className='text-xs Roboto mt-2 mb-6 px-2 lg:px-0'>After completing the activity quests, you will receive an additional reward: <span className='text-quest-yellow'>Advanced Quests.</span></p>
                    <div className='w-full px-1 lg:px-2'>
                        {subQuest.map((quest: QuestProps) => {
                            return <FarmingBlock quest={quest} key={'subQuest-' + (count++)} blank={true} />
                        })}
                    </div>
                </div> : ''}
        </div>
    )
}

const OtherQuestBlock = ({ quests, noticeOff }: { quests: QuestProps[], noticeOff?: boolean }) => {
    const [collapse, setCollapse] = useState(true);
    const [showNotice, setNotice] = useState(false);
    useEffect(() => {
        if (quests.length > 0 && !noticeOff) { //check notice if on
            let notice = false;
            let chkList = JSON.parse(localStorage.getItem(S_KEY + 'OtherQuest') + '');
            if (chkList) {
                for (let i = 0; i < quests.length; i++) {
                    let quest = quests[i];
                    if (chkList.indexOf(quest.questId) < 0) notice = true;
                }
            } else {
                notice = true;
            }
            setNotice(notice);
        }
    }, [setNotice, noticeOff, quests])
    function SwitchMenu() {
        setCollapse(collapse ? false : true);
        //set notice off
        let currChkList = [];
        for (let i = 0; i < quests.length; i++) {
            let quest = quests[i];
            currChkList.push(quest.questId);
        }
        localStorage.setItem(S_KEY + 'OtherQuest', JSON.stringify(currChkList));
        setNotice(false);
    }
    let count = 0;
    return (
        <div className='py-3 lg:py-5 px-4 lg:px-6 rounded mb-4 relative' style={{ backgroundColor: '#142a31' }}>
            {showNotice ? <div className='absolute w-3 h-3 rounded-full' style={{ backgroundColor: '#ff52b9', top: -4, right: -4 }} /> : ''}
            <div className='w-full flex relative pr-7 cursor-pointer' onClick={SwitchMenu} >
                <IMG src={collapse ? 'ArrowDown' : 'ArrowUp'} className='vertical-center right-0 w-6 lg:w-7 h-6 lg:h-7 ' />
                <IMG src='Quest' className='w-8 lg:w-10 h-8 lg:h-10 my-auto mr-3 lg:mr-5' />
                <p className="Roboto font-bold text-base lg:text-lg my-auto" >Other Quests</p>
            </div>
            {!collapse ?
                <div className='w-full'>
                    <div className='w-full px-1 lg:px-2 mt-6'>
                        {quests.length < 1 ? <p className='w-full text-center'>No Quests Available!</p> : ''}
                        {quests.map((quest: QuestProps) => {
                            return <FarmingBlock quest={quest} key={'otQuest-' + (count++)} blank={true} />
                        })}
                    </div>
                </div> : ''}
        </div>
    )
}

const QuestHistoryBlock = () => {
    const GET_USER_QUEST_HISTORY = gql`
        query GetUserQuestHistory {
            history:getUserQuestHistory{
                    id
                    questId 
                    projectId
                    progress
                    endDate
                    status
                    extra                
            }
        } `;
    const [uniqueQuest, setUniqueQuest] = useState<{ [k: number]: QuestProps[] }>({});
    const [oneTimeQuest, setOneTimeQuest] = useState<QuestProps[]>([]);
    const [ready, setReady] = useState(false);

    const { loading } = useQuery(GET_USER_QUEST_HISTORY, {
        onCompleted: (data: any) => {
            if (data && data.history) {
                let history = data.history;
                if (history.length > 0) {
                    let daily: QuestProps[] = [];
                    let unique: { [k: number]: QuestProps[] } = {};
                    let oneTime: QuestProps[] = [];
                    for (let i = 0; i < history.length; i++) {
                        let quest: QuestProps = history[i];
                        if (quest.questId > 200) {
                            oneTime.push(quest);
                        } else if (quest.questId < 200 && quest.questId > 100) {
                            daily.push(quest);
                        } else if (quest.projectId) {
                            if (!unique[quest.projectId]) unique[quest.projectId] = [];
                            unique[quest.projectId].push(quest);
                        }
                    }
                    setUniqueQuest(unique);
                    setOneTimeQuest(oneTime);
                    setReady(true);
                }
            }
        }
    });
    if (loading || !ready) return <Loading />;
    return (
        <div className='absolute w-full h-full left-0 top-0 px-3 lg:px-6 pt-12 lg:pt-22 pb-8 text-white'>
            <div className='w-full h-full overflow-y-auto px-4 pt-4'>
                <OtherQuestBlock quests={oneTimeQuest} noticeOff={true} />
                {Object.keys(uniqueQuest).map((id: any) => {
                    const quests = uniqueQuest[id];
                    return <ProjectQuestBlock quests={quests} noticeOff={true} />
                })}
            </div>
        </div>
    )
}

const FarmingQuest = ({ setQuestScore }: any) => {
    const GET_USER_QUEST = gql`
        query GetUserQuestInfo {
            questInfo:getUserQuestInfo{
                id
                questList{
                    id
                    questId 
                    projectId
                    progress
                    endDate
                    status
                    extra                
                }
                score
            }
        } `;
    const [uniqueQuest, setUniqueQuest] = useState<{ [k: number]: QuestProps[] }>({});
    const [dailyQuest, setDailyQuest] = useState<QuestProps[]>([]);
    const [oneTimeQuest, setOneTimeQuest] = useState<QuestProps[]>([]);
    const [ready, setReady] = useState(false);
    const [showHistory, setHistory] = useState(false);
    const [showMailQuest, setMailQuest] = useState(false);
    const { loading } = useQuery(GET_USER_QUEST, {
        onCompleted: (data: any) => {
            if (data && data.questInfo) {
                let questInfo = data.questInfo;
                if (questInfo.score && setQuestScore) setQuestScore(questInfo.score);
                if (questInfo.questList.length > 0) {
                    let daily: QuestProps[] = [];
                    let unique: { [k: number]: QuestProps[] } = {};
                    let oneTime: QuestProps[] = [];
                    for (let i = 0; i < questInfo.questList.length; i++) {
                        let quest: QuestProps = questInfo.questList[i];
                        if (quest.questId > 200) {
                            if (quest.questId === 201 && quest.progress < 1) setMailQuest(true);
                            oneTime.push(quest);
                        } else if (quest.questId < 200 && quest.questId > 100) {
                            daily.push(quest);
                        } else if (quest.projectId) {
                            if (!unique[quest.projectId]) unique[quest.projectId] = [];
                            unique[quest.projectId].push(quest);
                        }
                    }
                    setDailyQuest(daily);
                    setUniqueQuest(unique);
                    setOneTimeQuest(oneTime);
                }
            }
            setReady(true);
        }
    });
    if (loading || !ready) return <Loading />;
    let count = -1;
    return <div className='relative w-full mx-auto p-0 lg:p-4'>
        {showMailQuest ? <div className='w-full flex mt-6 mb-8 p-3 bg-gen2blue flex rounded'>
            <div className='w-6/7 lg:w-4/5 flex'>
                <IMG src='QuestBlack' className='w-[30px] aspect-square my-atuo mr-2' />
                <p className='text-black text-xs lg:text-lg Roboto my-auto'>Earn you first music score by connecting your email, no BS, it's that easy.</p>
            </div>
            <div className='w-9 lg:w-1/5 flex justify-end'>
                <Link to='/login?n=%2Fcollection%2F0%2FmyProfile&UpdAccount=YES&accountStatus=2' className='h-6 lg:h-9 w-20 lg:w-44 bg-djPink rounded justify-center flex my-auto'>
                    <p className='Roboto text-xs lg:text-sm my-auto hidden lg:block mr-1 lg:mr-2'>Connect  Now</p>
                    <IMG src='ArrowRight' className='w-4 h-4 my-auto' />
                </Link>
            </div>

        </div> : ''}
        <div className='w-full flex flex-wrap'>
            <div className='w-full lg:w-1/2 pr-0 lg:pr-3' >
                <div className="relative w-full rounded" style={{ backgroundColor: '#0f1e23' }}>
                    <div className='w-full h-96' />
                    <div className='w-full h-24 lg:h-64' />
                    <div className='w-full h-full absolute top-0 left-0 p-6 '>
                        <p className="text-xl lg:text-3xl RussoOne">Daily Farming</p>
                        <div className='absolute w-full h-full left-0 top-0 px-3 lg:px-6 pt-16 lg:pt-26 pb-8'>
                            <div className='relative w-full h-full overflow-y-auto'>
                                {dailyQuest.map((quest: QuestProps) => {
                                    return <FarmingBlock quest={quest} key={'dQuest-' + (count++)} />
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full lg:w-1/2 pl-0 lg:pl-3 mt-6 lg:mt-0' >
                <div className="relative w-full rounded" style={{ backgroundColor: '#0f1e23' }}>
                    <div className='w-full h-96' />
                    <div className='w-full h-24 lg:h-64' />
                    <div className='w-full h-full absolute top-0 left-0 p-6 '>
                        <div className='w-full relative pr-20'>
                            <p className="text-lg lg:text-3xl RussoOne">Unique Challenges</p>
                            <div className='vertical-center right-0 flex pr-2 cursor-pointer z-50' onClick={() => setHistory(true)}>
                                <p className='text-gen2blue text-sm lg:text-base mr-0 lg:mr-1 my-auto leadying-none'>History</p>
                                <IMG src='TimeB' className='my-auto w-4 h-4' />
                            </div>
                        </div>
                        <div className='absolute w-full h-full left-0 top-0 px-3 lg:px-6 pt-12 lg:pt-22 pb-8'>
                            <div className='w-full h-full overflow-y-auto px-2 lg:px-4 pt-4'>
                                <OtherQuestBlock quests={oneTimeQuest} />
                                {Object.keys(uniqueQuest).map((id: any) => {
                                    const quests = uniqueQuest[id];
                                    return <ProjectQuestBlock quests={quests} />
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Popup open={showHistory} closeOnDocumentClick={false} onClose={() => setHistory(false)}>
            <div className='fixed left-0 top-0 w-screen h-screen bg-black bg-opacity-50'>
                <div className='w-full text-white lg:w-1/2 absolute-center px-6' style={{ maxWidth: 600, height: '80vh' }}>
                    <div className='relative w-full h-full bg-darkestBlue rounded py-6 lg:py-8 px-4 lg:px-6' style={{ border: 'solid 1px #089dc6' }}>
                        <div className='relative w-full pr-12 lg:pr-20 pl-2 lg:pl-4'>
                            <p className="text-base lg:text-3xl RussoOne">Unique Challenges History</p>
                            <div className='absolute top-0 right-0 flex pr-4 cursor-pointer z-50' onClick={() => setHistory(false)}>
                                <IMG src='Close' className='my-auto w-6 lg:w-8 h-6 lg:h-8' />
                            </div>
                        </div>
                        <QuestHistoryBlock />
                    </div>
                </div>
            </div>
        </Popup >
        <div className="flex w-full mt-9 justify-end">
            <IMG src='QuestBlue' className='w-4 lg:w-5 h-4 lg:h-5 mr-1 my-auto' />
            <p className="text-10p lg:text-sm my-auto mr-2 lg:mr-5">Common Quests</p>
            <IMG src='QuestRed' className='w-4 lg:w-5 h-4 lg:h-5 mr-1 my-auto' />
            <p className="text-10p lg:text-sm my-auto mr-2 lg:mr-5">Special Quests</p>
            <IMG src='QuestYellow' className='w-4 lg:w-5 h-4 lg:h-5 mr-1 my-auto' />
            <p className="text-10p lg:text-sm my-auto mr-0 lg:mr-6">Advanced Quests</p>
        </div>
    </div >
}

export default FarmingQuest;